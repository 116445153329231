import "../styles/Faq.scss"

import React from "react"
import { Accordion } from "react-bootstrap"

import Page from "../components/Page"
import { ReactComponent as QuestionMark } from "../images/questionmark.svg"

export default class Faq extends Page {
  state = {
    data: null,
    openPanel: {},
  }

  showEditDiv = (id) => {
    this.setState((prevState) => ({
      openPanel: {
        ...{},
        [id]: !prevState.openPanel[id],
      },
    }))
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { data } = this.state

    return (
      data && (
        <section>
          <div className={"row"}>
            <div className={"col-12 col-md-8"}>
              <h1>{data.information.title}</h1>
              {
                <div className={"content__symbol"}>
                  <QuestionMark />
                </div>
              }
              <h2>{data.Subheadline}</h2>
              {this.text(data.Intro)}
            </div>
            <div className={"col-12 col-md-4"}>
              {data.image && (
                <img
                  className={"float-right"}
                  key={`${data.image.__component}${data.image.id}`}
                  src={`${process.env.REACT_APP_API}${data.image.url}`}
                  alt={data.image.alternativeText}
                />
              )}
            </div>
          </div>

          <Accordion>
            {data.faqs.map((faq) => (
              <div className={"faq-element"} key={faq.id}>
                <div className={"header"}>
                  <Accordion.Toggle
                    as="span"
                    variant="link"
                    eventKey={faq.id}
                    className={"faq-element"}
                    key={`faq-${faq.id}`}
                    onClick={() => this.showEditDiv(faq.id)}
                  >
                    <h3
                      className={`question ${
                        this.state.openPanel[faq.id] ? "open" : "closed"
                      }`}
                    >
                      {faq.question}
                    </h3>
                  </Accordion.Toggle>
                </div>

                <Accordion.Collapse eventKey={faq.id}>
                  <div className={"answer"}>{this.text(faq.answer)}</div>
                </Accordion.Collapse>
              </div>
            ))}
          </Accordion>
        </section>
      )
    )
  }
}

import "../../styles/AddClass.scss"

import { Link, withRouter } from "react-router-dom"

import Calendar from "../../components/Calendar"
import Form from "../../components/Form"
import Input from "../../components/Input"
import Loader from "../../components/Loader"
import PageIntern from "../../components/PageIntern"
import Select from "../../components/Select"
import { ReactComponent as Close } from "../../images/IMG_Close_Icon.svg"

class AddClass extends PageIntern {
  state = {
    ...this.state,

    schools: [],

    school: {
      value: "",
      name: "school",
      type: "select",
      errorMsg: "Ungültige Schule",
      error: false,
      required: true,
      onChange: (value) => {
        this.setState({
          school: Object.assign(this.state.school, value),
          teacher: Object.assign(this.state.teacher, {
            value: this.props.store._auth.isEmployee
              ? ""
              : this.props.store._auth.user.id.toString(),
          }),
        })
      },
    },
    teacher: {
      value: "",
      name: "teacher",
      type: "select",
      errorMsg: "Ungültiger Lehrer",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ teacher: Object.assign(this.state.teacher, value) }),
    },
    name: {
      value: "",
      name: "name",
      type: "text",
      errorMsg: "Ungültiger Name",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ name: Object.assign(this.state.name, value) }),
    },
    jahrgang: {
      value: "8",
      name: "year",
      type: "text",
      errorMsg: "Ungültiger Jahrgang",
      error: false,
      required: true,
      onChange: (val) =>
        this.setState({
          jahrgang: Object.assign(this.state.jahrgang, val),
        }),
    },
    schoolYear: {
      value: "",
      name: "schoolYear",
      type: "text",
      errorMsg: "Ungültiges Schuljahr",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({
          schoolYear: Object.assign(this.state.schoolYear, value),
        }),
    },

    date: null,
  }

  /**
   * constructor
   * @param props
   */
  constructor(props) {
    super(props)

    this.newClassForm = new Form({
      className: "form-full",
      onSubmit: (_) => {},
      fields: {
        school: this.state.school,
        teacher: this.state.teacher,
        name: this.state.name,
        jahrgang: this.state.jahrgang,
      },
    })
  }

  /**
   * init
   */
  init = async () => {
    Loader.show()

    if (this.props.store._auth.isEmployee) {
      const response = await this.props.store._api.getAllSchools()

      if (response.error) {
        alert("Fehler beim Laden der Schulen")
        this.props.history.replace("/")
        Loader.hide()
        return
      }

      this.setState({
        schools: response.data.map((school) => ({
          ...school,
          confirmed: true,
        })),
      })
    } else {
      await this.props.store.loadSchools(true)

      this.setState({
        schools: this.state.schools.map((school) => ({
          ...school,
          users: [this.props.store._auth.user],
        })),
      })
    }

    Loader.hide()
  }

  get schools() {
    return this.state.schools.filter((school) => school.confirmed)
  }

  getSchoolById(id) {
    return this.schools.find((school) => school.id === parseInt(id))
  }

  get school() {
    return this.getSchoolById(this.state.school.value)
  }

  get teachers() {
    return this.school?.users ?? []
  }

  getTeacherById(id) {
    return this.teachers.find((teacher) => teacher.id === parseInt(id))
  }

  get teacher() {
    return this.getTeacherById(this.state.teacher.value)
  }

  get schoolYear() {
    if (this.state.schoolYear.value.length > 0) {
      return this.state.schoolYear.value
    }

    let year = new Date().getFullYear().toString().substring(2, 4)
    let month = new Date().getMonth() + 1

    if (this.state.date) {
      year = this.state.date.day.year.toString().substring(2, 4)
      month = this.state.date.day.month
    }

    year = parseInt(year)

    return month < 8 ? `${year - 1}/${year}` : `${year}/${year + 1}`
  }

  get isSchoolYearValid() {
    return this.schoolYear.match(/^[\d]{2}\/[\d]{2}$/)
  }

  async submit(e) {
    e.preventDefault()

    if (!["8", "9"].includes(this.state.jahrgang.value)) {
      this.setState({
        jahrgang: Object.assign(this.state.jahrgang, { error: true }),
      })
      return
    }

    if (!this.isSchoolYearValid) {
      this.setState({
        schoolYear: Object.assign(this.state.schoolYear, {
          error: true,
        }),
      })
      return
    }

    Loader.show()

    const name = `${this.state.name.value} - ${this.schoolYear}`

    const classResult = await this.props.store.persistClass(
      name,
      32,
      this.school,
      this.teacher,
      this.state.jahrgang.value
    )

    if (classResult.error) {
      alert("Fehler beim Speichern der Klasse")
      this.props.history.replace("/")
      Loader.hide()
      return
    }

    const bookingResult = await this.props.store.persistBooking({
      date: this.state.date.day,
      slot: this.state.date.slot,
      schoolClass: classResult.data,
    })

    if (bookingResult.error) {
      alert("Fehler beim Speichern der Buchung")
      this.props.history.replace("/")
      Loader.hide()
      return
    }

    const emailResult = await this.props.store._api.sendClassEmailConfirmation({
      classname: name,
      size: 32,
      school: this.school,
      teacher: this.props.store._auth.user,
      date: this.state.date.day,
      slot: this.state.date.slot,
    })

    if (emailResult.error) {
      alert("Fehler beim Senden der E-Mail")
      this.props.history.replace("/")
      Loader.hide()
      return
    }

    await this.props.store.loadClasses(true)

    this.props.history.replace(
      this.props.store.routes.intern_class.path.to.replace(
        ":classId",
        classResult.data.id
      )
    )

    Loader.hide()
  }

  view() {
    const { store } = this.props
    const { school, teacher, name, date, schoolYear, jahrgang } = this.state

    return (
      <section className={"intern_add_class"}>
        <Link {...store.routes.intern_start.path} className={"close"}>
          <Close />
        </Link>

        <h1>{this.getText("08.WT.0400.HL")}</h1>
        <p>{this.getText("08.WT.0400.C.01")}</p>

        <Form {...this.newClassForm.props}>
          <div className={"grid-2"}>
            <div>
              <h2>{this.getText("08.WT.0400.SL.01")}</h2>

              <div className={"form-row button-row"}>
                <Select
                  {...school}
                  label={this.getText("09.ST.0900.L")}
                  options={this.schools.map((school) => ({
                    value: school.id,
                    title: `${school.name} (${school.number}) | ${school.users?.length}`,
                  }))}
                />
              </div>
              {this.schools.length === 0 && (
                <div className={"overlay hint"}>
                  {this.getText("08.WT.0400.H")}
                </div>
              )}

              {this.school && store._auth.isEmployee && (
                <>
                  <div className={"form-row button-row"}>
                    <Select
                      {...teacher}
                      label={this.getText("08.ST.0200.M.05")}
                      options={this.teachers.map((teacher) => ({
                        value: teacher.id,
                        title: `${teacher.salutation} ${teacher.forename} ${teacher.lastname}`,
                      }))}
                    />
                  </div>
                  {this.teachers.length === 0 && (
                    <div className={"overlay hint"}>
                      {this.getText("09.ST.0900.DF")}
                    </div>
                  )}
                </>
              )}

              {this.teacher && (
                <div>
                  <div className={"form-row"}>
                    <Input
                      {...name}
                      style={{ flex: "1 1 75%" }}
                      label={this.getText("08.WT.0400.EF.01")}
                    />
                    <Input
                      {...jahrgang}
                      label={this.getText("09.ST.0900.L.02")}
                    />
                    <Input
                      {...schoolYear}
                      value={this.schoolYear}
                      label={this.getText("09.ST.0900.L.03")}
                    />
                  </div>
                  <div className={"form-row"}>
                    <p className={"info-text"}>
                      {this.getText("08.WT.0420.H")}
                    </p>
                  </div>
                </div>
              )}

              <h2>{this.getText("08.WT.0400.SL.03")}</h2>
              <div className={"downloads"}>
                <div className={"link__arrow"}>
                  <a
                    href={
                      process.env.REACT_APP_API +
                      "/uploads/Schuelerdaten_Vorlagen_3058697eff.xlsx"
                    }
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    {this.getText("08.WT.0400.BT.L.02")}
                  </a>
                </div>
                <div className={"link__arrow"}>
                  <a
                    href={
                      process.env.REACT_APP_API +
                      "/uploads/Einwilligungserklaerung_Schueler_d54bc4ef1b.pdf"
                    }
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    {this.getText("08.WT.0400.BT.L.03")}
                  </a>
                </div>
              </div>

              <h2>{this.getText("08.WT.0400.SL.04")}</h2>
              <div>{this.getText("08.WT.0400.C.02", true)}</div>
            </div>

            <div>
              <h2>{this.getText("08.WT.0400.SL.02")}</h2>
              <Calendar
                store={this.props.store}
                onSubmit={(date) => this.setState({ date })}
              />
            </div>
          </div>
          <div className={"form-footer"}>
            <button
              type={"button"}
              className={"form-button"}
              disabled={!this.newClassForm.isValid || !date}
              onClick={(e) => this.submit(e)}
            >
              {this.getText("08.WT.0400.BT.02")}
            </button>
          </div>
        </Form>
      </section>
    )
  }
}

export default withRouter(AddClass)

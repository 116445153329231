import "../../styles/Documents.scss"

import React from "react"
import { Link } from "react-router-dom"

import PageIntern from "../../components/PageIntern"
import { ReactComponent as Close } from "../../images/IMG_Close_Icon.svg"

export default class Documents extends PageIntern {
  state = Object.assign(this.state, {
    view: this.props.store.view,
  })

  /**
   * render view
   * @returns {JSX.Element}
   */
  view() {
    const { store } = this.props

    return (
      <section>
        <Link {...store.routes.intern_start.path} className={"close"}>
          <Close />
        </Link>

        <h1>{this.getText("08.WT.1100.HL")}</h1>

        <div className={"intern_documents--container"}>
          <div className={"left"}>
            <h2>{this.getText("08.WT.1100.SL.01")}</h2>
            <p>{this.getText("08.WT.1100.C.01")}</p>
          </div>

          <div className={"right"}>
            <div className={"link__arrow--down"}>
              <a
                href={
                  process.env.REACT_APP_API +
                  "/uploads/Leitfaden_Lehrkraefte_2022_9e69be1013.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                {this.getText("08.WT.1100.BT.L.01")}
              </a>
            </div>
          </div>
        </div>

        <div className={"intern_documents--container"}>
          <div className={"left"}>
            <h2>{this.getText("08.WT.1100.SL.02")}</h2>
            <p>{this.getText("08.WT.1100.C.02")}</p>
          </div>

          <div className={"right"}>
            <div className={"link__arrow--down"}>
              <a
                href={
                  process.env.REACT_APP_API +
                  "/uploads/Einwilligungserklaerung_Schueler_d54bc4ef1b.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                {this.getText("08.WT.1100.BT.L.02")}
              </a>
            </div>
          </div>
        </div>

        <div className={"intern_documents--container"}>
          <div className={"left"}>
            <h2>{this.getText("08.WT.1100.SL.03")}</h2>
            <p>{this.getText("08.WT.1100.C.03")}</p>
          </div>

          <div className={"right"}>
            <div className={"link__arrow--down"}>
              <a
                href={
                  process.env.REACT_APP_API +
                  "/uploads/Schuelerdaten_Vorlagen_3058697eff.xlsx"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                {this.getText("08.WT.1100.BT.L.03")}
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

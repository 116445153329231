// mode = 'month' | 'year'
export const OldWorkload = ({
  average_value,
  average,
  mode,
  statisticData,
  current_date,
  year,
  month,
  current_average,
  current_average_value,
}) => {
  return (
    <div className={"intern_statistics--charts_month"}>
      <div
        className={
          "intern_statistics--charts_workload intern_statistics--charts_month--workload"
        }
      >
        <div className={"y-legend"}>
          <div className={"p-100"}>3</div>
          <div className={"p-66"}>2</div>
          <div className={"p-33"}>1</div>
          <div className={"p-0"}>0</div>
        </div>

        <div className={"y-helper"}>
          <div className={"p-100"}>&nbsp;</div>
          <div className={"p-66"}>&nbsp;</div>
          <div className={"p-33"}>&nbsp;</div>
          <div className={"p-0"}>&nbsp;</div>

          <div
            className={"average"}
            data-content={average_value}
            style={{ bottom: `${average}%` }}
          >
            &nbsp;
          </div>

          {mode === "year" && (
            <div
              className={"average average--current"}
              data-content={current_average_value}
              style={{ bottom: `${current_average}%` }}
            >
              &nbsp;
            </div>
          )}
        </div>

        <div className={"x-legend"}>
          {mode === "year" && (
            <>
              {statisticData?.[mode]?.map((data, i) => (
                <div key={`x-legend${i}`} className={`month month-${i + 1}`}>
                  {data.month}
                </div>
              ))}
            </>
          )}

          {mode === "month" && (
            <>
              {statisticData?.[mode]?.map((data, i) => (
                <div key={`x-legend${i}`} className={`day day-${i + 1}`}>
                  {data.day}
                </div>
              ))}
            </>
          )}
        </div>

        <div className={"x-values"}>
          {mode === "month" && (
            <>
              {statisticData?.[mode]?.map((data, i) => (
                <div
                  key={`x-values${i}`}
                  className={
                    "dayvalue dayvalue-" +
                    (i + 1) +
                    (new Date(current_date.replace(/-/g, "/")) <
                    new Date(
                      (
                        year +
                        "-" +
                        (month + 1) +
                        "-" +
                        parseInt(data.day)
                      ).replace(/-/g, "/")
                    )
                      ? " dayvalue--grey"
                      : "")
                  }
                  style={{
                    height: `${+(isNaN(data.value) ? 0 : data.value)}%`,
                  }}
                >
                  &nbsp;
                </div>
              ))}
            </>
          )}
          {mode === "year" && (
            <>
              {statisticData?.[mode]?.map((data, i) => (
                <div
                  key={`x-values${i}`}
                  className={
                    "monthvalue monthvalue-" +
                    (i + 1) +
                    (new Date(current_date.replace(/-/g, "/")) <
                    new Date(`${year}-${data.month}-31`.replace(/-/g, "/"))
                      ? " monthvalue--grey"
                      : "")
                  }
                  style={{
                    height: `${+(isNaN(data.value) ? 0 : data.value)}%`,
                  }}
                >
                  &nbsp;
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

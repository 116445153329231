export default class Observable {
  /**
   * constructor
   */
  constructor() {
    this.observers = []
  }

  /**
   * add the ability to subscribe to a new object
   * @param f
   */
  subscribe(f) {
    this.observers.push(f)
  }

  /**
   * add the ability to unsubscribe from a particular object
   * @param f
   */
  unsubscribe(f) {
    this.observers = this.observers.filter((subscriber) => subscriber !== f)
  }

  /**
   * update all subscribed objects
   */
  notify(data) {
    this.observers.forEach((observer) => observer.setState(data))
  }
}

import "../styles/Calendar.scss"

import React from "react"

import Input from "./Input"

export default class CalendarSheet extends Input {
  today = new Date()

  state = {
    days: null,
    day: null,
    date: this.props.date || null,
    month: parseInt(this.props.month) || this.today.getMonth() + 1,
    year: parseInt(this.props.year) || this.today.getFullYear(),
    loading: false,
  }

  /**
   * init
   */
  componentDidMount() {
    const { date, month, year } = this.state

    this.getDays(month, year, (_) => this.setDate(date))
  }

  /**
   * props date changed
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.date !== this.props.date) {
      this.setDate(this.props.date)
    }
    if (prevProps.classes !== this.props.classes) {
      this.getDays(this.props.month, this.props.year, (_) =>
        this.setDate(this.props.date)
      )
    }
    if (this.state.month !== parseInt(this.props.month)) {
      const { month, year } = this.props
      this.setState({ month, year }, this.getDays(month, year))
    }
  }

  /**
   * set a extern date
   */
  setDate = (newDate) => {
    //const {month} = this.state

    this.setState(
      {
        day: newDate /*&& newDate.month === month ? newDate : null*/,
        date: newDate,
      },
      this.props.onChange && this.props.onChange({ day: newDate })
    )
  }

  /**
   * day click handling
   * @param date
   */
  dayClicked = (date) => this.setDate(date)

  /**
   * get days by month and year
   * @param month
   * @param year
   * @param callback
   */
  getDays(month, year, callback = null) {
    this.setState({ loading: true }, (_) =>
      this.props.store._api
        .getDates(month, year)
        .then((dates) =>
          this.setState(
            { days: dates ? dates.data : [], month, year },
            callback
          )
        )
        .then((_) => this.setState({ loading: false }))
    )
  }

  /**
   * submit handling
   */
  submit = () => {
    const { day } = this.state

    this.props.onSubmit && this.props.onSubmit({ day })
  }

  /**
   * get header (month and year)
   * @returns {string}
   */
  get header() {
    const date = new Date(`${this.state.year}/${this.state.month}/01`)

    return (
      date.toLocaleString("de-DE", { month: "long" }) +
      " " +
      date.toLocaleString("de-DE", { year: "numeric" })
    )
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { month, days, loading } = this.state

    return (
      <div className={"calendar calendar--sheet"}>
        <div className={"calendar__month"}>
          <span>{this.header}</span>
        </div>
        <div className={"calendar__weekdays grid-7"}>
          <div className={"calender__weekday"}>Mo</div>
          <div className={"calender__weekday"}>Di</div>
          <div className={"calender__weekday"}>Mi</div>
          <div className={"calender__weekday"}>Do</div>
          <div className={"calender__weekday"}>Fr</div>
          <div className={"calender__weekday"}>Sa</div>
          <div className={"calender__weekday"}>So</div>
        </div>
        <div className={"calendar__days grid-7"}>
          {days &&
            days.map((day, i) => {
              const classNames = ["calendar__day"]
              const classIds = this.props.classes.map(
                (schoolClass) => schoolClass.id
              )

              day.date === this.today.toLocaleDateString("de-DE") &&
                day.month === month &&
                classNames.push("calendar__day--today")

              day.month !== month && classNames.push("calendar__day--inactive")

              day.slots.find((slot) => slot.bookable) &&
                classNames.push("calendar__day--bookable")

              day.slots.find(
                (slot) => slot.booked && classIds.includes(slot.booked)
              ) && classNames.push("calendar__day--booked")

              this.state.day &&
                day.date === this.state.day.date &&
                classNames.push("calendar__day--selected")

              return (
                <div
                  className={classNames.join(" ")}
                  key={`day-${i}`}
                  onClick={(_) => this.dayClicked(day)}
                >
                  {day.day}
                </div>
              )
            })}
        </div>
        {loading && (
          <div className={"calendar__loader lds"}>
            <div className="lds-dual-ring" />
          </div>
        )}
      </div>
    )
  }
}

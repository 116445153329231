import PropTypes from "prop-types"

import { ReactComponent as Edit } from "../../../images/BTN_Edit.svg"
import { ReactComponent as Trash } from "../../../images/BTN_Loeschen.svg"

const StudentTableRow = ({
  birthday,
  city,
  formatDate,
  gender,
  getText,
  name,
  onClickDelete,
  onClickEdit,
  removeStudent,
  showOverlay,
  street,
  surname,
  zip,
}) => {
  return (
    <div className={"table__row grid-8"}>
      <div>
        <div className={"students-list__student--delete"}>
          <button
            type={"button"}
            className={"clean-button"}
            onClick={onClickDelete}
          >
            <Trash />
          </button>
          {showOverlay && (
            <div className={"overlay"}>
              <p>{getText("09.ST.0900.DF.02")}</p>
              <button
                type={"button"}
                className={"form-button"}
                onClick={removeStudent}
              >
                {getText("08.WT.0820.DF.BT")}
              </button>
            </div>
          )}
        </div>
        <div className={"students-list__student--edit"}>
          <button
            type={"button"}
            className={"clean-button edit"}
            onClick={onClickEdit}
          >
            <Edit />
          </button>
        </div>
      </div>
      <div>
        <span className={"label_mobile"}>{getText("08.WT.0800.TH.01")}: </span>
        {surname}
      </div>
      <div>
        <span className={"label_mobile"}>{getText("08.WT.0800.TH.02")}: </span>
        {name}
      </div>
      <div>
        <span className={"label_mobile"}>{getText("08.WT.0800.TH.03")}: </span>
        {formatDate(birthday)}
      </div>
      <div>
        <span className={"label_mobile"}>{getText("08.WT.0800.TH.04")}: </span>
        {gender}
      </div>
      <div>
        <span className={"label_mobile"}>{getText("08.WT.0800.TH.05")}: </span>
        {street}
      </div>
      <div>
        <span className={"label_mobile"}>{getText("08.WT.0800.TH.06")}: </span>
        {zip}
      </div>
      <div>
        <span className={"label_mobile"}>{getText("08.WT.0800.TH.07")}: </span>
        {city}
      </div>
    </div>
  )
}

StudentTableRow.propTypes = {
  birthday: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  formatDate: PropTypes.func.isRequired,
  gender: PropTypes.string.isRequired,
  getText: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  removeStudent: PropTypes.func.isRequired,
  showOverlay: PropTypes.bool.isRequired,
  street: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
}

export { StudentTableRow }

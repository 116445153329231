import PropTypes from "prop-types"
import { useMemo } from "react"
import { Cell } from "recharts"

import {
  TextAndPieChartStatistics,
  TextLine,
} from "../TextAndPieChartStatistics"

const COLORS = { m: "#eaeaea", f: "#d5d4cf", d: "#fae760" }

const GenderStatistics = ({ statisticsData, getText }) => {
  const data = useMemo(() => {
    const data = {
      d: { display: getText("08.ST.0500.L.02"), value: 0, key: "d" },
      f: { display: getText("08.ST.0500.L.03"), value: 0, key: "f" },
      m: { display: getText("08.ST.0500.L.04"), value: 0, key: "m" },
    }

    statisticsData?.statistics?.forEach((stats) => {
      if (!stats.participated) {
        return
      }

      data.d.value +=
        stats.gender_d_registered_with_result +
        stats.gender_d_registered_later_with_result

      data.m.value +=
        stats.gender_m_registered_with_result +
        stats.gender_m_registered_later_with_result

      data.f.value +=
        stats.gender_f_registered_with_result +
        stats.gender_f_registered_later_with_result
    })

    return data
  }, [getText, statisticsData?.statistics])

  const chartData = useMemo(() => Object.values(data), [data])

  const Text = () => {
    return (
      <>
        <TextLine color={COLORS.d} text={data.d.display} value={data.d.value} />
        <TextLine color={COLORS.f} text={data.f.display} value={data.f.value} />
        <TextLine color={COLORS.m} text={data.m.display} value={data.m.value} />
      </>
    )
  }

  return (
    <TextAndPieChartStatistics
      data={chartData}
      text={<Text />}
      description={getText("08.ST.0500.C.02")}
    >
      {chartData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[entry.key]} />
      ))}
    </TextAndPieChartStatistics>
  )
}

GenderStatistics.propTypes = {
  statisticsData: PropTypes.object,
}

export { GenderStatistics }

import "./styles/App.scss"

import React from "react"
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom"

import Loader from "./components/Loader"
import Time from "./components/Time"
import { ReactComponent as Door } from "./images/door.svg"
import { ReactComponent as User } from "./images/IMG_Benutzer-Icon.svg"
import { ReactComponent as Like } from "./images/like.svg"
import { ReactComponent as Lock } from "./images/lock.svg"
import Logo from "./images/logo.svg"
import { ReactComponent as QuestionMark } from "./images/questionmark.svg"
import AccountPopup from "./pages/intern/AccountPopup"
import Store from "./stores/Store"

/**
 * main app class
 */
export default class App extends React.Component {
  state = {
    route: "home",
    routes: null,
    scrolled: false,
    loggedIn: false,
    greeting: null,
    incomplete: false,
    popup: false,
  }

  /**
   * scroll event listener
   * @param _
   * @private
   */
  _scrollListener = (_) => {
    window.scrollY > 0 &&
      !this.state.scrolled &&
      this.setState({ scrolled: true })
    window.scrollY === 0 &&
      this.state.scrolled &&
      this.setState({ scrolled: false })
  }

  /**
   * constructor
   */
  constructor() {
    super()

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Berufsorientierung mal anders: Im Talente Check Berlin können Berliner Jugendliche ohne Leistungsdruck ihre Kompetenzen im Hinblick auf ihre spätere Berufswahl erkunden!"
      )
  }

  /**
   * mount
   */
  componentDidMount() {
    // init store
    this.store = new Store()
    this.store.subscribe(this)

    document.addEventListener("scroll", this._scrollListener)
  }

  /**
   * unmount
   */
  componentWillUnmount() {
    document.removeEventListener("scroll", this._scrollListener)

    clearInterval(this.interval)
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { route, routes, loggedIn, greeting, incomplete, popup } = this.state
    const internView = loggedIn && route.split("_")[0] === "intern"

    return (
      routes && (
        <Router>
          <header role={"banner"}>
            <div className={`header__inner ${route}`}>
              <div className={"logo"}>
                <Link {...routes.home.path}>
                  <img src={Logo} alt={"Logo vom Talente Check Berlin"} />
                </Link>
              </div>
              {internView && <div className={"greeting"}>{greeting}</div>}
              {internView && (
                <div
                  className={`account ${
                    !this.store._auth.isEmployee && incomplete
                      ? "incomplete"
                      : ""
                  }`}
                >
                  {this.store._auth.isEmployee ? (
                    <Time />
                  ) : this.store._auth.isReadonly ? (
                    ""
                  ) : (
                    <User
                      onClick={(_) =>
                        this.setState({ popup: !this.state.popup })
                      }
                    />
                  )}
                  {(this.store._auth.isEmployee ||
                    this.store._auth.isReadonly) && (
                    <Link
                      to={"#"}
                      onClick={(_) => {
                        this.store._auth.clear()
                        this.store.notify({ loggedIn: false, bouncer: true })
                        this.store.clearData()
                      }}
                    >
                      Logout
                    </Link>
                  )}
                </div>
              )}
              {!internView && (
                <div className={"top-nav"} role={"menu"}>
                  <Link {...routes.press.path} role={"menuitem"}>
                    {routes.press.title}
                  </Link>
                  <Link {...routes.imprint.path} role={"menuitem"}>
                    {routes.imprint.title}
                  </Link>
                </div>
              )}

              {internView && popup && (
                <div
                  className={"account-popup"}
                  onClick={(_) => {
                    this.setState({ popup: false })
                  }}
                >
                  <AccountPopup store={this.store} />
                </div>
              )}
            </div>
          </header>
          <section className={`main ${route}`}>
            <Loader />
            <div className={"tiles"} role={"menu"}>
              <div className={"tile"}>
                <Link {...routes.about.path} role={"menuitem"}>
                  <span>{routes.about.title}</span>
                  <Like />
                </Link>
              </div>
              <div className={"tile"}>
                <Link {...routes.login.path} role={"menuitem"}>
                  <span>{routes.login.title}</span>
                  <Lock />
                </Link>
              </div>
              <div className={"tile"}>
                <Link {...routes.location.path} role={"menuitem"}>
                  <span>{routes.location.title}</span>
                  <Door />
                </Link>
              </div>
              <div className={"tile"}>
                <Link {...routes.faq.path} role={"menuitem"}>
                  <span>{routes.faq.title}</span>
                  <QuestionMark />
                </Link>
              </div>
            </div>
            <article
              className={`content ${this.state.scrolled ? "" : "scroll-arrow"}`}
            >
              <Switch>
                {Object.entries(routes).map((routes) => {
                  const [key, route] = routes
                  return (
                    <Route
                      key={key}
                      path={route.path.to}
                      exact={route.exact === true}
                    >
                      <route.component
                        path={route.path.to}
                        store={this.store}
                        route={key}
                        params={""}
                      />
                    </Route>
                  )
                })}
              </Switch>
              {
                <footer>
                  <ul className={"bottom-nav"} role={"menu"}>
                    <li role={"none"}>
                      <Link {...routes.data_protection.path} role={"menuitem"}>
                        {routes.data_protection.title}
                      </Link>
                    </li>
                    <li role={"none"}>
                      <Link {...routes.imprint.path} role={"menuitem"}>
                        {routes.imprint.title}
                      </Link>
                    </li>
                  </ul>
                </footer>
              }
            </article>
          </section>
        </Router>
      )
    )
  }
}

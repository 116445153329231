/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../../styles/StudentsList.scss"

import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import React from "react"
import { Link, withRouter } from "react-router-dom"

import Form from "../../../components/Form"
import Input from "../../../components/Input"
import Loader from "../../../components/Loader"
import PageIntern from "../../../components/PageIntern"
import { Unterstuetzungsbedarf } from "../../../components/Unterstuetzungsbedarf"
import { ReactComponent as Close } from "../../../images/IMG_Close_Icon.svg"
import { StudentForm } from "./StudentForm"
import { StudentTableRow } from "./StudentTableRow"

dayjs.extend(customParseFormat)

class StudentsList extends PageIntern {
  state = Object.assign(this.state, {
    showCalendar: false,
    confirmOverlay: false,
    removeOverlay: null,
    hintOverlay: false,
    uploadOverlay: false,
    resetFormOverlay: false,
    student: null,
    showForm: false,
    unterstuetzungsbedarf: {
      value: false,
      name: "unterstuetzungsbedarf",
      type: "checkbox",
      error: false,
      required: true,
    },
    file: {
      value: "",
      name: "file",
      type: "file",
      errorMsg: "Ungültige Datei",
      error: false,
      required: true,
      onChange: (file) => this.uploadFile(file),
    },
    name: {
      value: "",
      name: "name",
      type: "text",
      errorMsg: "Ungültiger Name",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ name: Object.assign(this.state.name, value) }),
    },
    forename: {
      value: "",
      name: "forename",
      type: "text",
      errorMsg: "Ungültiger Vorname",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ forename: Object.assign(this.state.forename, value) }),
    },
    birthdate: {
      value: "",
      name: "birthdate",
      type: "text",
      errorMsg: "Ungültiges Geburtsdatum",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({
          birthdate: Object.assign(this.state.birthdate, value),
        }),
    },
    gender: {
      value: "",
      name: "gender",
      maxLength: 1,
      minLength: 1,
      pattern: "[mwd]",
      type: "text",
      errorMsg: "Ungültiges Geschlecht",
      error: false,
      required: true,
      onChange: (value, error) => {
        this.setState({
          gender: Object.assign(this.state.gender, {
            value,
            error: error ?? this.state.gender.error,
          }),
        })
      },
    },
    street: {
      value: "",
      name: "street",
      type: "text",
      errorMsg: "Ungültige Straße",
      error: false,
      required: true,
      onChange: (value) => {
        this.setState({ street: Object.assign(this.state.street, value) })
      },
    },
    zip: {
      value: "",
      name: "zip",
      type: "text",
      errorMsg: "Ungültige Postleitzahl",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ zip: Object.assign(this.state.zip, value) }),
    },
    city: {
      value: "",
      name: "city",
      type: "text",
      errorMsg: "Ungültige Stadt",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ city: Object.assign(this.state.city, value) }),
    },
  })

  /**
   * constructor
   * @param props
   */
  constructor(props) {
    super(props)

    this.newStudentForm = new Form({
      className: "form-full",
      onSubmit: (_) => {},
      fields: {
        name: this.state.name,
        forename: this.state.forename,
        birthdate: this.state.birthdate,
        gender: this.state.gender,
        street: this.state.street,
        zip: this.state.zip,
        city: this.state.city,
      },
    })

    this.newFileUpload = new Form({
      className: "form-full",
      onSubmit: (_) => {},
      fields: { file: this.state.file },
    })

    this.addStudentForm = React.createRef()
    this.inputFile = React.createRef()
    this.inputName = React.createRef()
  }

  onUploadButtonClick = () => {
    this.setState({ uploadOverlay: false })
    this.inputFile.current.click()
  }

  /**
   * init
   */
  init = () => {
    this.newStudentForm.reset()

    this.setState({
      showCalendar: false,
      confirmOverlay: false,
      removeOverlay: null,
      hintOverlay: false,
      uploadOverlay: false,
      resetFormOverlay: false,
      student: null,
      file: Object.assign(this.state.file, { value: "" }),
    })

    const { match } = this.props
    this.classId = match && match.params["classId"]

    this.loadClass(this.classId, true)
  }

  /**
   * overlay listener
   * @param e
   * @private
   */
  overlayListener = (e) => {
    if (!e.target.closest(".overlay"))
      this.setState({
        showCalendar: false,
        confirmOverlay: false,
        removeOverlay: null,
        hintOverlay: false,
        uploadOverlay: false,
        resetFormOverlay: false,
      })
  }

  /**
   * load one class by id
   * @param classId
   * @param force
   */
  loadClass(classId, force = false) {
    Loader.show()
    this.props.store
      .loadOneClass(classId, force)
      .then((_) => this.loadClassData())
      .then((_) => Loader.hide())
  }

  loadClassData() {
    const { _schoolClass: currentClass } = this.props.store

    this.setState({
      // checks the size of a class
      showForm: currentClass?.students && !!currentClass.students.length < 32,
      unterstuetzungsbedarf: Object.assign(this.state.unterstuetzungsbedarf, {
        ...this.state.unterstuetzungsbedarf,
        value: !!currentClass.help_needed,
        onClick: (_ev) => {
          this.props.store.updateClass({
            ...currentClass,
            help_needed: !this.state.unterstuetzungsbedarf.value,
          })

          this.setState({
            unterstuetzungsbedarf: {
              ...this.state.unterstuetzungsbedarf,
              value: !this.state.unterstuetzungsbedarf.value,
            },
          })
        },
      }),
    })
  }

  // /**
  //  * get all gender dropdown options
  //  * @returns {[]}
  //  */
  // get genders() {
  //   return [
  //     { value: "maennlich", title: this.getText("08.WT.0810.DM-01") },
  //     { value: "weiblich", title: this.getText("08.WT.0810.DM-02") },
  //     { value: "divers", title: this.getText("08.WT.0810.DM-03") },
  //   ]
  // }

  /**
   * handle upload file
   */
  async uploadFile() {
    const file = this.inputFile.current.files[0]

    if (file.name.length <= 0) {
      return
    }

    Loader.show()

    this.inputFile.current.files = null
    const fReader = new FileReader()
    fReader.readAsDataURL(file)

    await new Promise((resolve) => {
      fReader.onloadend = resolve
    })

    const result = await this.props.store.persistStudents(
      parseInt(this.classId),
      fReader
    )

    if (result.error) {
      console.log(result)
      alert(
        "Beim Upload ist ein Fehler aufgetreten. Bitte prüfen Sie den Inhalt der Datei."
      )
    }

    this.props.store.loadClasses(true)

    this.init()
  }

  /**
   * remove student
   * @param student
   */
  removeStudent = async (student) => {
    if (!student) return

    Loader.show()

    await this.props.store.deleteStudent(student.id)
    this.props.store.loadClasses(true)

    this.init()
  }

  /**
   * reset the form
   */
  resetForm = () => {
    this.newStudentForm.reset()

    this.setState({
      resetFormOverlay: false,
      student: null,
    })
  }

  /**
   * save student
   */
  saveStudent = async () => {
    const { name, forename, street, zip, city, birthdate, gender, student } =
      this.state
    const streetWithoutNumber = street.value.split(" ")
    const housenumber = streetWithoutNumber.pop() || ""

    if (!/^\d{5}$/.test(zip.value)) {
      this.setState({
        zip: Object.assign(this.state.zip, {
          error: true,
        }),
      })
      return
    }

    const birthdayFormat1 = dayjs(birthdate.value, "D.M.YYYY", true)
    const birthdayFormat2 = dayjs(birthdate.value, "DD.MM.YYYY", true)

    if (
      !/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(birthdate.value) ||
      (!birthdayFormat1.isValid() && !birthdayFormat2.isValid()) ||
      (birthdayFormat1.isValid() && birthdayFormat1.isAfter(dayjs())) ||
      (birthdayFormat2.isValid() && birthdayFormat2.isAfter(dayjs()))
    ) {
      this.setState({
        birthdate: Object.assign(this.state.birthdate, {
          error: true,
        }),
      })
      return
    }

    Loader.show()

    const mappedGender = { ...gender }

    // Neue Eingabe von nur einem Buchstaben auf Ursprüngliche Datenstruktur ändern
    // eslint-disable-next-line default-case
    switch (mappedGender.value.toLowerCase()) {
      case "m":
        mappedGender.value = "maennlich"
        break
      case "w":
        mappedGender.value = "weiblich"
        break
      case "d":
        mappedGender.value = "divers"
        break
    }

    await this.props.store.persistStudent({
      id: student ? student.id : null,
      lastname: name.value,
      forename: forename.value,
      street: streetWithoutNumber.join(" "),
      housenumber,
      zip: zip.value,
      city: city.value,
      birthdate: new Date(birthdate.value.split(".").reverse().join("-")),
      gender: mappedGender.value.replace("ä", "ae"),
      class: this.state.schoolClass,
    })

    this.props.store.loadClasses(true)

    this.init()
  }

  /**
   * handle new students submit
   */
  submitNewStudents = (class_id) => {
    this.props.store.submitNewStudents(class_id)
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  view() {
    const { store } = this.props
    const {
      unterstuetzungsbedarf,
      schoolClass,
      removeOverlay,
      confirmOverlay,
      hintOverlay,
      uploadOverlay,
      resetFormOverlay,
      showForm,
      name,
      forename,
      birthdate,
      gender,
      street,
      zip,
      city,
      file,
      student,
    } = this.state

    return (
      <section className={"students-list"}>
        <Link
          to={store.routes.intern_class.path.to.replace(
            ":classId",
            this.classId
          )}
          className={"close"}
        >
          <Close />
        </Link>

        {schoolClass && (
          <div className={"students-list__header form"}>
            {schoolClass && store._auth.isEmployee && (
              <div>
                <div className={"link__arrow"}>
                  <a
                    href={"#"}
                    onClick={(_) => this.submitNewStudents(schoolClass?.id)}
                  >
                    {this.getText("09.ST.0900.DF.03")}
                  </a>
                </div>
              </div>
            )}
            <h1>
              {this.getText("08.WT.0800.HL").replace(
                "[school]",
                schoolClass?.school?.name
              )}
            </h1>
            <h2>
              {this.getText("08.WT.0800.SL").replace(
                "[class]",
                schoolClass?.classname
              )}
            </h2>
            <p>
              {this.getText("08.WT.0800.C")
                .replace("[entries]", schoolClass?.students?.length)
                .replace("[classSize]", schoolClass?.size)}
            </p>
          </div>
        )}

        <div className={"table table--students"} role={"table"}>
          <div className={"table__header grid-8"}>
            <div />
            <div>{this.getText("08.WT.0800.TH.01")}</div>
            <div>{this.getText("08.WT.0800.TH.02")}</div>
            <div>{this.getText("08.WT.0800.TH.03")}</div>
            <div>{this.getText("08.WT.0800.TH.04")}</div>
            <div>{this.getText("08.WT.0800.TH.05")}</div>
            <div>{this.getText("08.WT.0800.TH.06")}</div>
            <div>{this.getText("08.WT.0800.TH.07")}</div>
          </div>

          <StudentForm
            displayForm={schoolClass && showForm}
            birthday={birthdate}
            city={city}
            confirmOverlay={confirmOverlay}
            gender={gender}
            getText={(key) => this.getText(key)}
            inputName={this.inputName}
            isChanged={this.isChanged}
            name={forename}
            surname={name}
            newStudentForm={this.newStudentForm}
            openConfirmOverlay={() => this.setState({ confirmOverlay: true })}
            openResetOverlay={() => this.setState({ resetFormOverlay: true })}
            resetForm={() => this.resetForm()}
            resetFormOverlay={resetFormOverlay}
            saveStudent={() => this.saveStudent()}
            street={street}
            student={student}
            studentFormRef={this.addStudentForm}
            zip={zip}
          />

          <div className={"table__content"}>
            {schoolClass?.students.map((student) => (
              <StudentTableRow
                birthday={student.birthdate}
                city={student.city}
                gender={student.gender?.replace("ae", "ä")}
                name={student.forename}
                surname={student.lastname}
                onClickEdit={() => {
                  this.resetForm()
                  this.setState(
                    {
                      removeOverlay: null,
                      student: Object.assign({}, student),
                      showForm: true,
                      // gender: Object.assign(this.state.gender, { value: "w" }),
                    },
                    () => this.forceUpdate()
                  )
                }}
                onClickDelete={() =>
                  this.setState({ removeOverlay: student.id })
                }
                removeStudent={() => this.removeStudent(student)}
                showOverlay={removeOverlay === student.id}
                street={`${student.street} ${
                  student.housenumber ?? (student.housenumber !== 0 && "")
                }`}
                zip={student.zip}
                key={`student-${student.id}`}
                getText={(key) => this.getText(key)}
                formatDate={(date) => this.formatDate(date)}
              />
            ))}
          </div>
        </div>

        <Unterstuetzungsbedarf
          inputProps={unterstuetzungsbedarf}
          getText={(key) => this.getText(key)}
        />

        <div className={"students-list__footer"}>
          <div className={"empty-footer"} />
          <div className={"form-footer"}>
            <Form {...this.newStudentForm.props}>
              <Input isHidden={true} innerRef={this.inputFile} {...file} />
            </Form>

            <Link
              to={"#"}
              onClick={(_) => this.setState({ uploadOverlay: true })}
              className={"form-button"}
            >
              {this.getText("08.WT.0800.BT")}
            </Link>

            {uploadOverlay && (
              <div className={"overlay"}>
                <p>{this.getText("08.WT.0800.DF.C")}</p>
                <button
                  type={"button"}
                  className={"form-button"}
                  onClick={(_) => this.onUploadButtonClick()}
                >
                  {this.getText("08.WT.0800.DF.BT")}
                </button>
              </div>
            )}
          </div>

          <div className={"hint-footer"}>
            <div>
              <div className={"link__arrow"}>
                <Link
                  to={"#"}
                  onClick={(_) => this.setState({ hintOverlay: true })}
                >
                  {this.getText("08.WT.0800.BT.L")}
                </Link>
              </div>
              {hintOverlay && (
                <div className={"overlay"}>
                  <p>{this.getText("08.WT.0800.IF.C")}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withRouter(StudentsList)

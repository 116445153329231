import "../styles/Intern.scss"

import React from "react"
import { Redirect } from "react-router-dom"

import Page from "./Page"

export default class PageIntern extends Page {
  /**
   * init
   */
  afterDidMount = () => {
    const { store } = this.props

    const today = new Date()

    // check auth
    store._auth
      .authenticate()
      .then((result) => {
        if (result.data && !result.error) {
          store.notify({
            bouncer: false,
            loggedIn: true,
            incomplete: store._auth.isIncomplete,
            greeting: store._auth.isEmployee
              ? today.toLocaleDateString("de-DE", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })
              : store._auth.isReadonly
              ? "Willkommen"
              : this.getText("08.WT.0300.C")
                  .replace("[titleTeacher]", store._auth.user.salutation)
                  .replace("[surnameTeacher]", store._auth.user.lastname),
          })
        } else {
          store.notify({ loggedIn: false, bouncer: true })
        }
      })
      .then(this.init)
  }

  /**
   * empty wrapper
   */
  init = () => {
    // load all necessary init stuff here after routing and auth procedure
  }

  /**
   * format booking date
   * @param booking
   * @returns {string}
   */
  formatBookingDate = (booking) => {
    const { date, slot } = booking
    const [year, month, day] = date?.split("-")

    return `${day}.${month}.${year.substring(2)} - ${slot?.time.substring(
      0,
      5
    )} Uhr`
  }

  /**
   * format normal date
   * @param date
   * @returns {string}
   */
  formatDate = (date) => {
    if (!date) return ""
    const [year, month, day] = date?.split("-")

    return `${day}.${month}.${year}`
  }

  /**
   * render view
   * @returns {*|null}
   */
  render() {
    const { store } = this.props

    return this.state.texts && this.state.loggedIn ? (
      this.view()
    ) : this.state.bouncer ? (
      <Redirect to={store.routes.sign_in.path.to} />
    ) : null
  }
}

import "../styles/Press.scss"

import React from "react"

import Page from "../components/Page"
import Slider from "../components/Slider"

export default class Press extends Page {
  /**
   * get slider
   * @returns {*|JSX.Element}
   */
  get slider() {
    const { slider } = this.state.data

    return (
      slider && (
        <div className={"container"}>
          <h2>{slider.headline}</h2>
          <Slider slides={slider.slides} />
        </div>
      )
    )
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { data } = this.state

    return (
      data && (
        <section>
          {this.header(data.information)}
          <div className={"two-columns"}>
            {this.contents(data.content)}
            {this.slider}
          </div>
        </section>
      )
    )
  }
}

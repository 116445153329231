import Loader from "../components/Loader"
import Observable from "../components/Observable"
import Routing from "../components/Routing"
import Api from "./Api"
import Auth from "./Auth"

export default class Store extends Observable {
  _api = null
  _observer = null

  _routing = null
  _routes = null
  _route = "home"
  _path = null

  _content = null
  _texts = null
  _loggedIn = false
  _view = null

  _schools = null
  _classes = null
  _schoolClass = null
  _bookings = null
  _teachers = null

  /**
   * constructor
   */
  constructor() {
    super()

    this._api = new Api()
    this._routing = new Routing(this)
    this._auth = new Auth(this)
  }

  /**
   * set routes
   * @param routes
   */
  set routes(routes) {
    this._routes = routes
    this.notify({ routes })
  }

  /**
   * get routes
   * @returns {{}}
   */
  get routes() {
    return this._routes
  }

  /**
   * set route
   * @param route
   */
  set route(route) {
    this._route = route
    this.notify({ route })
  }

  /**
   * get route
   * @returns {string}
   */
  get route() {
    return this._route
  }

  /**
   * set texts
   * @param texts
   */
  set texts(texts) {
    this._texts = texts
    this.notify({ texts })
  }

  /**
   * get texts
   * @returns {null}
   */
  get texts() {
    return this._texts
  }

  /**
   * set classes
   * @param classes
   */
  set classes(classes) {
    this._classes = classes
    this.notify({ classes })
  }

  /**
   * get all classes by teachers
   * @returns {null}
   */
  get classes() {
    return this._classes
  }

  /**
   * set school class
   * @param schoolClass
   */
  set schoolClass(schoolClass) {
    this._schoolClass = schoolClass
    this.notify({ schoolClass })
  }

  /**
   * get school class
   * @returns {null}
   */
  get schoolClass() {
    return this._schoolClass
  }

  /**
   * set schools
   * @param schools
   */
  set schools(schools) {
    this._schools = schools
    this.notify({ schools })
  }

  /**
   * get schools
   * @returns {null}
   */
  get schools() {
    return this._schools
  }

  /**
   * set bookings
   * @param bookings
   */
  set bookings(bookings) {
    this._bookings = bookings
    this.notify({ bookings })
  }

  /**
   * get all bookings
   * @returns {null}
   */
  get bookings() {
    return this._bookings
  }

  /**
   * set view
   * @param view
   */
  set view(view) {
    this._view = view
    this.notify({ view })
  }

  /**
   * get view
   * @returns {string}
   */
  get view() {
    return this._view
  }

  /**
   * set teachers
   * @param teachers
   */
  set teachers(teachers) {
    this._teachers = teachers
    this.notify({ teachers })
  }

  /**
   * get all teachers
   * @returns {null}
   */
  get teachers() {
    return this._teachers
  }

  // *** methods ***

  /**
   * wait for content data
   * @returns {Promise<void>}
   */
  async loadContentData(route, path) {
    this._route = route
    this._path = path
    const { data } = route && this.routes[route]

    if (path && path.length > 0 && !data) {
      const response = await this._api.getPageContent(path)
      const data = !response.error && response.data

      this._routes[this._route].data = response.data
      this.notify({ route, data })
      return
    }
    this.notify({ route, data })
  }

  /**
   * load all texts
   * @returns {Promise<void>}
   */
  async loadTexts() {
    if (this.texts) {
      this.notify({ texts: this.texts })
    } else {
      const response = await this._api.getTexts()
      this.texts = response && !response.error ? response.data : null
    }
  }

  /**
   * get classes by teacher
   * @param force
   * @returns {Promise<void>}
   */
  async loadClasses(force = false) {
    if (!this.classes || force) {
      const response = await this._api.getClasses()
      if (!response.error) {
        this.classes = response.data
      }
    } else {
      this.notify({ classes: this.classes })
    }
  }

  /**
   * get a class by id
   * @param force
   * @returns {Promise<void>}
   */
  async loadOneClass(classId, force = false) {
    if (!this.schoolClass || force) {
      const response = await this._api.getOneClass(classId)
      if (!response.error) {
        this.schoolClass = response.data
      }
    } else {
      this.notify({ schoolClass: this.schoolClass })
    }
  }

  /**
   * get schools by teacher
   * @param force
   * @returns {Promise<void>}
   */
  async loadSchools(force = false) {
    if (!this.schools || force) {
      const response = await this._api.getSchools()
      if (!response.error) {
        this.schools = response.data
      }
    } else {
      this.notify({ schools: this.schools })
    }
  }

  /**
   * get bookings
   * @param force
   * @returns {Promise<void>}
   */
  async loadBookings(force = false) {
    if (!this.bookings || force) {
      const response = await this._api.getBookings()
      if (!response.error) {
        this.bookings = response.data
      }
    } else {
      this.notify({ bookings: this.bookings })
    }
  }

  /**
   * Setzt Daten beim Logout zurück. Evtl muss das noch erweitert werden.
   */
  clearData() {
    this._classes = null
    this._schools = null
    this._bookings = null
    this._teachers = null
    this._schoolClass = null
  }

  /**
   * save new class
   * @param className
   * @param classSize
   * @param school
   * @returns {Promise<*>}
   */
  async persistClass(
    className,
    classSize,
    school,
    teacher = this._auth.user,
    jahrgang
  ) {
    Loader.show()
    const result = await this._api.newClass({
      classname: className,
      size: parseInt(classSize),
      school,
      bookings: [],
      teacher,
      year: jahrgang,
    })

    Loader.hide()

    return result
  }

  async updateClass(schoolClass) {
    Loader.show()

    const result = await this._api.updateClass(schoolClass)

    Loader.hide()

    return result
  }

  /**
   * save new oder changed booking
   * @returns {Promise<*>}
   */
  async persistBooking({ date, slot, schoolClass }) {
    const isUpdate = schoolClass.bookings.length > 0
    const ApiAction = isUpdate ? this._api.updateBooking : this._api.newBooking

    Loader.show()
    const result = await ApiAction({
      id: isUpdate ? schoolClass.bookings[0].id : null,
      classes: schoolClass,
      slots: slot.id,
      date: `${date.year}-${String(date.month).padStart(2, "0")}-${String(
        date.day
      ).padStart(2, "0")}`,
    })
    Loader.hide()
    return result
  }

  /**
   * delete booking
   * @param id
   * @returns {Promise<*>}
   */
  async deleteBooking(id) {
    Loader.show()
    const result = await this._api.deleteBooking({ id })
    Loader.hide()
    return result
  }

  /**
   * save new students
   * @param classId
   * @param file
   * @returns {Promise<*>}
   */
  async persistStudents(classId, file) {
    Loader.show()
    const formData = new FormData()
    formData.append("class", classId)
    formData.append("blob", file.result.split(",")[1])
    const result = await this._api.importStudents(formData)
    Loader.hide()
    return result
  }

  /**
   * save new student
   * @param student
   * @returns {Promise<*>}
   */
  async persistStudent(student) {
    Loader.show()
    const apiAction = student.id
      ? this._api.updateStudent
      : this._api.addStudent

    const result = await apiAction(student)
    Loader.hide()
    return result
  }

  /**
   * delete student
   * @param id
   * @returns {Promise<*>}
   */
  async deleteStudent(id) {
    Loader.show()
    const result = await this._api.deleteStudent({ id })
    Loader.hide()
    return result
  }

  /**
   * delete student
   * @param id
   * @returns {Promise<*>}
   */
  async submitNewStudents(id) {
    Loader.show()
    const result = await this._api.submitNewStudents(id)
    Loader.hide()
    return result
  }

  /**
   * get teachers
   * @param force
   * @returns {Promise<void>}
   */
  async loadTeachers(force = false) {
    if (!this.teachers || force) {
      const response = await this._api.getTeachers()
      if (!response.error) {
        this.teachers = response.data
      }
    } else {
      this.notify({ teachers: this.teachers })
    }
  }

  // *** helper ***

  /**
   * sort helper
   * @param sortResult
   * @param field
   * @param state
   * @private
   */
  sortBy = (sortResult, field, state) => {
    const { sortField, sortDirection } = state

    this.notify({
      classes:
        sortField !== field
          ? sortResult
          : sortDirection === "desc"
          ? sortResult
          : sortResult.reverse(),
      sortField: field,
      sortDirection:
        sortField !== field ? "asc" : sortDirection === "asc" ? "desc" : "asc",
    })
  }
}

import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import Styles from "../BarStatistics.module.scss"
import { monthNames } from "../lib"

const Participants = ({ month, statisticsData, year, getText }) => {
  const strings = useMemo(
    () => [
      getText("08.ST.0500.L.27"),
      getText("08.ST.0500.L.28"),
      getText("08.ST.0500.L.29"),
    ],
    [getText]
  )

  const data = useMemo(() => {
    if (month !== undefined) {
      const date = dayjs().month(month).year(year)

      const data = []

      for (let i = 1; i <= date.daysInMonth(); i++) {
        data.push({
          name: i,
          [strings[0]]: 0,
          [strings[1]]: 0,
          [strings[2]]: 0,
        })
      }

      statisticsData?.statistics?.forEach((stats) => {
        if (!stats.participated) {
          return
        }

        const day = dayjs(stats.visit_date).date() - 1

        if (day > data.length - 1) {
          return
        }

        data[day][strings[0]] +=
          stats.gender_d_registered_with_result +
          stats.gender_m_registered_with_result +
          stats.gender_f_registered_with_result

        data[day][strings[1]] +=
          stats.gender_d_registered_later_with_result +
          stats.gender_m_registered_later_with_result +
          stats.gender_f_registered_later_with_result

        data[day][strings[2]] +=
          stats.gender_d_registered_without_result +
          stats.gender_m_registered_without_result +
          stats.gender_f_registered_without_result +
          stats.gender_d_registered_deletedatday_withoutresult +
          stats.gender_m_registered_deletedatday_withoutresult +
          stats.gender_f_registered_deletedatday_withoutresult
      })

      return data
    }

    const data = [
      {
        name: monthNames[0],
        [strings[0]]: 0,
        [strings[1]]: 0,
        [strings[2]]: 0,
      },
      {
        name: monthNames[1],
        [strings[0]]: 0,
        [strings[1]]: 0,
        [strings[2]]: 0,
      },
      {
        name: monthNames[2],
        [strings[0]]: 0,
        [strings[1]]: 0,
        [strings[2]]: 0,
      },
      {
        name: monthNames[3],
        [strings[0]]: 0,
        [strings[1]]: 0,
        [strings[2]]: 0,
      },
      {
        name: monthNames[4],
        [strings[0]]: 0,
        [strings[1]]: 0,
        [strings[2]]: 0,
      },
      {
        name: monthNames[5],
        [strings[0]]: 0,
        [strings[1]]: 0,
        [strings[2]]: 0,
      },
      {
        name: monthNames[6],
        [strings[0]]: 0,
        [strings[1]]: 0,
        [strings[2]]: 0,
      },
      {
        name: monthNames[7],
        [strings[0]]: 0,
        [strings[1]]: 0,
        [strings[2]]: 0,
      },
      {
        name: monthNames[8],
        [strings[0]]: 0,
        [strings[1]]: 0,
        [strings[2]]: 0,
      },
      {
        name: monthNames[9],
        [strings[0]]: 0,
        [strings[1]]: 0,
        [strings[2]]: 0,
      },
      {
        name: monthNames[10],
        [strings[0]]: 0,
        [strings[1]]: 0,
        [strings[2]]: 0,
      },
      {
        name: monthNames[11],
        [strings[0]]: 0,
        [strings[1]]: 0,
        [strings[2]]: 0,
      },
    ]

    statisticsData?.statistics?.forEach((stats) => {
      if (!stats.participated) {
        return
      }

      const date = new Date(stats.visit_date)
      const month = date.getMonth()

      data[month][strings[0]] +=
        stats.gender_d_registered_with_result +
        stats.gender_m_registered_with_result +
        stats.gender_f_registered_with_result

      data[month][strings[1]] +=
        stats.gender_d_registered_later_with_result +
        stats.gender_m_registered_later_with_result +
        stats.gender_f_registered_later_with_result

      data[month][strings[2]] +=
        stats.gender_d_registered_without_result +
        stats.gender_m_registered_without_result +
        stats.gender_f_registered_without_result +
        stats.gender_m_registered_deletedatday_withoutresult +
        stats.gender_f_registered_deletedatday_withoutresult +
        stats.gender_d_registered_deletedatday_withoutresult
    })

    return data
  }, [month, statisticsData?.statistics, strings, year])

  return (
    <div>
      <ResponsiveContainer
        width="100%"
        height="100%"
        minHeight="500px"
        minWidth="500px"
      >
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            domain={month != null ? [0, 96] : undefined}
            ticks={month != null ? [0, 32, 64, 96] : undefined}
          />
          <Tooltip />
          <Legend />
          <Bar dataKey={strings[0]} stackId="teilnehmer" fill="#fae760" />
          <Bar dataKey={strings[1]} stackId="teilnehmer" fill="#93918a" />
          <Bar dataKey={strings[2]} fill="#d5d4cf" />
        </BarChart>
      </ResponsiveContainer>
      <div className={Styles.description}>{getText("08.ST.0500.C.08")}</div>
    </div>
  )
}

Participants.propTypes = {
  statisticsData: PropTypes.object,
  month: PropTypes.number,
  year: PropTypes.number.isRequired,
}

export { Participants }

import React from "react"
import { Link } from "react-router-dom"

import Loader from "../../components/Loader"
import PageIntern from "../../components/PageIntern"
import { ReactComponent as Trash } from "../../images/BTN_Loeschen.svg"

export default class TeacherList extends PageIntern {
  state = Object.assign(this.state, {
    teachers: [],
    classes: [],
    selectedClasses: new Set(),
    overlayTeacherId: null,
  })

  /**
   * mount
   */
  init = async () => {
    this.setState({ overlayTeacherId: null, selectedClasses: new Set() })
    await this.loadTeachers()
    await this.loadClasses()
  }

  /**
   * close calendar if you clicked somewhere else
   * @param e
   */
  overlayListener = (e) => {
    if (!e.target.closest(".overlay--wrapper"))
      this.setState({ overlayTeacherId: null, selectedClasses: new Set() })
  }

  /**
   * get all teachers
   * @param force
   */
  async loadTeachers(force = false) {
    Loader.show()
    await this.props.store.loadTeachers(force)
    Loader.hide()
  }

  /**
   * get all classes
   * @param force
   */
  async loadClasses(force = false) {
    Loader.show()
    await this.props.store.loadClasses(force)
    Loader.hide()
  }

  /**
   * removes a teacher and selected classes
   * @param teacher
   * @param classes
   * @returns {Promise<void>}
   */
  removeTeacher = async (teacher, classes) => {
    Loader.show()

    await this.props.store._api.deleteUser(teacher.id)
    await Promise.all(classes.map((c) => this.props.store._api.deleteClass(c)))

    await this.loadTeachers(true)
    await this.loadClasses(true)

    this.setState({ overlayTeacherId: null, selectedClasses: new Set() })

    Loader.hide()
  }

  /**
   * is confirmed
   * @param teacher
   * @returns {string}
   */
  isComplete = (teacher) => {
    return `${teacher.confirmed > 0 ? "" : "in"}complete`
  }

  /**
   * get a teachers classes
   * @param teacher
   * @returns classes
   */
  getTeacherClasses = (teacher) => {
    return this.state.classes.filter((c) => c.teacher?.id === teacher.id)
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { store } = this.props
    const { teachers, selectedClasses, overlayTeacherId } = this.state

    return (
      <div className={"table table--teachers"} role={"table"}>
        <div className={"table__header grid-3"}>
          <div className={"teacher__name"}>
            {this.getText("08.ST.0800.TH.01")}
          </div>
          <div className={"teacher__email"}>
            {this.getText("08.ST.0800.TH.02")}
          </div>
          <div className={"teacher__phone"}>
            {this.getText("08.ST.0800.TH.03")}
          </div>
        </div>
        <div className={"table__content"}>
          {teachers.map((t) => (
            <div className={"table__row grid-3"} key={`teacher-${t.id}`}>
              {store._auth.isEmployee ? (
                <div className={"link__arrow"}>
                  <Link
                    to={store.routes.intern_teacher.path.to.replace(
                      ":userId",
                      t.id
                    )}
                    onClick={(_) =>
                      store.routes.intern_teacher.path.onClick(t.id)
                    }
                  >
                    {t.salutation} {t.forename} {t.lastname}
                  </Link>
                </div>
              ) : (
                <div>
                  {t.salutation} {t.forename} {t.lastname}
                </div>
              )}
              <div className={"teacher__email"}>{t.email}</div>
              <div className={"teacher__phone"}>{t.phone}</div>
              <div
                className={"table__row--extra"}
                style={{
                  zIndex: overlayTeacherId === t.id ? 1 : 0, // need to set z-index via js, because this div is absolutely positioned
                }}
              >
                <div className={this.isComplete(t)} />
                {store._auth.isEmployee && (
                  <div className={"delete overlay--wrapper"}>
                    <button
                      type={"button"}
                      className={"clean-button"}
                      onClick={(_) => this.setState({ overlayTeacherId: t.id })}
                    >
                      <Trash />
                    </button>
                    {overlayTeacherId === t.id && (
                      <div className={"overlay"}>
                        {this.getTeacherClasses(t).length > 0 ? (
                          <p>{this.getText("08.ST.0800.DF.C.02")}</p>
                        ) : (
                          <p>{this.getText("08.ST.0800.DF.C.01")}</p>
                        )}
                        {this.getTeacherClasses(t).map((c) => (
                          <div key={`class-${c.id}`} className={"flex-row"}>
                            <input
                              type={"checkbox"}
                              id={`class-${c.id}`}
                              name={`class-${c.id}`}
                              checked={selectedClasses.has(c.id)}
                              onChange={(e) => {
                                const newSelectedClasses = new Set(
                                  selectedClasses
                                )

                                if (e.target.checked)
                                  newSelectedClasses.add(c.id)
                                else newSelectedClasses.delete(c.id)

                                this.setState({
                                  selectedClasses: newSelectedClasses,
                                })
                              }}
                            />
                            <label htmlFor={`class-${c.id}`}>
                              {c.school.name} ({c.school.number}) {c.classname}
                            </label>
                          </div>
                        ))}
                        <button
                          type={"button"}
                          className={"form-button"}
                          onClick={() =>
                            this.removeTeacher(t, [...selectedClasses])
                          }
                        >
                          {this.getText("08.ST.0800.DF.BT")}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={"table__footer"}>
          {!store._auth.isReadonly && (
            <Link
              {...store.routes.intern_add_teacher.path}
              className={"form-button"}
            >
              {this.getText("08.ST.0800.BT")}
            </Link>
          )}
        </div>
        <div className={"table__legend"}>
          <span className={"incomplete"}>
            {this.getText("08.ST.0800.L.02")}
          </span>
          <span className={"complete"}>{this.getText("08.ST.0800.L.01")}</span>
        </div>
      </div>
    )
  }
}

import React from "react"

import Input from "./Input"

export default class Select extends Input {
  /**
   * constructor
   * @param props
   */
  constructor(props) {
    super(props)

    this.select = React.createRef()
  }

  /**
   * generate grouped options if necessary
   * @returns {[]}
   */
  get options() {
    const { options } = this.props
    const groupedOptions = []

    options &&
      options.forEach((option) => {
        if (option.group) {
          const group = groupedOptions.find(
            (group) => group.group === option.group
          )
          if (!group) {
            groupedOptions.push({
              group: option.group,
              options: [option],
            })
          } else group.options.push(option)
        } else groupedOptions.push(option)
      })
    return groupedOptions
  }

  /**
   * handle change
   * @param e
   */
  onChange = (e) => {
    const { onChange } = this.props
    const isChanged = this.state.value !== e.target.value

    isChanged &&
      this.setState(
        { value: e.target.value },
        (_) => onChange && onChange(this.state)
      )
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { name, label, errorMsg, error } = this.props

    return (
      <div className={"form-input"}>
        <select
          className={"form-input-element form-select-element"}
          name={name}
          value={this.state.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onClick={this.onChange}
          ref={this.select}
        >
          <option value={""} disabled={true} hidden={true}>
            &nbsp;
          </option>
          {this.options.map((option, i) =>
            option.group ? (
              <optgroup label={option.group} key={`optgroup-${i}`}>
                {option.options.map((option, j) => (
                  <option key={`option-${i}-${j}`} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </optgroup>
            ) : (
              <option key={`option-${i}`} value={option.value || option}>
                {option.title || option}
              </option>
            )
          )}
        </select>
        {this.state.value.length === 0 && (
          <label className={"form-label"}>{label}</label>
        )}
        {error && <div className={"form-input-error"}>{errorMsg || ""}</div>}
      </div>
    )
  }
}

import PropTypes from "prop-types"
import { useMemo } from "react"
import { Cell } from "recharts"

import {
  TextAndPieChartStatistics,
  TextLine,
} from "../TextAndPieChartStatistics"

const COLORS = {
  12: "#b1afaa",
  "05": "#eaeaea",
  "03": "#fae760",
  10: "#f8c75b",
  11: "#f5a655",
  "02": "#ff8e72",
  "01": "#f06449",
  "04": "#e0a6ce",
  "06": "#bfdbf7",
  "07": "#97c3dd",
  "09": "#4793a7",
  "08": "#1f7a8c",
}
const District = ({ statisticsData, getText }) => {
  const data = useMemo(() => {
    const data = {
      "01": { display: getText("08.ST.0500.L.17"), value: 0, key: "01" },
      "02": { display: getText("08.ST.0500.L.16"), value: 0, key: "02" },
      "03": { display: getText("08.ST.0500.L.13"), value: 0, key: "03" },
      "04": { display: getText("08.ST.0500.L.18"), value: 0, key: "04" },
      "05": { display: getText("08.ST.0500.L.12"), value: 0, key: "05" },
      "08": { display: getText("08.ST.0500.L.22"), value: 0, key: "08" },
      "06": { display: getText("08.ST.0500.L.19"), value: 0, key: "06" },
      "07": { display: getText("08.ST.0500.L.20"), value: 0, key: "07" },
      "09": { display: getText("08.ST.0500.L.21"), value: 0, key: "09" },
      11: { display: getText("08.ST.0500.L.15"), value: 0, key: "11" },
      10: { display: getText("08.ST.0500.L.14"), value: 0, key: "10" },
      12: { display: getText("08.ST.0500.L.11"), value: 0, key: "12" },
    }

    const counts = {}

    statisticsData?.statistics?.forEach((stats) => {
      if (!stats.participated) {
        return
      }

      const district = stats?.district

      if (Object.keys(data).includes(district)) {
        counts[district] = (counts[district] || 0) + 1
      }
    })

    Object.keys(counts).forEach((k) => (data[k].value = counts[k]))

    return data
  }, [getText, statisticsData?.statistics])

  // Object.values würde die keys selbst sortieren
  const chartData = useMemo(
    () =>
      [
        "08",
        "09",
        "07",
        "06",
        "04",
        "01",
        "02",
        "11",
        "10",
        "03",
        "05",
        "12",
      ].map((key) => data[key]),
    [data]
  )

  const Text = () => {
    return (
      <>
        <TextLine
          color={COLORS["12"]}
          text={data["12"].display}
          value={data["12"].value}
        />
        <TextLine
          color={COLORS["05"]}
          text={data["05"].display}
          value={data["05"].value}
        />
        <TextLine
          color={COLORS["03"]}
          text={data["03"].display}
          value={data["03"].value}
        />
        <TextLine
          color={COLORS["10"]}
          text={data["10"].display}
          value={data["10"].value}
        />
        <TextLine
          color={COLORS["11"]}
          text={data["11"].display}
          value={data["11"].value}
        />
        <TextLine
          color={COLORS["02"]}
          text={data["02"].display}
          value={data["02"].value}
        />
        <TextLine
          color={COLORS["01"]}
          text={data["01"].display}
          value={data["01"].value}
        />
        <TextLine
          color={COLORS["04"]}
          text={data["04"].display}
          value={data["04"].value}
        />
        <TextLine
          color={COLORS["06"]}
          text={data["06"].display}
          value={data["06"].value}
        />
        <TextLine
          color={COLORS["07"]}
          text={data["07"].display}
          value={data["07"].value}
        />
        <TextLine
          color={COLORS["09"]}
          text={data["09"].display}
          value={data["09"].value}
        />
        <TextLine
          color={COLORS["08"]}
          text={data["08"].display}
          value={data["08"].value}
        />
      </>
    )
  }

  return (
    <TextAndPieChartStatistics
      data={chartData}
      text={<Text />}
      description={getText("08.ST.0500.C.05")}
    >
      {chartData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[entry.key]} />
      ))}
    </TextAndPieChartStatistics>
  )
}

District.propTypes = {
  statisticsData: PropTypes.object,
}

export { District }

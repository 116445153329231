import PropTypes from "prop-types"
import { useMemo } from "react"
import { Cell } from "recharts"

import {
  TextAndPieChartStatistics,
  TextLine,
} from "../TextAndPieChartStatistics"

const COLORS = { usedSlot: "#fae760", unusedSlot: "#d5d4cf" }

const Timeslots = ({ statisticsData, getText }) => {
  const dailyStatistics = useMemo(() => {
    const data = {}
    statisticsData?.statistics.forEach((stats) => {
      const date = stats.visit_date
      if (data[date]) {
        data[date].push(stats)
      } else {
        data[date] = [stats]
      }
    })
    return data
  }, [statisticsData])

  const data = useMemo(() => {
    const data = {
      usedSlot: {
        display: getText("08.ST.0500.L.23"),
        value: 0,
        key: "usedSlot",
      },
      unusedSlot: {
        display: getText("08.ST.0500.L.24"),
        value: 0,
        key: "unusedSlot",
      },
    }

    const counts = {
      usedSlot: 0,
      unusedSlot: 0,
    }

    Object.values(dailyStatistics).forEach((day) => {
      counts.usedSlot += day.length
    })

    counts.unusedSlot = statisticsData?.slots - counts.usedSlot

    Object.keys(counts).forEach((k) => (data[k].value = counts[k]))

    return data
  }, [dailyStatistics, getText, statisticsData?.slots])

  const chartData = useMemo(() => Object.values(data), [data])

  const Text = () => {
    return (
      <>
        <TextLine
          color={COLORS.usedSlot}
          text={data.usedSlot.display}
          value={data.usedSlot.value}
        />
        <TextLine
          color={COLORS.unusedSlot}
          text={data.unusedSlot.display}
          value={data.unusedSlot.value}
        />
      </>
    )
  }

  return (
    <TextAndPieChartStatistics
      data={chartData}
      text={<Text />}
      description={getText("08.ST.0500.C.08")}
      displayPercentage
    >
      {chartData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[entry.key]} />
      ))}
    </TextAndPieChartStatistics>
  )
}

Timeslots.propTypes = {
  statisticsData: PropTypes.object,
}

export { Timeslots }

export default class Auth {
  static _JWT_TOKEN_KEY = "FSB_TC_JWT"
  static _EMPLOYEE_ROLE_ID = 6
  static _READONLY_ROLE_ID = 8
  static _ADMIN_ROLE_ID = 9
  _user = null
  _store = null

  /**
   * constructor
   * @param store
   */
  constructor(store) {
    this._store = store
  }

  /**
   * set an auth state
   * @param user
   * @param jwt
   */
  set({ user, jwt }) {
    this._user = user
    Auth.setToken(jwt)
  }

  /**
   * clear auth state
   */
  clear() {
    this._user = null
    this._active = false
    Auth.removeToken()
  }

  /**
   * check, if required data is missing
   */
  get isIncomplete() {
    return (
      this._user &&
      (String(this._user.email).length === 0 ||
        this._user.email === null ||
        String(this._user.lastname).length === 0 ||
        this._user.lastname === null ||
        String(this._user.forename).length === 0 ||
        this._user.forename === null ||
        String(this._user.phone).length === 0 ||
        this._user.phone === null ||
        String(this._user.salutation).length === 0 ||
        this._user.salutation === null ||
        this._user.confirmed === false)
    )
  }

  /**
   * auth handling
   * @returns {Promise<void>}
   */
  async authenticate() {
    const result = await this._store._api.getUser()

    if (result.data && !result.error) {
      this._user = result.data
    } else {
      this.clear()
    }
    return result
  }

  /**
   * get user
   */
  get user() {
    return this._user
  }

  /**
   * Check if user is employee
   * @returns {boolean}
   */
  get isEmployee() {
    return (
      this.user &&
      (this.user.role.id === Auth._EMPLOYEE_ROLE_ID ||
        this.user.role.id === Auth._ADMIN_ROLE_ID)
    )
  }

  /**
   * Check if user has read only access
   * @returns {boolean}
   */
  get isReadonly() {
    return this.user && this.user.role.id === Auth._READONLY_ROLE_ID
  }

  /**
   * Check if user is admin
   * @returns {boolean}
   */
  get isAdmin() {
    return this.user && this.user.role.id === Auth._ADMIN_ROLE_ID
  }

  // ------------------------------------------------------------------------------------------

  /**
   * set jwt
   * @param jwt
   */
  static setToken(jwt) {
    window.sessionStorage.setItem(Auth._JWT_TOKEN_KEY, jwt)
  }

  /**
   * get jwt
   * @returns {string}
   */
  static getToken() {
    return window.sessionStorage.getItem(Auth._JWT_TOKEN_KEY)
  }

  /**
   * remove jwt
   */
  static removeToken() {
    return window.sessionStorage.removeItem(Auth._JWT_TOKEN_KEY)
  }
}

import "../../styles/CalendarAdmin.scss"

import React from "react"
import { Link, Redirect } from "react-router-dom"

import Form from "../../components/Form"
import Input from "../../components/Input"
import Loader from "../../components/Loader"
import PageIntern from "../../components/PageIntern"
import { ReactComponent as Edit } from "../../images/BTN_Edit.svg"
import { ReactComponent as Trash } from "../../images/BTN_Loeschen.svg"
import { ReactComponent as Close } from "../../images/IMG_Close_Icon.svg"

export default class CalendarAdmin extends PageIntern {
  state = Object.assign(this.state, {
    weekends: null,
    notBookable: null,
    showForm: null,
    title: {
      value: "",
      name: "title",
      type: "text",
      errorMsg: "Ungültige Bezeichnung",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ title: Object.assign(this.state.title, value) }),
    },
    startDate: {
      value: "",
      name: "startDate",
      type: "date",
      errorMsg: "Ungültiges Startdatum",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({
          startDate: Object.assign(this.state.startDate, value),
        }),
    },
    endDate: {
      value: "",
      name: "endDate",
      type: "date",
      errorMsg: "Ungültiges Enddatum",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ endDate: Object.assign(this.state.endDate, value) }),
    },
    startTime: {
      value: "",
      name: "startTime",
      type: "time",
      errorMsg: "Ungültige Startzeit",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({
          startTime: Object.assign(this.state.startTime, value),
        }),
    },
    endTime: {
      value: "",
      name: "endTime",
      type: "time",
      errorMsg: "Ungültige Endzeit",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ endTime: Object.assign(this.state.endTime, value) }),
    },
  })

  /**
   * constructor
   */
  constructor(props) {
    super(props)

    this.form = new Form({
      className: "form-full",
      fields: {
        title: this.state.title,
        startDate: this.state.startDate,
        startTime: this.state.startTime,
        endDate: this.state.endDate,
        endTime: this.state.endTime,
      },
    })
  }

  /**
   * overlay listener
   * @param e
   * @private
   */
  overlayListener = (e) => {
    if (!e.target.closest(".table__item--form"))
      this.setState({
        showForm: null,
      })
  }

  /**
   * init
   */
  init = () => {
    this.setState(
      {
        showForm: null,
      },
      (_) => {
        this.props.store._api
          .getWeekends()
          .then(
            (response) =>
              !response.error && this.setState({ weekends: response.data })
          )
        this.props.store._api
          .getNotBookable()
          .then(
            (response) =>
              !response.error && this.setState({ notBookable: response.data })
          )
      }
    )
  }

  /**
   * open form handler
   * @param data
   * @param prefix
   */
  openForm(data, prefix) {
    this.setState({
      showForm: `${prefix}-${data.id}`,
      title: Object.assign(this.state.title, { value: data.title || "" }),
      startDate: Object.assign(this.state.startDate, {
        value: data.startdate || "",
      }),
      endDate: Object.assign(this.state.endDate, { value: data.enddate || "" }),
      startTime: Object.assign(this.state.startTime, {
        value: data.from ? data.from.substring(0, 5) : "",
      }),
      endTime: Object.assign(this.state.endTime, {
        value: data.to ? data.to.substring(0, 5) : "",
      }),
    })
  }

  /**
   * update
   * @param data
   * @param type
   */
  update = (data, type) => {
    const isWeekend = type === "weekend"
    const { startDate, endDate, startTime, endTime, title } = this.state
    const Action = isWeekend
      ? this.props.store._api.updateWeekend
      : this.props.store._api.updateNotBookable
    Loader.show()
    Action({
      enddate: endDate.value,
      from: `${startTime.value}:00.000`,
      id: data.id,
      startdate: startDate.value,
      title: title.value,
      to: `${endTime.value}:00.000`,
      type,
    })
      .then(this.init)
      .then((_) => Loader.hide())
  }

  /**
   * add
   * @param type
   */
  add = (type) => {
    const isWeekend = type === "weekend"
    const { startDate, endDate, startTime, endTime, title } = this.state
    const Action = isWeekend
      ? this.props.store._api.addWeekend
      : this.props.store._api.addNotBookable
    Loader.show()
    Action({
      enddate: endDate.value,
      from: `${startTime.value}:00.000`,
      startdate: startDate.value,
      title: title.value,
      to: `${endTime.value}:00.000`,
      type,
    })
      .then(this.init)
      .then((_) => Loader.hide())
  }

  /**
   * remove
   * @param data
   * @param type
   */
  remove = (data, type) => {
    const isWeekend = type === "weekend"
    const Action = isWeekend
      ? this.props.store._api.deleteWeekend
      : this.props.store._api.deleteNotBookable
    Loader.show()
    Action(data)
      .then(this.init)
      .then((_) => Loader.hide())
  }

  /**
   * column render helper
   * @param items
   * @param type
   * @param update
   * @param add
   * @param remove
   * @returns {JSX.Element}
   */
  renderTableCol(items, type, update, add, remove) {
    const { showForm, title, startTime, startDate, endTime, endDate } =
      this.state

    return (
      <div className={`table__col--${type}`}>
        {items &&
          items.map((item) => (
            <div className={"table__item"} key={`${type}-${item.id}`}>
              {showForm !== `${type}-${item.id}` && (
                <div className={"table__item--entry"}>
                  <button
                    type={"button"}
                    className={"clean-button"}
                    onClick={(_) => remove(item, type)}
                  >
                    <Trash />
                  </button>
                  <button
                    type={"button"}
                    className={"clean-button"}
                    onClick={(_) => this.openForm(item, type)}
                  >
                    <Edit />
                  </button>
                  <p>
                    {item.title || "Wochenende"}
                    <br />
                    {this.formatDate(item.startdate)}
                    {item.startdate === item.enddate
                      ? ""
                      : ` - ${this.formatDate(item.enddate)}`}
                  </p>
                </div>
              )}
              {showForm === `${type}-${item.id}` && (
                <Form {...this.form} className={"table__item--form"}>
                  <Input
                    {...title}
                    label={this.getText("08.ST.0331.IF.EF.01")}
                  />
                  <Input {...startDate} />
                  <Input {...endDate} />
                  <Input {...startTime} />
                  <Input {...endTime} />
                  <button
                    type={"button"}
                    className={"form-button"}
                    disabled={!this.form.isValid}
                    onClick={(_) => update(item, type)}
                  >
                    {this.getText("08.ST.0331.IF.BT")}
                  </button>
                </Form>
              )}
            </div>
          ))}
        <div className={"table__item"}>
          {showForm === `${type}-0` && (
            <Form {...this.form} className={"table__item--form"}>
              <Input {...title} label={this.getText("08.ST.0331.IF.EF.01")} />
              <Input {...startDate} />
              <Input {...endDate} />
              <Input {...startTime} />
              <Input {...endTime} />
              <button
                type={"button"}
                className={"form-button"}
                disabled={!this.form.isValid}
                onClick={(_) => add(type)}
              >
                {this.getText("08.ST.0330.IF.BT.L.01")}
              </button>
            </Form>
          )}
        </div>
        {showForm !== `${type}-0` && (
          <button
            type={"button"}
            className={"form-button"}
            onClick={(_) => this.openForm({ id: 0 }, type)}
          >
            {this.getText("08.ST.0330.IF.BT.L.01")}
          </button>
        )}
      </div>
    )
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  view() {
    const { store } = this.props
    const { weekends, notBookable } = this.state

    return store._auth.isEmployee ? (
      <section className={"calendar-admin"}>
        <Link {...store.routes.intern_start.path} className={"close"}>
          <Close />
        </Link>

        <h1>{this.getText("08.ST.0330.IF.HL")}</h1>

        <div className={"table table--students"} role={"table"}>
          <div className={"table__header grid-3"}>
            <div>
              {this.getText("08.ST.0330.IF.SL.01")}
              <small>{this.getText("08.ST.0330.IF.C")}</small>
            </div>
            <div>{this.getText("08.ST.0330.IF.SL.02")}</div>
            <div>{this.getText("08.ST.0330.IF.SL.03")}</div>
          </div>
          <div className={"table__row grid-3"}>
            {this.renderTableCol(
              weekends,
              "weekend",
              this.update,
              this.add,
              this.remove
            )}
            {this.renderTableCol(
              notBookable &&
                notBookable.filter((item) => item.type === "holidays"),
              "holidays",
              this.update,
              this.add,
              this.remove
            )}
            {this.renderTableCol(
              notBookable &&
                notBookable.filter((item) => item.type === "holiday"),
              "holiday",
              this.update,
              this.add,
              this.remove
            )}
          </div>
        </div>
      </section>
    ) : (
      <Redirect to={store.routes.intern_start.path.to} />
    )
  }
}

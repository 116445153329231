import "../../../styles/Statistics.scss"

import React from "react"
import { CSVLink } from "react-csv"
import { isSafari } from "react-csv/src/core"
import { Link } from "react-router-dom"

import Form from "../../../components/Form"
import Input from "../../../components/Input"
import Loader from "../../../components/Loader"
import PageIntern from "../../../components/PageIntern"
import Select from "../../../components/Select"
import { ReactComponent as Arrow } from "../../../images/arrow_next.svg"
import { monthNames } from "./lib"
import { OldWorkload } from "./Variants/_oldWorkload"
import { Capacity } from "./Variants/Capacity"
import { Classes } from "./Variants/Classes"
import { District } from "./Variants/District"
import { GenderStatistics } from "./Variants/GenderStatistics"
import { Participants } from "./Variants/Participants"
import { SchoolType } from "./Variants/SchoolType"
import { SchoolYear } from "./Variants/SchoolYear"
import { Timeslots } from "./Variants/Timeslots"

export default class Statistics extends PageIntern {
  today = new Date()
  type = null
  month = null
  year = null
  prevMode = "month"
  csvLink = React.createRef()

  state = Object.assign(this.state, {
    month: this.today.getMonth(),
    year: this.today.getFullYear(),
    full_month: `${
      monthNames[this.today.getMonth()]
    } ${this.today.getFullYear()}`,
    types: {
      value: "participants",
      name: "types",
      errorMsg: "Ungültige Auswahl",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState(
          { types: Object.assign(this.state.types, value) },
          this.init
        ),
    },
    views: {
      value: "month",
      name: "views",
      errorMsg: "Ungültige Auswahl",
      error: false,
      required: true,
      onChange: (value) => {
        const typesValue = this.state.types.value

        this.setState(
          {
            views: Object.assign(this.state.views, value),
            types: {
              ...this.state.types,
              value: typesValue,
            },
          },
          () => this.handleMonth(0)
        )
      },
    },
    download_type: {
      value: "Gruppenstärke",
      name: "download_type",
      type: "select",
      errorMsg: "Ungültiges Datum",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({
          download_type: Object.assign(this.state.download_type, value),
        }),
    },
    download_from: {
      value: "",
      name: "download_from",
      type: "date",
      errorMsg: "Ungültiges Datum",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({
          download_from: Object.assign(this.state.download_from, value),
        }),
    },
    download_to: {
      value: "",
      name: "download_to",
      type: "date",
      errorMsg: "Ungültiges Datum",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({
          download_to: Object.assign(this.state.download_to, value),
        }),
    },
    statisticData: null,
    uploadOverlay: false,
    csvData: [],
  })

  constructor(props) {
    super(props)

    this.downloadForm = new Form({
      className: "form-download",
      onSubmit: this.submitDownload,
      fields: {
        download_type: this.state.download_type,
        download_from: this.state.download_from,
        download_to: this.state.download_to,
      },
    })
  }

  init = () => {
    const { types, month, year, views } = this.state
    let action

    this.setState({
      uploadOverlay: false,
    })

    if (
      !(
        types.value !== this.type ||
        month !== this.month ||
        year !== this.year ||
        views.value !== this.prevMode
      )
    ) {
      return
    }

    this.type = types.value
    this.month = month
    this.year = year
    this.prevMode = views.value

    const api = this.props.store._api

    if (types.value === "workload") {
      action = api.getWorkloads
    } else {
      action =
        views.value === "year" ? api.getYearStatistics : api.getMonthStatistics
    }

    if (action) {
      Loader.show()

      action(month + 1, year).then((response) => {
        if (!response.error) {
          this.setState({ statisticData: response.data }, () => Loader.hide())
        }
      })
    }
  }

  /**
   * overlay listener
   * @param e
   * @private
   */
  overlayListener = (e) => {
    if (!e.target.closest(".overlay"))
      this.setState({
        uploadOverlay: null,
      })
  }

  /**
   * handle new password submit
   */
  submitDownload = (e) => {
    e.preventDefault()

    if (isSafari()) {
      const help_from = this.state.download_from.value.split(".")
      const help_to = this.state.download_to.value.split(".")

      this.state.download_from.value = `${help_from[2]}-${help_from[1]}-${help_from[0]}`
      this.state.download_to.value = `${help_to[2]}-${help_to[1]}-${help_to[0]}`
    }

    Loader.show()
    this.props.store._api
      .downloadStatistic({
        download_type: this.state.download_type.value,
        download_from: this.state.download_from.value,
        download_to: this.state.download_to.value,
      })
      .then((result) => {
        !result.error &&
          this.setState(
            {
              uploadOverlay: false,
              csvData: result.data,
            },
            () => this.csvLink.current.link.click()
          )
        Loader.hide()
      })
  }

  /**
   * show upload overlay
   */
  onUploadButtonClick = () => {
    this.setState({ uploadOverlay: false })
  }

  get newChartTypes() {
    return [
      {
        value: "genders",
        title: this.getText("08.ST.0500.DM.01-04"),
      },
      {
        value: "years",
        title: this.getText("08.ST.0500.DM.01-05"),
      },
      {
        value: "schoolType",
        title: this.getText("08.ST.0500.DM.01-06"),
      },
      {
        value: "district",
        title: this.getText("08.ST.0500.DM.01-07"),
      },
      {
        value: "timeslots",
        title: this.getText("08.ST.0500.DM.01-08"),
      },
      {
        value: "classes",
        title: this.getText("08.ST.0500.DM.01-09"),
      },
      {
        value: "participants",
        title: this.getText("08.ST.0500.DM.01-10"),
      },
      {
        value: "capacity",
        title: this.getText("08.ST.0500.DM.01-02"),
      },
    ]
  }

  NewCharts = ({ type }) => {
    const statisticsData = this.state.statisticData

    if (statisticsData && !Array.isArray(statisticsData?.statistics)) {
      statisticsData.statistics = []
    }

    switch (type) {
      case "classes":
        return (
          <Classes
            statisticsData={statisticsData}
            getText={(key) => this.getText(key)}
          />
        )
      case "district":
        return (
          <District
            statisticsData={statisticsData}
            getText={(key) => this.getText(key)}
          />
        )
      case "genders":
        return (
          <GenderStatistics
            statisticsData={statisticsData}
            getText={(key) => this.getText(key)}
          />
        )
      case "participants":
        return (
          <Participants
            statisticsData={statisticsData}
            month={
              this.state.views.value === "month" ? this.state.month : undefined
            }
            year={this.state.year}
            getText={(key) => this.getText(key)}
          />
        )
      case "schoolType":
        return (
          <SchoolType
            statisticsData={statisticsData}
            getText={(key) => this.getText(key)}
          />
        )
      case "timeslots":
        return (
          <Timeslots
            statisticsData={statisticsData}
            getText={(key) => this.getText(key)}
          />
        )
      case "years":
        return (
          <SchoolYear
            statisticsData={statisticsData}
            getText={(key) => this.getText(key)}
          />
        )
      case "capacity":
        return (
          <Capacity
            statisticsData={statisticsData}
            getText={(key) => this.getText(key)}
            year={this.state.year}
            month={
              this.state.views.value === "month" ? this.state.month : undefined
            }
          />
        )
      default:
        return <h1>TO BE ADDED</h1>
    }
  }

  /**
   * get all types
   * @returns {[]}
   */
  get types() {
    return this.newChartTypes
  }

  /**
   * get all view
   * @returns {[]}
   */
  get views() {
    const options = []

    options.push({ value: "month", title: this.getText("08.ST.0500.DM.02-01") })
    options.push({ value: "year", title: this.getText("08.ST.0500.DM.02-02") })

    return options
  }

  /**
   * increase / decrease month
   * @param step
   */
  handleMonth = (step = 0) => {
    const { month, year, views } = this.state
    const date = new Date(year, month)

    switch (views.value) {
      case "year":
        date?.setFullYear(date.getFullYear() + step)
        this.setState(
          {
            month: date.getMonth(),
            year: date.getFullYear(),
            full_month: date.getFullYear(),
          },
          this.init
        )
        break
      case "month":
      default:
        date?.setMonth(date.getMonth() + step)
        this.setState(
          {
            month: date.getMonth(),
            year: date.getFullYear(),
            full_month: `${monthNames[date.getMonth()]} ${date.getFullYear()}`,
          },
          this.init
        )
        break
    }
  }

  /**
   * get all download types
   * @returns {[]}
   */
  get download_types() {
    const options = []

    options.push(this.getText("08.ST.0520.DF.DM-01"))
    options.push(this.getText("08.ST.0520.DF.DM-02"))
    options.push(this.getText("08.ST.0520.DF.DM-03"))
    options.push(this.getText("08.ST.0520.DF.DM-04"))

    return options
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  view() {
    const {
      types,
      full_month,
      views,
      statisticData,
      uploadOverlay,
      download_type,
      download_from,
      download_to,
      csvData,
      year,
      month,
    } = this.state
    const date = new Date()
    let current_month = date.getMonth()
    current_month++
    const current_day = date.getDate()
    const current_year = date.getFullYear()
    const current_date = `${current_year}-${current_month}-${current_day}`

    statisticData?.[views.value]?.sort((a, b) => a.day - b.day)
    statisticData?.[views.value]?.sort((a, b) => a.month - b.month)

    // Average
    let average = 0
    let current_average = 0
    let index = 0
    let current_index = 0

    statisticData?.[views.value]?.forEach((data) => {
      average += +(isNaN(data.value) ? 0 : data.value)

      if (index < current_month) {
        current_average += +(isNaN(data.value) ? 0 : data.value)
        current_index++
      }
      index++
    })

    average /= index
    current_average /= current_index
    let average_value = `Ø ${average.toFixed(2)}`
    let current_average_value = `Ø ${current_average.toFixed(2)}`

    if (types.value === "workload") {
      average_value += " %"
      current_average_value += " %"
    } else {
      average *= 3.125
      current_average *= 3.125
    }

    return (
      <section>
        <CSVLink
          filename="data.csv"
          className={"hidden"}
          data={csvData}
          target="_self"
          separator={";"}
          ref={this.csvLink}
        />

        <div className={"intern_statistics--header"}>
          <div className={"link__arrow"}>
            <Link
              to={"#"}
              onClick={() => this.setState({ uploadOverlay: true })}
            >
              Daten herunterladen
            </Link>
          </div>

          {uploadOverlay && (
            <div className={"overlay"}>
              <Form {...this.downloadForm.props}>
                <p>{this.getText("08.ST.0520.DF.C")}</p>

                <div className={"form-row"}>
                  <Select
                    {...download_type}
                    options={this.download_types}
                    value={download_type.value || this.download_types[0]}
                    label={this.getText("08.ST.0520.DF.DM-01")}
                  />
                </div>

                <div className={"form-row"}>
                  <Input {...download_from} />
                  <Input {...download_to} />
                </div>

                <button
                  className={"form-button"}
                  disabled={!this.downloadForm.isValid}
                  type={"submit"}
                >
                  {this.getText("08.ST.0520.DF.BT")}
                </button>
              </Form>
            </div>
          )}
        </div>

        <div className={"intern_statistics--choose grid-3"}>
          <div className={"intern_statistics--choose_left"}>
            <div className={"form"}>
              <Select
                {...types}
                options={this.types}
                value={types.value || this.types[0]}
              />
            </div>
          </div>

          <div className={"intern_statistics--choose_middle"}>
            <button
              className={"intern_statistics--month--prev"}
              type={"button"}
              onClick={() => this.handleMonth(-1)}
            >
              <Arrow />
            </button>

            <h2>{full_month}</h2>

            <button
              className={"intern_statistics--month--next"}
              type={"button"}
              onClick={() => this.handleMonth(+1)}
            >
              <Arrow />
            </button>
          </div>

          <div className={"intern_statistics--choose_right"}>
            <div className={"form"}>
              <Select
                {...views}
                options={this.views}
                value={views.value || this.views[0]}
              />
            </div>
          </div>
        </div>
        {this.newChartTypes.map((t) => t.value).includes(types.value) ? (
          <div>
            <this.NewCharts type={this.state.types.value} />
          </div>
        ) : (
          <div className={"intern_statistics--charts"}>
            {types.value === "workload" && views.value === "month" && (
              <OldWorkload
                average_value={average_value}
                average={average}
                mode={views.value}
                statisticData={statisticData}
                current_date={current_date}
                year={year}
                month={month}
              />
            )}

            {types.value === "workload" && views.value === "year" && (
              <OldWorkload
                average_value={average_value}
                average={average}
                mode={views.value}
                statisticData={statisticData}
                current_date={current_date}
                year={year}
                month={month}
                current_average={current_average}
                current_average_value={current_average_value}
              />
            )}
          </div>
        )}
      </section>
    )
  }
}

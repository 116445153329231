import "../../styles/CalendarList.scss"

import React from "react"
import { Link } from "react-router-dom"

import CalendarSheet from "../../components/CalendarSheet"
import Loader from "../../components/Loader"
import PageIntern from "../../components/PageIntern"
import Select from "../../components/Select"
import { ReactComponent as Arrow } from "../../images/arrow_next.svg"

export default class CalendarList extends PageIntern {
  today = new Date()

  state = Object.assign(this.state, {
    month: this.today.getMonth(),
    year: this.today.getFullYear(),
    day: null,
    slot: null,
    classes: null,
    classToChange: null,
    confirmOverlay: null,
    removeOverlay: null,
  })

  /**
   * mount
   */
  init = () => {
    this.loadClasses()
  }

  /**
   * overlay listener
   * @param e
   * @private
   */
  overlayListener = (e) => {
    if (!e.target.closest(".overlay"))
      this.setState({
        confirmOverlay: null,
        removeOverlay: null,
      })
  }

  /**
   * get all classes
   * @param force
   */
  loadClasses(force = false) {
    Loader.show()
    this.props.store
      .loadClasses(force)
      .then((_) => this.sortByName)
      .then((_) => Loader.hide())
  }

  /**
   * sort by school name
   */
  sortByName = () => {
    const sortClassesByName = this.state.classes.sort((a, b) =>
      a.school.name.toLowerCase().localeCompare(b.school.name.toLowerCase())
    )
    this.props.store.sortBy(sortClassesByName, "name", this.state)
  }

  /**
   * is fully complete filled
   * @param schoolClass
   * @returns {string}
   */
  isComplete = (schoolClass) => {
    return `${schoolClass.bookings.length > 0 ? "" : "in"}complete`
  }

  /**
   * format booking date
   * @param booking
   * @returns {string}
   */
  bookingDate = (booking) => {
    const { date, slot } = booking
    const [year, month, day] = date?.split("-")

    return `${day}.${month}.${year.substring(2)} - ${slot?.time.substring(
      0,
      5
    )} Uhr`
  }

  /**
   * format overview headline
   */
  get slotHeadline() {
    const { day } = this.state

    const date = new Date(day.year, day.month - 1, day.day)

    return date.toLocaleDateString("de-DE", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  }

  /**
   * get class data by slot
   * @param slot
   * @returns {string|JSX.Element|*}
   */
  getClassNameBySlot(slot) {
    const { classes } = this.state
    const classData = slot?.booked
      ? classes.find((c) => c.id === slot.booked)
      : null

    return classData ? (
      <span>
        {classData.school.name}
        <br />
        {classData.classname}
      </span>
    ) : slot.bookable ? null : (
      this.getText("08.WT.0600.C.B")
    )
  }

  /**
   * handle booking submit
   * @param day
   * @param slot
   */
  submitBooking = (day, slot) => {
    const schoolClass =
      this.state.classes.find(
        (schoolClass) => schoolClass.id === slot.ready2book
      ) || this.state.classToChange
    schoolClass &&
      this.props.store
        .persistBooking({ date: day, slot, schoolClass })
        .then((result) => !result.error && result.data)
        .then((booking) => {
          if (!booking) {
            return
          }

          slot.booked = booking.classes.id
          slot.bookable = false
          delete slot.ready2book
          this.setState({
            day,
            slot,
            confirmOverlay: null,
            removeOverlay: null,
            classToChange: null,
          })

          this.loadClasses(true)
          return
        })
  }

  /**
   * handle remove booking
   * @param day
   * @param slot
   */
  removeBooking = (day, slot) => {
    const schoolClass = this.state.classes.find(
      (schoolClass) => schoolClass.id === slot.booked
    )
    schoolClass &&
      this.props.store
        .deleteBooking(schoolClass.bookings[0].id)
        .then((result) => !result.error && result.data)
        .then((booking) => {
          if (!booking) {
            return
          }

          slot.booked = null
          slot.bookable = true
          delete slot.ready2book
          this.setState({
            day: null,
            slot: null,
            confirmOverlay: false,
            removeOverlay: false,
            classToChange: null,
          })

          this.loadClasses(true)
          return
        })
  }

  /**
   * change booking
   * @param day
   * @param slot
   */
  changeBooking = (day, slot) => {
    const schoolClass = this.state.classes.find(
      (schoolClass) => schoolClass.id === slot.booked
    )
    this.setState({
      classToChange: schoolClass,
      day,
      slot,
    })
  }

  /**
   * increase / decrease month
   * @param step
   */
  handleMonths = (step) => {
    const { month, year } = this.state
    const date = new Date(year, month)
    if (date) {
      date.setMonth(date.getMonth() + step)
      this.setState({ month: date.getMonth(), year: date.getFullYear() })
    }
  }

  /**
   * check dates in past
   * @param day
   * @returns {boolean}
   */
  isInPast = (day) => {
    const date = new Date(day.year, day.month - 1, day.day, 23, 59, 59)
    const today = new Date()

    return date.getTime() < today.getTime()
  }

  /**
   * render helper
   * @param month
   * @returns {JSX.Element|null}
   */
  renderCalendarSlots(month) {
    const { classToChange, day } = this.state
    return classToChange && day && day.month === month ? (
      <div className={"calendar__slots grid-3"}>
        {day.slots.map((slot) => {
          const classNames = ["calendar__slot"]
          slot.bookable && classNames.push("calendar__slot--bookable")
          slot.booked && classNames.push("calendar__slot--booked")
          slot === this.state.slot &&
            classNames.push("calendar__slot--selected")

          return (
            <div
              className={classNames.join(" ")}
              key={`slot-${slot.id}`}
              onClick={(_) =>
                this.setState({ slot: slot.bookable ? slot : this.state.slot })
              }
            >
              {slot.time.substring(0, 5)}
            </div>
          )
        })}
      </div>
    ) : null
  }

  /**
   * get all classes for current user
   * @returns {[]}
   */
  get classesForUser() {
    const options = []

    const { store } = this.props
    const { classes } = this.state
    const userClassIds =
      classes &&
      classes
        .filter(
          (schoolClass) => schoolClass.teacher?.id === store._auth.user.id
        )
        .map((results) => results.id)

    if (store._auth.isEmployee) {
      classes.forEach((schoolClass) => {
        options.push({
          value: schoolClass.id,
          title: schoolClass.classname,
          group: schoolClass.school.name,
        })
      })
    } else {
      classes.forEach((schoolClass) => {
        if (userClassIds.includes(schoolClass.id)) {
          options.push({
            value: schoolClass.id,
            title: schoolClass.classname,
            group: schoolClass.school.name,
          })
        }
      })
    }

    return options
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { store } = this.props
    const {
      month,
      year,
      classes,
      day,
      slot,
      classToChange,
      confirmOverlay,
      removeOverlay,
    } = this.state

    const userClassIds =
      classes &&
      classes
        .filter(
          (schoolClass) => schoolClass.teacher?.id === store._auth.user.id
        )
        .map((results) => results.id)

    const date = new Date(year, month)
    const dateNext = new Date(year, month)
    dateNext.setMonth(date.getMonth() + 1)
    const dateAfterNext = new Date(year, month)
    dateAfterNext.setMonth(date.getMonth() + 2)

    return (
      classes && (
        <div className={"calendar-list"}>
          <div className={"calendar-list__grid"}>
            <button
              className={"calendar-list__grid--prev"}
              type={"button"}
              onClick={(_) => this.handleMonths(-3)}
            >
              <Arrow />
            </button>
            <button
              className={"calendar-list__grid--next"}
              type={"button"}
              onClick={(_) => this.handleMonths(+3)}
            >
              <Arrow />
            </button>
            <div>
              <CalendarSheet
                store={store}
                month={date.getMonth() + 1}
                year={date.getFullYear()}
                date={day}
                classes={classes}
                onChange={({ day }) => this.setState({ day, slot: null })}
              />

              {classToChange && this.renderCalendarSlots(date.getMonth() + 1)}
            </div>
            <div>
              <CalendarSheet
                store={store}
                month={dateNext.getMonth() + 1}
                year={dateNext.getFullYear()}
                date={day}
                classes={classes}
                onChange={({ day }) => this.setState({ day, slot: null })}
              />

              {classToChange &&
                this.renderCalendarSlots(dateNext.getMonth() + 1)}
            </div>
            <div>
              <CalendarSheet
                store={store}
                month={dateAfterNext.getMonth() + 1}
                year={dateAfterNext.getFullYear()}
                date={day}
                classes={classes}
                onChange={({ day }) => this.setState({ day, slot: null })}
              />

              {classToChange &&
                this.renderCalendarSlots(dateAfterNext.getMonth() + 1)}
            </div>
          </div>

          <div className={"calendar-list__legend"}>
            <span>{this.getText("08.WT.0600.L.01")}</span>
            <span>{this.getText("08.WT.0600.L.02")}</span>
            <span>{this.getText("08.WT.0600.L.03")}</span>
            {store._auth.isEmployee && (
              <div className={"link__arrow"}>
                <Link {...store.routes.intern_calendar_admin.path}>
                  {this.getText("08.ST.0300.BT.L")}
                </Link>
              </div>
            )}
          </div>
          {day && !classToChange && (
            <div className={"calendar-list__slots"}>
              <p>{this.slotHeadline}</p>
              <div className={"grid-3"}>
                {day.slots.map((slot) => {
                  const classNames = ["calendar-list__slot"]
                  slot.bookable &&
                    classNames.push("calendar-list__slot--bookable")
                  slot.booked && classNames.push("calendar-list__slot--booked")

                  return (
                    <div
                      className={classNames.join(" ")}
                      key={`slot-${slot.id}`}
                    >
                      <p>
                        {this.getText("08.WT.0600.T").replace(
                          "[timeslot]",
                          slot.time.substring(0, 5)
                        )}
                      </p>
                      <div>{this.getClassNameBySlot(slot)}</div>
                      {!store._auth.isEmployee &&
                        !store._auth.isReadonly &&
                        slot.booked && (
                          <div className={"calendar-list__buttons"}>
                            {userClassIds.includes(slot.booked) ? (
                              <>
                                <button
                                  type={"button"}
                                  className={"form-button"}
                                  disabled={!day || !slot || this.isInPast(day)}
                                  onClick={(_) => this.changeBooking(day, slot)}
                                >
                                  {this.getText("08.WT.0600.BT.01")}
                                </button>
                                <button
                                  type={"button"}
                                  className={"form-button"}
                                  disabled={
                                    !day ||
                                    !slot ||
                                    this.isInPast(day) ||
                                    !userClassIds.includes(slot.booked)
                                  }
                                  onClick={(_) =>
                                    this.setState({ removeOverlay: slot.id })
                                  }
                                >
                                  {this.getText("08.WT.0600.BT.02")}
                                </button>
                              </>
                            ) : null}
                            {removeOverlay === slot.id && (
                              <div className={"overlay"}>
                                <p>{this.getText("08.WT.0640.DF.C")}</p>
                                <button
                                  type={"button"}
                                  className={"form-button"}
                                  disabled={
                                    !day ||
                                    !slot ||
                                    this.isInPast(day) ||
                                    !userClassIds.includes(slot.booked)
                                  }
                                  onClick={(_) => this.removeBooking(day, slot)}
                                >
                                  {this.getText("08.WT.0640.DF.BT")}
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      {!store._auth.isEmployee &&
                        !store._auth.isReadonly &&
                        slot.bookable && (
                          <div className={"calendar-list__buttons form"}>
                            <Select
                              value={""}
                              label={this.getText("08.ST.0610.DT")}
                              options={this.classesForUser}
                              onChange={({ value }) => {
                                slot.ready2book = parseInt(value)
                                this.setState({ day })
                              }}
                            />
                            <button
                              type={"button"}
                              className={"form-button"}
                              value={slot.ready2book}
                              disabled={!slot.ready2book}
                              onClick={(_) => this.submitBooking(day, slot)}
                            >
                              {this.getText("08.ST.0610.BT")}
                            </button>
                          </div>
                        )}

                      {store._auth.isEmployee && slot.booked && (
                        <div className={"calendar-list__buttons"}>
                          <button
                            type={"button"}
                            className={"form-button"}
                            disabled={!day || !slot || this.isInPast(day)}
                            onClick={(_) => this.changeBooking(day, slot)}
                          >
                            {this.getText("08.WT.0600.BT.01")}
                          </button>
                          <button
                            type={"button"}
                            className={"form-button"}
                            disabled={!day || !slot || this.isInPast(day)}
                            onClick={(_) =>
                              this.setState({ removeOverlay: slot.id })
                            }
                          >
                            {this.getText("08.WT.0600.BT.02")}
                          </button>
                          {removeOverlay === slot.id && (
                            <div className={"overlay"}>
                              <p>{this.getText("08.WT.0640.DF.C")}</p>
                              <button
                                type={"button"}
                                className={"form-button"}
                                disabled={!day || !slot || this.isInPast(day)}
                                onClick={(_) => this.removeBooking(day, slot)}
                              >
                                {this.getText("08.WT.0640.DF.BT")}
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                      {store._auth.isEmployee && slot.bookable && (
                        <div className={"calendar-list__buttons form"}>
                          <Select
                            value={""}
                            label={this.getText("08.ST.0610.DT")}
                            options={this.classesForUser}
                            onChange={({ value }) => {
                              slot.ready2book = parseInt(value)
                              this.setState({ day })
                            }}
                          />
                          <button
                            type={"button"}
                            className={"form-button"}
                            value={slot.ready2book}
                            disabled={!slot.ready2book}
                            onClick={(_) => this.submitBooking(day, slot)}
                          >
                            {this.getText("08.ST.0610.BT")}
                          </button>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          {day && classToChange && (
            <div className={"calendar-list__slots"}>
              <p>{this.getText("08.WT.0630.HL")}</p>
              <div className={"grid-3"}>
                <div className={"calendar-list__slot"}>&nbsp;</div>
                <div
                  className={`calendar-list__slot ${
                    day && slot ? "calendar-list__slot--bookable" : ""
                  }`}
                >
                  <p>
                    {day && slot
                      ? `${day.date} - ${slot.time.substring(0, 5)} Uhr`
                      : this.getText("08.WT.0630.H")}
                  </p>
                  <div>
                    <span>
                      {classToChange.school.name}
                      <br />
                      {classToChange.classname}
                    </span>
                  </div>
                  <div className={"calendar-list__buttons"}>
                    <button
                      type={"button"}
                      className={"form-button"}
                      onClick={(_) => this.setState({ classToChange: null })}
                    >
                      {this.getText("08.WT.0630.BT.01")}
                    </button>
                    <button
                      type={"button"}
                      className={"form-button"}
                      disabled={!day || !slot}
                      onClick={(_) =>
                        this.setState({ confirmOverlay: slot.id })
                      }
                    >
                      {this.getText("08.WT.0630.BT.02")}
                    </button>
                    {slot && confirmOverlay === slot.id ? (
                      <div className={"overlay"}>
                        <p>{`Neuer Termin: ${day.date} - ${slot.time.substring(
                          0,
                          5
                        )} Uhr`}</p>
                        <button
                          type={"button"}
                          className={"form-button"}
                          disabled={!day || !slot}
                          onClick={(_) => this.submitBooking(day, slot)}
                        >
                          {this.getText("08.WT.0631.DF.BT")}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={"calendar-list__slot"}>&nbsp;</div>
              </div>
            </div>
          )}
          {day && (
            <div
              className={"calendar-list__legend calendar-list__legend--slots"}
            >
              <span>{this.getText("08.WT.0600.L.04")}</span>
              <span>{this.getText("08.WT.0600.L.05")}</span>
            </div>
          )}
        </div>
      )
    )
  }
}

import React from "react"

import PageIntern from "../../components/PageIntern"
import CalendarList from "./CalendarList"
import ClassList from "./ClassList"
import DailyView from "./DailyView"
import Statistics from "./Statistics/Statistics"
import TeacherList from "./TeacherList"

export default class Start extends PageIntern {
  init = () => {
    if (!this.props.store.view) {
      this.props.store.view = this.props.store._auth.isEmployee
        ? "daily"
        : "classes"
    }
  }

  /**
   * class view handler
   * @returns {boolean}
   */
  get classView() {
    return this.props.store.view === "classes"
  }

  /**
   * calendar view handler
   * @returns {boolean}
   */
  get calendarView() {
    return this.props.store.view === "calendars"
  }

  /**
   * dashboard for employee
   * @returns {boolean}
   */
  get dailyView() {
    return this.props.store.view === "daily"
  }

  /**
   * statistics view handler
   * @returns {boolean}
   */
  get statsView() {
    return this.props.store.view === "stats"
  }

  /**
   * teacher for admins
   * @returns {boolean}
   */
  get teacherView() {
    return this.props.store.view === "teacher"
  }

  /**
   * students for admins
   * @returns {boolean}
   */
  get studentsView() {
    return this.props.store.view === "students"
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  view() {
    const { store } = this.props

    return (
      <section>
        <div className={"tabs"}>
          {store._auth.isEmployee ? (
            <button
              className={`tab ${this.dailyView ? "tab--active" : ""}`}
              type={"button"}
              onClick={(_) => (store.view = "daily")}
            >
              {this.getText("08.ST.0200.M.01")}
            </button>
          ) : null}
          <button
            className={`tab ${this.classView ? "tab--active" : ""}`}
            type={"button"}
            onClick={(_) => (store.view = "classes")}
          >
            {this.getText("08.WT.0300.M.01")}
          </button>
          {store._auth.isAdmin ? (
            <button
              className={`tab ${this.teacherView ? "tab--active" : ""}`}
              type={"button"}
              onClick={(_) => (store.view = "teacher")}
            >
              {this.getText("08.ST.0200.M.05")}
            </button>
          ) : null}
          <button
            className={`tab ${this.calendarView ? "tab--active" : ""}`}
            type={"button"}
            onClick={(_) => (store.view = "calendars")}
          >
            {this.getText("08.WT.0300.M.02")}
          </button>
          {store._auth.isEmployee || store._auth.isReadonly ? (
            <button
              className={`tab ${this.statsView ? "tab--active" : ""}`}
              type={"button"}
              onClick={(_) => (store.view = "stats")}
            >
              {this.getText("08.ST.0200.M.04")}
            </button>
          ) : null}
        </div>
        {this.dailyView && <DailyView {...this.props} />}
        {this.classView && <ClassList {...this.props} />}
        {this.calendarView && <CalendarList {...this.props} />}
        {this.statsView && <Statistics {...this.props} />}
        {this.teacherView && <TeacherList {...this.props} />}
      </section>
    )
  }
}

import "../styles/Location.scss"

import React from "react"

import Page from "../components/Page"
import Slider from "../components/Slider"
import { ReactComponent as Door } from "../images/door.svg"

export default class Location extends Page {
  get slider() {
    const { slider } = this.state.data

    return (
      slider && (
        <div className={"container"}>
          <h2>{slider.headline}</h2>
          <Slider slides={slider.slides} />
        </div>
      )
    )
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { data } = this.state

    return (
      data && (
        <section>
          {this.header(data.information)}
          {
            <div className={"content__symbol"}>
              <Door />
            </div>
          }
          {this.slider}

          <div className={"container"}>
            <div className={"two-columns"}>{this.contents(data.content)}</div>
          </div>
        </section>
      )
    )
  }
}

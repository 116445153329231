import Page from "../components/Page"

export default class Home extends Page {
  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    return null
  }
}

import React from "react"

export default class AccountPopup extends React.Component {
  state = {
    time: new Date().toLocaleTimeString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
    }),
  }

  /**
   * mount
   */
  componentDidMount() {
    this.interval = setInterval(
      () =>
        this.setState({
          time: new Date().toLocaleTimeString("de-DE", {
            hour: "2-digit",
            minute: "2-digit",
          }),
        }),
      10_000
    )
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    return <span>{this.state.time} Uhr | </span>
  }
}

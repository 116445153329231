/* eslint-disable jsx-a11y/anchor-is-valid */
// import { useDraggable } from "../../../components/Draggable/Draggable"
import { clsx } from "clsx"
import { useCallback, useEffect, useMemo } from "react"
import { useDrag, useDrop } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"

import Styles from "./Pupil.module.scss"
import { PupilDeleteOverlay } from "./PupilDeleteOverlay"

export const DraggingPupil = ({ pupil }) => {
  return (
    <div className={Styles.customDragged}>
      <span className={Styles.bwt}>{pupil.bwt}</span>
      <span>
        {pupil.forename} {pupil.lastname}
      </span>
    </div>
  )
}

// Row und Col sind die Koordinaten der Gruppen, also A oder B für row, und 1-4
// für col
export const PupilPlaceholder = ({ row, col, onDrop }) => {
  const [dropState, drop] = useDrop({
    accept: "pupil",
    canDrop: (item) => `${row}_${col}` !== item.item.group,
    drop: (item, _monitor) => {
      onDrop(item.item.id, `${row}_${col}`)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const className = useMemo(
    () =>
      clsx({
        [Styles.droppable]: dropState.canDrop && dropState.isOver,
      }),
    [dropState.canDrop, dropState.isOver]
  )

  return <div ref={drop} className={className} />
}

export const Pupil = ({
  dragDisabled,
  pupil,
  handleRfid,
  rfidOverlay,
  getText,
  printOneResult,
  onClickDelete,
  remove,
  deleteOverlay,
  switchStudentGroups,
  closeDeleteOverlay,
  printSticker,
  index,
}) => {
  const [state, drag, preview] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      isDraggable: monitor.canDrag(),
      canDrag: !dragDisabled,
    }),
    item: { item: pupil, type: "pupil" },
    type: "pupil",
  })

  useEffect(() => {
    if (state.isDragging) {
      // Overlay schließen, wenn Drag beginnt. Click-Outside greift nämlich dann
      // noch nicht
      closeDeleteOverlay()
    }
  }, [closeDeleteOverlay, state.isDragging])

  const [dropState, drop] = useDrop({
    accept: "pupil",
    canDrop: (item) => pupil.group !== item.item.group,
    drop: (item, _monitor) => {
      switchStudentGroups(item.item.id, pupil.id)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const className = useMemo(
    () =>
      clsx({
        [Styles.draggable]: state.isDraggable,
        [Styles.dragging]: state.isDragging,
        [Styles.droppable]: dropState.canDrop && dropState.isOver,
      }),
    [dropState.canDrop, dropState.isOver, state.isDraggable, state.isDragging]
  )

  const setRef = useCallback(
    (instance) => {
      drag(instance)
      drop(instance)
    },
    [drag, drop]
  )

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  if (!pupil) return null

  return (
    <div ref={setRef} className={className}>
      <div key={`student-${pupil.id}`} className={"class-detail__content"}>
        <span>{index}</span>
        <span>
          {pupil.forename} {pupil.lastname}
        </span>
        <div className={"class-detail__link"}>
          <a href={"#"} onClick={(e) => handleRfid(e, pupil)}>
            {pupil.rfid || getText("08.ST.0600.L.04")}
          </a>
          <a href={"#"} onClick={(e) => printOneResult(e, pupil)}>
            {getText("08.ST.0600.L.06")}
          </a>
          <a href={"#"} onClick={() => onClickDelete(pupil.id)}>
            {getText("08.ST.0600.L.07")}
          </a>
          {deleteOverlay && (
            <PupilDeleteOverlay
              getText={getText}
              remove={remove}
              close={closeDeleteOverlay}
            />
          )}
          {rfidOverlay === pupil.id && (
            <div className={"overlay"}>
              {pupil.rfid ? (
                <div className={"grid-2"}>
                  <div>
                    <p>{getText("08.ST.0600.DF.C.02")}</p>
                    <button
                      type={"button"}
                      className={"form-button"}
                      onClick={(_) => printSticker(pupil)}
                    >
                      {getText("08.ST.0600.DF.BT.01")}
                    </button>
                  </div>
                  <div>
                    <p>{getText("08.ST.0600.DF.C.03")}</p>
                    <button
                      type={"button"}
                      className={"form-button"}
                      onClick={(e) => {
                        pupil.rfid = null
                        handleRfid(e, pupil)
                      }}
                    >
                      {getText("08.ST.0600.DF.BT.02")}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <p>{getText("08.ST.0600.DF.C.01")}</p>
                  <div className="lds-dual-ring" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

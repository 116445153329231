import PropTypes from "prop-types"
import { useMemo } from "react"
import { Cell } from "recharts"

import {
  TextAndPieChartStatistics,
  TextLine,
} from "../TextAndPieChartStatistics"

const COLORS = { participated: "#fae760", notParticipated: "#d5d4cf" }

const Classes = ({ statisticsData, getText }) => {
  const data = useMemo(() => {
    const data = {
      participated: {
        display: getText("08.ST.0500.L.25"),
        value: 0,
        key: "participated",
      },
      notParticipated: {
        display: getText("08.ST.0500.L.26"),
        value: 0,
        key: "notParticipated",
      },
    }

    const counts = {}

    statisticsData?.statistics?.forEach((stats) => {
      const participated = stats?.participated
      const key = participated ? "participated" : "notParticipated"
      counts[key] = (counts[key] || 0) + 1
    })

    Object.keys(counts).forEach((k) => (data[k].value = counts[k]))

    return data
  }, [getText, statisticsData])

  const chartData = useMemo(() => Object.values(data), [data])

  const Text = () => {
    return (
      <>
        <TextLine
          color={COLORS.participated}
          text={data.participated.display}
          value={data.participated.value}
        />
        <TextLine
          color={COLORS.notParticipated}
          text={data.notParticipated.display}
          value={data.notParticipated.value}
        />
      </>
    )
  }

  return (
    <TextAndPieChartStatistics
      data={chartData}
      text={<Text />}
      description={getText("08.ST.0500.C.07")}
      displayPercentage
    >
      {chartData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[entry.key]} />
      ))}
    </TextAndPieChartStatistics>
  )
}

Classes.propTypes = {
  statisticsData: PropTypes.object,
}

export { Classes }

import "../styles/SignIn.scss"

import React from "react"
import { Redirect } from "react-router-dom"

import Form from "../components/Form"
import Input from "../components/Input"
import Loader from "../components/Loader"
import Page from "../components/Page"
import Auth from "../stores/Auth"

export default class SignIn extends Page {
  state = Object.assign(this.state, {
    username: {
      value: "",
      name: "username",
      type: "text",
      errorMsg: "Ungültiger Benutzername",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ username: Object.assign(this.state.username, value) }),
    },
    password: {
      value: "",
      name: "password",
      type: "password",
      errorMsg: "Ungültiges Password",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ password: Object.assign(this.state.password, value) }),
    },
    email: {
      value: "",
      name: "email",
      type: "email",
      errorMsg: "Ungültige E-Mail Adresse",
      error: false,
      required: true,
      pattern:
        "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])",
      onChange: (value) =>
        this.setState({ email: Object.assign(this.state.email, value) }),
    },
    loggedIn: false,
    apiErrorMsg: null,
    showNewPassword: false,
    sendNewPassword: false,
  })

  /**
   * constructor
   * @param props
   */
  constructor(props) {
    super(props)

    this.signInForm = new Form({
      className: "form-sign-in",
      onSubmit: this.submitSignIn,
      fields: {
        username: this.state.username,
        password: this.state.password,
      },
    })

    this.newPasswordForm = new Form({
      className: "form-new-password",
      onSubmit: this.submitNewPassword,
      fields: {
        email: this.state.email,
      },
    })
  }

  /**
   * init
   */
  afterDidMount = () => {
    this.setState({
      loggedIn:
        this.state.loggedIn === false &&
        Auth.getToken() &&
        Auth.getToken().length > 0,
    })
  }

  /**
   * handle sign in submit
   * @param e
   */
  submitSignIn = (e) => {
    const { store } = this.props
    e.preventDefault()

    Loader.show()
    store._api
      .loginUser({
        identifier: this.state.username.value,
        password: this.state.password.value,
      })
      .then((result) => {
        if (result.error) {
          this.setState({ apiErrorMsg: result.msg, showNewPassword: false })
          store.notify({ loggedIn: false })
          //const element = this.form.current.querySelector(`[name="${result.key}"]`)
          //element && element.focus()
        } else {
          this.setState({ apiErrorMsg: null })
          // AUTH
          store._auth.set(result.data)
          store.notify({ loggedIn: true })
          store.view = store._auth.isEmployee
            ? "daily"
            : store._auth.isReadonly
            ? "calendars"
            : "classes"
        }
        Loader.hide()
      })
  }

  /**
   * handle new password submit
   */
  submitNewPassword = (e) => {
    e.preventDefault()

    this.props.store._api
      .newPassword({
        email: this.state.email.value,
      })
      .then((result) => {
        !result.error &&
          this.setState({
            sendNewPassword: true,
            showNewPassword: false,
          })
      })
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const {
      username,
      password,
      email,
      loggedIn,
      texts,
      apiErrorMsg,
      showNewPassword,
    } = this.state

    return texts ? (
      <section>
        <h2>{this.getText("08.WT.0200.SL")}</h2>

        {loggedIn ? (
          <Redirect to={this.props.store.routes.intern_start.path.to} />
        ) : (
          <div className={"form-wrapper"}>
            <Form {...this.signInForm.props}>
              <div className={"form-row"}>
                <Input {...username} label={this.getText("08.WT.0200.EF.01")} />
              </div>
              <div className={"form-row"}>
                <Input {...password} label={this.getText("08.WT.0200.EF.02")} />
              </div>
              <div className={"form-row button-row"}>
                <button
                  className={"form-button"}
                  disabled={!this.signInForm.isValid}
                  type={"submit"}
                >
                  {this.getText("08.WT.0200.BT")}
                </button>
                {apiErrorMsg && (
                  <div className={"form-input-error"}>{apiErrorMsg}</div>
                )}
                <div className={"link__arrow"}>
                  <a
                    href={"#new-password"}
                    onClick={(_) =>
                      this.setState({
                        showNewPassword: !this.state.showNewPassword,
                        apiErrorMsg: null,
                      })
                    }
                  >
                    {this.getText("08.WT.0200.BT.L")}
                  </a>
                </div>
              </div>
            </Form>
            {showNewPassword && (
              <Form {...this.newPasswordForm.props}>
                <p>{this.getText("08.WT.0220.DF.C")}</p>
                <div className={"form-row"}>
                  <Input {...email} label={this.getText("08.WT.0220.DF.EF")} />
                  <button
                    className={"form-button"}
                    disabled={!this.newPasswordForm.isValid}
                    type={"submit"}
                  >
                    {this.getText("08.WT.0220.DF.BT")}
                  </button>
                </div>
              </Form>
            )}
          </div>
        )}
      </section>
    ) : null
  }
}

import PropTypes from "prop-types"
import { useMemo } from "react"
import { Cell } from "recharts"

import {
  TextAndPieChartStatistics,
  TextLine,
} from "../TextAndPieChartStatistics"

const COLORS = { 8: "#fae760", 9: "#d5d4cf" }

const SchoolYear = ({ statisticsData, getText }) => {
  const data = useMemo(() => {
    const data = {
      8: { display: getText("08.ST.0500.L.05"), value: 0, key: "8" },
      9: { display: getText("08.ST.0500.L.06"), value: 0, key: "9" },
    }

    const counts = {}

    statisticsData?.statistics?.forEach((stats) => {
      if (!stats.participated) {
        return
      }

      const schoolYear = stats?.year

      if (schoolYear >= 8 && schoolYear <= 9) {
        counts[schoolYear] = (counts[schoolYear] || 0) + 1
      }
    })

    Object.keys(counts).forEach((k) => (data[k].value = counts[k]))

    return data
  }, [getText, statisticsData?.statistics])

  const chartData = useMemo(() => Object.values(data), [data])

  const Text = () => {
    return (
      <>
        <TextLine
          color={COLORS[8]}
          text={data[8].display}
          value={data[8].value}
        />
        <TextLine
          color={COLORS[9]}
          text={data[9].display}
          value={data[9].value}
        />
      </>
    )
  }

  return (
    <TextAndPieChartStatistics
      data={chartData}
      text={<Text />}
      description={getText("08.ST.0500.C.03")}
      displayPercentage
    >
      {chartData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[entry.key]} />
      ))}
    </TextAndPieChartStatistics>
  )
}

SchoolYear.propTypes = {
  statisticsData: PropTypes.object,
}

export { SchoolYear }

import "../styles/Sort.scss"

import React from "react"

export default class Sort extends React.Component {
  state = {
    active: this.props.active || false,
    direction: "desc",
  }

  /**
   * update sort elements, set default desc sorting if inactive
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        active: this.props.active,
        direction: this.props.active ? this.state.direction : "desc",
      })
    }
  }

  /**
   * on click handling
   */
  onClick = () => {
    this.setState({
      active: true,
      direction: this.state.direction === "asc" ? "desc" : "asc",
    })
    this.props.onChange && this.props.onChange()
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { active, direction } = this.state

    return (
      <div
        className={`sortable ${active ? `sort-${direction}` : "sort-desc"}`}
        onClick={this.onClick}
      >
        {this.props.children}
      </div>
    )
  }
}

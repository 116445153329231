import "../styles/About.scss"

import React from "react"

import Page from "../components/Page"
import { ReactComponent as Like } from "../images/like.svg"

export default class About extends Page {
  /**
   * get partner container
   * @returns {*|JSX.Element}
   */
  get partner() {
    const { partner } = this.state.data

    return (
      partner && (
        <div className={"container"}>
          <h2>{partner.headline}</h2>
          <div className={"two-columns"}>{this.text(partner.description)}</div>
          <ul className={"partner-logos"}>
            {partner.logos.map((logo) => (
              <li key={logo.id}>
                <a href={logo.link} target="_blank" rel="noreferrer">
                  {this.image(logo)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )
    )
  }

  /**
   * get team container
   * @returns {*|JSX.Element}
   */
  get team() {
    const { team } = this.state.data
    return (
      team && (
        <div className={"container"}>
          <h2>{team.headline}</h2>
          {this.image(team)}
        </div>
      )
    )
  }

  /**
   * render content block
   * @param content
   * @returns {*|JSX.Element}
   */
  contents(content) {
    return (
      <div className={"container"}>
        <div className={"two-columns text-break"}>
          {super.contents(content.slice(0, 1))}
        </div>
        <div className={"two-columns"}>{super.contents(content.slice(1))}</div>
      </div>
    )
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { data } = this.state

    return (
      data && (
        <section>
          {this.header(data.information)}
          {
            <div className={"content__symbol"}>
              <Like />
            </div>
          }
          {this.contents(data.content)}
          {this.partner}
          {this.team}
        </section>
      )
    )
  }
}

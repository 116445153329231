import React from "react"

export default class Form extends React.Component {
  /**
   * constructor
   * @param props
   */
  constructor(props) {
    super(props)

    this.init()
  }

  /**
   * init
   */
  init() {
    this.hash = this._hash(this._valuesAsString)
  }

  /**
   * generates a hash
   * @param str
   * @returns {*}
   * @private
   */
  _hash = (str) => {
    return str
      .split("")
      .reduce(
        (prevHash, currVal) =>
          ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
        0
      )
  }

  /**
   * generates a string of form values
   * @returns {string}
   * @private
   */
  get _valuesAsString() {
    let { fields } = this.props

    return fields
      ? Object.keys(fields)
          .map((key) => fields[key].value)
          .join("|")
      : ""
  }

  /**
   * check required fields
   * @returns {boolean}
   */
  get isValid() {
    return Object.values(this.props.fields).every((value) => {
      const elements = Array.isArray(value) ? value : [value]

      return elements.every((element) => {
        if (element.error) {
          return false
        }

        if (!element.required) {
          return true
        }

        if (
          element.value == null ||
          (element.type !== "checkbox" && element.value.trim().length === 0)
        ) {
          return false
        }

        return true
      })
    })
  }

  /**
   * is form changed
   * @returns {boolean}
   */
  get isChanged() {
    return this.hash !== this._hash(this._valuesAsString)
  }

  /**
   * reset form
   */
  reset() {
    const { fields } = this.props

    fields && Object.keys(fields).forEach((key) => (fields[key].value = ""))
    this.hash = this._hash(this._valuesAsString)
  }

  /**
   * render view
   */
  render() {
    const { className, onSubmit, children, id, innerRef } = this.props

    return (
      <form
        id={id}
        ref={innerRef}
        className={`form ${className}`}
        noValidate={true}
        onSubmit={onSubmit}
      >
        {children}
      </form>
    )
  }
}

import "./TextAndPieChartStatistics.scss"

import PropTypes from "prop-types"
import { Pie, PieChart, ResponsiveContainer } from "recharts"

const RADIAN = Math.PI / 180

const renderLabel = (
  {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    // display,
    value,
  },
  displayPercentage
) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)
  return (
    <text
      x={x}
      y={y}
      // fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      fontSize={24}
      className="pieText"
    >
      {value > 0 && (
        // <>{`${display} - ${value} (${(percent * 100).toFixed(0)}%)`}</>
        <>{displayPercentage ? `${(percent * 100).toFixed(0)}%` : value}</>
      )}
    </text>
  )
}

const TextAndPieChartStatistics = ({
  data,
  children,
  text,
  description,
  displayPercentage,
}) => {
  return (
    <div className="pieStatsGrid">
      <div>
        <div className="textStatistics">{text}</div>
        <div className="description">{description}</div>
      </div>
      <ResponsiveContainer
        width="100%"
        height="100%"
        minHeight="500px"
        minWidth="500px"
        className="pieChart"
      >
        <PieChart width={400} height={400}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={(props) => renderLabel(props, displayPercentage)}
            outerRadius={250}
            dataKey="value"
            stroke={""}
          >
            {children}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

TextAndPieChartStatistics.propTypes = {
  data: PropTypes.array.isRequired,
  children: PropTypes.array.isRequired,
  text: PropTypes.element.isRequired,
  description: PropTypes.string.isRequired,
}

const TextLine = ({ value, text, color }) => {
  return (
    <>
      <div className="colorBox" style={{ background: color }} />
      <div className="text">{text}</div>
      <div className="value">{value}</div>
    </>
  )
}

TextLine.propTypes = {
  value: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}

export { TextAndPieChartStatistics, TextLine }

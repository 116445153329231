import "../styles/EmailConfirmed.scss"

import React from "react"

import Page from "../components/Page"

export default class EmailConfirmed extends Page {
  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { data } = this.state

    return (
      data && (
        <section>
          {this.header(data.information)}
          <div className={"two-columns"}>{this.contents(data.content)}</div>
        </section>
      )
    )
  }
}

import React from "react"

export const Input = ({
  name,
  type,
  label,
  pattern,
  errorMsg,
  error,
  empty,
  readOnly,
  style,
  className,
  maxLength,
  minLength,
  onClick,
  value,
  defaultValue,
  isHidden,
  onChange,
  innerRef,
}) => {
  const isValid = React.useMemo(() => {
    const patternRegex = pattern && new RegExp(pattern, "ig")

    return patternRegex
      ? value?.trim().length === 0 || value?.match(patternRegex) !== null
      : true
  }, [pattern, value])

  const onBlur = React.useCallback(
    (_e) => {
      onChange?.(value, !isValid)
    },
    [onChange, isValid, value]
  )

  const onChangeValue = React.useCallback(
    (e) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  const onClickCheckbox = React.useCallback(() => {
    onChange(!value)
  }, [onChange, value])

  return (
    <div className={`form-input ${className ?? ""}`} style={style}>
      <input
        className={
          "form-input-element" +
          (isHidden ? " d-none" : "") +
          (empty ? " empty" : "")
        }
        checked={type === "checkbox" ? value ?? defaultValue : null}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        onBlur={onBlur}
        onChange={onChangeValue}
        onClick={onClick}
        ref={innerRef}
        pattern={pattern}
        readOnly={readOnly}
        type={type}
        value={value ?? defaultValue}
      />
      {(type === "checkbox"
        ? true
        : value === undefined || value?.length === 0) && (
        <label
          className={"form-label"}
          onClick={type === "checkbox" ? onClickCheckbox : null}
        >
          {label}
        </label>
      )}
      {error && <div className={"form-input-error"}>{errorMsg || ""}</div>}
    </div>
  )
}

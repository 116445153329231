import "../styles/Register.scss"

import React from "react"
import Autosuggest from "react-autosuggest"
import { Link } from "react-router-dom"

import Form from "../components/Form"
import Input from "../components/Input"
import Loader from "../components/Loader"
import Page from "../components/Page"
import Select from "../components/Select"
import { ReactComponent as Trash } from "../images/BTN_Loeschen.svg"
import { ReactComponent as Plus } from "../images/BTN_plus.svg"

export default class Register extends Page {
  state = Object.assign(this.state, {
    salutation: {
      value: "",
      name: "salutation",
      errorMsg: "Ungültige Anrede",
      error: false,
      required: true,
      onChange: (salutation) =>
        this.setState({
          salutation: Object.assign(this.state.salutation, salutation),
        }),
    },
    firstname: {
      value: "",
      name: "firstname",
      type: "text",
      errorMsg: "Ungültiger Vorname",
      error: false,
      required: true,
      onChange: (firstname) =>
        this.setState({
          firstname: Object.assign(this.state.firstname, firstname),
        }),
    },
    lastname: {
      value: "",
      name: "lastname",
      type: "text",
      errorMsg: "Ungültiger Nachname",
      error: false,
      required: true,
      onChange: (lastname) =>
        this.setState({
          lastname: Object.assign(this.state.lastname, lastname),
        }),
    },
    email: {
      value: "",
      name: "email",
      type: "email",
      errorMsg: "Ungültige E-Mail Adresse",
      error: false,
      required: true,
      pattern:
        "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])",
      onChange: (email) =>
        this.setState({ email: Object.assign(this.state.email, email) }),
    },
    schools: [
      {
        value: "",
        error: false,
        required: true,
      },
    ],
    suggestions: [],
    allSchools: [],
    success: false,
    apiErrorMsg: null,
  })

  /**
   * constructor
   * @param props
   */
  constructor(props) {
    super(props)

    this.form = new Form({
      className: "form-register",
      onSubmit: this.formSubmit,
      fields: {
        salutation: this.state.salutation,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        schools: this.state.schools,
      },
    })
  }

  /**
   * init
   */
  afterDidMount = () => {
    this.props.store._api
      .getAllSchools()
      .then(
        (response) =>
          !response.error && this.setState({ allSchools: response.data })
      )
  }

  /**
   * add school to list
   */
  addSchool = () => {
    const lastSchool = this.state.schools[this.state.schools.length - 1]
    if (lastSchool.value.trim() !== "" && !lastSchool.error) {
      this.state.schools.push({
        value: "",
        error: false,
        required: true,
      })
      this.setState({ schools: this.state.schools })
    }
  }

  /**
   * remove school from list
   */
  removeSchool = (index) => {
    this.state.schools.splice(index, 1)
    this.setState({ schools: this.state.schools })
  }

  /**
   * get school suggestions by value
   * @param value
   * @returns {*[]}
   */
  getSchools = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    const selectedSchoolIds = []
    this.state.schools.forEach((school) => {
      const found = school.error
        ? null
        : this.getSchoolByNameAndId(school.value)
      found && selectedSchoolIds.push(found.id)
    })
    return inputLength === 0
      ? []
      : this.state.allSchools.filter(
          (school) =>
            school.name &&
            school.name.toLowerCase().slice(0, inputLength) === inputValue &&
            !selectedSchoolIds.includes(school.id)
        )
  }

  /**
   * get school by name and id
   * @param schoolNameWithId
   * @returns {*}
   */
  getSchoolByNameAndId = (schoolNameWithId) => {
    return this.state.allSchools.find(
      (school) => `${school.name} (${school.number})` === schoolNameWithId
    )
  }

  /**
   * handle form submit
   * @param e
   */
  formSubmit = (e) => {
    const { store } = this.props
    e.preventDefault()

    const { salutation, firstname, lastname, email, schools } = this.state
    const schoolObjects = []
    schools.forEach((school) =>
      schoolObjects.push(this.getSchoolByNameAndId(school.value))
    )

    Loader.show()
    store._api
      .addUser({
        salutation: salutation.value,
        forename: firstname.value,
        lastname: lastname.value,
        email: email.value,
        schools: schoolObjects,
        username: email.value,
        password: "",
        blocked: false,
        confirmed: false,
      })
      .then((result) => {
        if (result.error) {
          this.setState({ apiErrorMsg: result.msg })
          //const element = this.form.current.querySelector(`[name="${result.key}"]`)
          //element && element.focus()
        } else {
          this.setState({ success: true })
        }
        Loader.hide()
      })
  }

  /**
   * get all salutations
   * @returns {[]}
   */
  get salutations() {
    const options = []

    options.push(this.getText("08.WT.0101.DM-01"))
    options.push(this.getText("08.WT.0101.DM-02"))
    options.push(this.getText("08.WT.0101.DM-03"))
    options.push(this.getText("08.WT.0101.DM-04"))
    options.push(this.getText("08.WT.0101.DM-05"))

    return options
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const {
      salutation,
      firstname,
      lastname,
      email,
      schools,
      suggestions,
      success,
      texts,
      apiErrorMsg,
    } = this.state

    return texts ? (
      <section>
        <h2>{this.getText("08.WT.0100.SL")}</h2>

        {success ? (
          <div>
            {texts && this.getText("08.WT.0103.C", true)}
            <span className={"link__arrow"}>
              <Link {...this.props.store.routes.login.path}>
                {this.getText("08.WT.0103.BT.L")}
              </Link>
            </span>
          </div>
        ) : (
          <Form {...this.form.props}>
            <div className={"form-row"}>
              <Select
                {...salutation}
                label={this.getText("08.WT.0100.DT")}
                options={this.salutations}
              />
              <Input {...firstname} label={this.getText("08.WT.0100.EF.01")} />
              <Input {...lastname} label={this.getText("08.WT.0100.EF.02")} />
            </div>
            <div className={"form-row"}>
              <Input
                {...email}
                label={this.getText("08.WT.0100.EF.03")}
                errorMsg={this.getText("08.WT.0101.HF.C")}
              />
            </div>
            {schools.map((school, index) => (
              <div className={"form-row"} key={`school-${index}`}>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={({ value }) =>
                    this.setState({ suggestions: this.getSchools(value) })
                  }
                  onSuggestionsClearRequested={() =>
                    this.setState({ suggestions: [] })
                  }
                  getSuggestionValue={(suggestion) =>
                    `${suggestion.name} (${suggestion.number})`
                  }
                  renderSuggestion={(suggestion) => (
                    <div>
                      {suggestion.name} ({suggestion.number})
                    </div>
                  )}
                  inputProps={{
                    value: school.value,
                    type: "text",
                    className: "form-input-element",
                    label: this.getText("08.WT.0100.EF.04"),
                    onChange: (event, { newValue }) => {
                      this.state.schools[index].value = newValue
                      this.setState({ schools: this.state.schools })
                    },
                    onBlur: (event) => {
                      const isEmpty = event.target.value.trim().length === 0
                      const school = this.getSchoolByNameAndId(
                        event.target.value
                      )
                      this.state.schools[index].error = !school && !isEmpty
                      this.setState({
                        schools: this.state.schools,
                        hasError: !school && !isEmpty,
                      })
                    },
                  }}
                />
                {school.error && (
                  <div className={"form-input-error"}>
                    {this.getText("08.WT.0102.H")}
                  </div>
                )}
                {school.value.length === 0 && (
                  <label className={"form-label"}>Schule</label>
                )}
                {index + 1 === schools.length ? (
                  <button
                    type={"button"}
                    className={"form-input-add"}
                    onClick={this.addSchool}
                  >
                    <Plus />
                  </button>
                ) : (
                  <button
                    type={"button"}
                    className={"form-input-remove"}
                    onClick={(_) => this.removeSchool(index)}
                  >
                    <Trash />
                  </button>
                )}
              </div>
            ))}
            <div className={"form-row"}>{this.getText("08.WT.0100.H")}</div>
            <div className={"form-row button-row"}>
              <button
                className={"form-button"}
                disabled={!this.form.isValid}
                type={"submit"}
              >
                {this.getText("08.WT.0100.BT")}
              </button>
              {apiErrorMsg && (
                <div className={"form-input-error"}>{apiErrorMsg || ""}</div>
              )}
            </div>
          </Form>
        )}
      </section>
    ) : null
  }
}

import PropTypes from "prop-types"
import { useMemo } from "react"
import { Cell } from "recharts"

import {
  TextAndPieChartStatistics,
  TextLine,
} from "../TextAndPieChartStatistics"

const COLORS = { Y: "#fae760", K: "#eaeaea", P: "#d5d4cf", S: "#939393" }

const SchoolType = ({ statisticsData, getText }) => {
  const data = useMemo(() => {
    const data = {
      Y: { display: getText("08.ST.0500.L.07"), value: 0, key: "Y" },
      K: { display: getText("08.ST.0500.L.08"), value: 0, key: "K" },
      P: { display: getText("08.ST.0500.L.09"), value: 0, key: "P" },
      S: { display: getText("08.ST.0500.L.10"), value: 0, key: "S" },
    }

    const counts = {}

    statisticsData?.statistics?.forEach((stats) => {
      const schoolType = stats?.school_type?.toUpperCase()

      if (Object.keys(data).includes(schoolType) && stats?.participated) {
        counts[schoolType] = (counts[schoolType] || 0) + 1
      }
    })

    Object.keys(counts).forEach((k) => (data[k].value = counts[k]))

    return data
  }, [getText, statisticsData?.statistics])

  const chartData = useMemo(() => Object.values(data), [data])

  const Text = () => {
    return (
      <>
        <TextLine color={COLORS.Y} text={data.Y.display} value={data.Y.value} />
        <TextLine color={COLORS.K} text={data.K.display} value={data.K.value} />
        <TextLine color={COLORS.P} text={data.P.display} value={data.P.value} />
        <TextLine color={COLORS.S} text={data.S.display} value={data.S.value} />
      </>
    )
  }

  return (
    <TextAndPieChartStatistics
      data={chartData}
      text={<Text />}
      description={getText("08.ST.0500.C.04")}
    >
      {chartData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[entry.key]} />
      ))}
    </TextAndPieChartStatistics>
  )
}

SchoolType.propTypes = {
  statisticsData: PropTypes.object,
}

export { SchoolType }

import React from "react"
import { Link } from "react-router-dom"

import Page from "../../components/Page"

export default class AccountPopup extends Page {
  state = Object.assign(this.state, {})

  /**
   * mount
   */
  componentDidMount() {
    const { store } = this.props

    store.subscribe(this)
    store.loadTexts()
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { store } = this.props

    return (
      <ul>
        <li className={store._auth.isIncomplete ? "incomplete" : ""}>
          <Link {...store.routes.intern_profile.path}>
            {this.getText("08.WT.0900.M.01")}
          </Link>
        </li>
        <li>
          <Link {...store.routes.intern_documents.path}>
            {this.getText("08.WT.0900.M.02")}
          </Link>
        </li>
        <li>
          <Link
            to={"#"}
            onClick={(_) => {
              store._auth.clear()
              store.notify({ loggedIn: false, bouncer: true })
              store.clearData()
            }}
          >
            {this.getText("08.WT.0900.M.03")}
          </Link>
        </li>
      </ul>
    )
  }
}

import "../styles/Calendar.scss"

import React from "react"

import { ReactComponent as Arrow } from "../images/arrow_next.svg"
import Input from "./Input"

export default class Calendar extends Input {
  today = new Date()

  state = {
    days: null,
    day: null,
    slot: null,
    date: null,
    month: parseInt(this.props.month) || this.today.getMonth() + 1,
    year: parseInt(this.props.year) || this.today.getFullYear(),
  }

  /**
   * constructor
   * @param props
   */
  constructor(props) {
    super(props)

    this.today.setDate(this.today.getDate() + 28)
    this.state.month = parseInt(props.month) || this.today.getMonth() + 1
    this.state.year = parseInt(props.year) || this.today.getFullYear()

    if (props.date) {
      const [year, month, day] = props.date.date.split("-")
      this.state.month = parseInt(month)
      this.state.year = parseInt(year)
      this.state.date = {
        day: parseInt(day),
        month: parseInt(month),
        year: parseInt(year),
        slot: props.date.slot,
      }
    }
  }

  /**
   * init
   */
  componentDidMount() {
    const { date, month, year } = this.state

    this.getDays(month, year, (_) => this.setDate(date))
  }

  /**
   * set a new date and handle booked date status
   */
  setDate = (newDate) => {
    const { date, month } = this.state

    if (date && date.day === newDate.day && date.month === newDate.month) {
      // set booked day and booked slot
      const day = this.state.days.find(
        (day) => day.day === date.day && day.month === date.month
      )
      const slot = day && day.slots.find((slot) => slot.id === date.slot.id)
      this.setState({ day, slot })
      return
    }
    if (newDate && newDate.month === month) {
      // set new day and reset slot
      this.setState({ day: newDate, slot: null })
    }
  }

  /**
   * day click handling
   * @param date
   */
  dayClicked = (date) => this.setDate(date)

  /**
   * slot click handling
   * @param slot
   */
  slotClicked = (slot) => {
    const { button } = this.props

    slot.bookable &&
      this.setState({ slot }, (_) => {
        !button && this.submit()
      })
  }

  /**
   * get days by month and year
   * @param month
   * @param year
   * @param callback
   */
  getDays(month, year, callback = null) {
    this.props.store._api.getDates(month, year).then((dates) =>
      this.setState(
        {
          days: dates ? dates.data : [],
          month,
          year,
        },
        callback
      )
    )
  }

  /**
   * next click handling
   */
  nextClicked = () => {
    const { date, month, year } = this.state

    this.getDays(
      month === 12 ? 1 : month + 1,
      month === 12 ? year + 1 : year,
      (_) => this.setDate(date)
    )
  }

  /**
   * prev click handling
   */
  prevClicked = () => {
    const { date, month, year } = this.state

    this.getDays(
      month === 1 ? 12 : month - 1,
      month === 1 ? year - 1 : year,
      (_) => this.setDate(date)
    )
  }

  /**
   * submit handling
   */
  submit = () => {
    const { day, slot } = this.state

    this.props.onSubmit && this.props.onSubmit({ day, slot })
  }

  /**
   * get header (month and year)
   * @returns {string}
   */
  get header() {
    const date = new Date(`${this.state.year}/${this.state.month}/01`)

    return (
      date.toLocaleString("de-DE", { month: "long" }) +
      " " +
      date.toLocaleString("de-DE", { year: "numeric" })
    )
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { button, disabled } = this.props
    const { month, day, slot, days } = this.state

    return (
      <div className={"calendar"}>
        <div className={"calendar__month"}>
          <button
            type={"button"}
            className={"calendar__prev"}
            onClick={this.prevClicked}
          >
            <Arrow />
          </button>
          <span>{this.header}</span>
          <button
            type={"button"}
            className={"calendar__next"}
            onClick={this.nextClicked}
          >
            <Arrow />
          </button>
        </div>
        <div className={"calendar__weekdays grid-7"}>
          <div className={"calender__weekday"}>Mo</div>
          <div className={"calender__weekday"}>Di</div>
          <div className={"calender__weekday"}>Mi</div>
          <div className={"calender__weekday"}>Do</div>
          <div className={"calender__weekday"}>Fr</div>
          <div className={"calender__weekday"}>Sa</div>
          <div className={"calender__weekday"}>So</div>
        </div>
        <div className={"calendar__days grid-7"}>
          {days &&
            days.map((day, i) => {
              const classNames = ["calendar__day"]
              day.month !== month && classNames.push("calendar__day--inactive")
              day.slots.find((slot) => slot.bookable) &&
                classNames.push("calendar__day--bookable")
              day === this.state.day &&
                classNames.push("calendar__day--selected")

              return (
                <div
                  className={classNames.join(" ")}
                  key={`day-${i}`}
                  onClick={(_) => this.dayClicked(day)}
                >
                  {day.day}
                </div>
              )
            })}
        </div>
        <div className={"calendar__slots grid-3"}>
          {day &&
            day.slots.map((slot, i) => {
              const classNames = ["calendar__slot"]
              slot.booked && classNames.push("calendar__slot--inactive")
              slot.bookable && classNames.push("calendar__slot--bookable")
              slot === this.state.slot &&
                classNames.push("calendar__slot--selected")

              return (
                <div
                  className={classNames.join(" ")}
                  key={`slot-${i}`}
                  onClick={(_) => this.slotClicked(slot)}
                >
                  {slot.time.substr(0, 5)}
                </div>
              )
            })}
        </div>
        {button && (
          <div className={"calendar__button"}>
            <button
              type={"button"}
              className={"form-button"}
              disabled={!slot || !day || disabled}
              onClick={this.submit}
            >
              {button}
            </button>
          </div>
        )}
      </div>
    )
  }
}

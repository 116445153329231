import Error from "../components/Error"
import Auth from "./Auth"

export default class Api {
  /**
   * load page content
   * @returns {Promise<*>}
   */
  async getPageContent(path) {
    return await Api._get(path)
  }

  /**
   * load all navigation items
   * @returns {Promise<*>}
   */
  async getNavigation() {
    return await Api._get("/navigation/5")
  }

  /**
   * load all texts
   * @returns {Promise<*>}
   */
  async getTexts() {
    return await Api._get("/texts", { _limit: -1 })
  }

  /**
   * load all school classes
   * @returns {Promise<void>}
   */
  async getSchools() {
    return await Api._get("/intern/schools", { _limit: -1 }, Auth.getToken())
  }

  /**
   * load all schools
   * @returns {Promise<void>}
   */
  async getAllSchools() {
    return await Api._get("/schools", { _limit: -1 })
  }

  /**
   * add user
   * @returns {Promise<void>}
   */
  async addUser(data) {
    return await Api._post("/auth/local/register", data)
  }

  /**
   * login user
   * @returns {Promise<void>}
   */
  async loginUser(data) {
    return await Api._post("/auth/local", data)
  }

  /**
   * get user by jwt
   * @returns {Promise<*>}
   */
  async getUser() {
    return await Api._get("/users/me", {}, Auth.getToken())
  }

  /**
   * get user by id
   * @param id
   * @returns {Promise<*>}
   */
  async getUserById(id) {
    return await Api._get(`/users/${id}`, {}, Auth.getToken())
  }

  /**
   * update user by id
   * @param data
   * @returns {Promise<*>}
   */
  async updateUser(data) {
    return await Api._put(`/users/${data.id}`, data, Auth.getToken())
  }

  /**
   * create user
   * @param data
   * @returns {Promise<*>}
   */
  async createUser(data) {
    return await Api._post("/users", data, Auth.getToken())
  }

  /**
   * delete user by id
   * @param id
   */
  async deleteUser(id) {
    return await Api._delete(`/users/${id}`, {}, Auth.getToken())
  }

  /**
   * new password
   * @returns {Promise<*>}
   */
  async newPassword(data) {
    return await Api._post("/auth/forgot-password", data)
  }

  /**
   * load all classes by teacher
   * @returns {Promise<void>}
   */
  async getClasses() {
    return await Api._get("/intern/classes", { _limit: -1 }, Auth.getToken())
  }

  /**
   * delete class by id
   * @param id
   * @returns {Promise<*>}
   */
  async deleteClass(id) {
    return await Api._delete(`/classes/${id}`, {}, Auth.getToken())
  }

  /**
   * load one class by id
   * @param classId
   * @returns {Promise<*>}
   */
  async getOneClass(classId) {
    return await Api._get(
      `/intern/classes/${classId}`,
      { _limit: -1 },
      Auth.getToken()
    )
  }

  /**
   * load calendar dates by month and year
   * @returns {Promise<void>}
   */
  async getDates(month, year) {
    return await Api._get(
      `/intern/dates/${month}/${year}`,
      { _limit: -1 },
      Auth.getToken()
    )
  }

  /**
   * add new class
   * @param data
   * @returns {Promise<*>}
   */
  async newClass(data) {
    return await Api._post("/classes", data, Auth.getToken())
  }

  /**
   * update class
   * @param data
   * @returns {Promise<*>}
   */
  async updateClass(data) {
    return await Api._put(`/classes/${data.id}`, data, Auth.getToken())
  }

  /**
   * add new booking
   * @param data
   * @returns {Promise<*>}
   */
  async newBooking(data) {
    return await Api._post("/bookings", data, Auth.getToken())
  }

  /**
   * send email to confirmation after new class is added
   * @param data
   * @returns {Promise<*>}
   */
  async sendClassEmailConfirmation(data) {
    return await Api._post(
      "/intern/classes/sendconfirmation",
      data,
      Auth.getToken()
    )
  }

  /**
   * update booking
   * @param data
   * @returns {Promise<*>}
   */
  async updateBooking(data) {
    return await Api._put(`/bookings/${data.id}`, data, Auth.getToken())
  }

  /**
   * delete booking
   * @param data
   * @returns {Promise<*>}
   */
  async deleteBooking(data) {
    return await Api._delete(`/bookings/${data.id}`, {}, Auth.getToken())
  }

  /**
   * get all bookings
   * @returns {Promise<*>}
   */
  async getBookings() {
    return await Api._get("/bookings", { _limit: -1 }, Auth.getToken())
  }

  /**
   * upload excel sheet for inserting students
   * @param data
   * @returns {Promise<*>}
   */
  async importStudents(data) {
    return await Api._fetch(
      "/intern/students/import",
      data,
      Auth.getToken(),
      "POST",
      true
    )
  }

  /**
   * add new student
   * @param data
   * @returns {Promise<*>}
   */
  async addStudent(data) {
    return await Api._post("/students", data, Auth.getToken())
  }

  /**
   * update student
   * @param data
   * @returns {Promise<*>}
   */
  async updateStudent(data) {
    return await Api._put(`/students/${data.id}`, data, Auth.getToken())
  }

  /**
   * Vertauscht die Grupen zweier Schüler (die Farbzuordnung, Reihenfolge
   * innerhalb der Gruppen wird nicht unterstützt)
   * @param data
   * @returns {Promise<*>}
   */
  async switchStudentGroups(data) {
    return await Api._post(`/students/switchGroups`, data, Auth.getToken())
  }

  /**
   * delete student
   * @param data
   * @returns {Promise<*>}
   */
  async deleteStudent(data) {
    return await Api._delete(`/students/${data.id}`, {}, Auth.getToken())
  }

  /**
   * get all blocked calendar weekends
   * @returns {Promise<*>}
   */
  async getWeekends() {
    return await Api._get("/weekends", { _limit: -1 }, Auth.getToken())
  }

  /**
   * update one blocked calendar weekend
   * @returns {Promise<*>}
   */
  async updateWeekend(data) {
    return await Api._put(`/weekends/${data.id}`, data, Auth.getToken())
  }

  /**
   * add one blocked calendar weekend
   * @returns {Promise<*>}
   */
  async addWeekend(data) {
    return await Api._post("/weekends", data, Auth.getToken())
  }

  /**
   * remove one blocked calendar weekend
   * @returns {Promise<*>}
   */
  async deleteWeekend(data) {
    return await Api._delete(`/weekends/${data.id}`, {}, Auth.getToken())
  }

  /**
   * get all other calendar blocks
   * @returns {Promise<*>}
   */
  async getNotBookable() {
    return await Api._get("/not-bookables", { _limit: -1 }, Auth.getToken())
  }

  /**
   * update one other calendar block
   * @returns {Promise<*>}
   */
  async updateNotBookable(data) {
    return await Api._put(`/not-bookables/${data.id}`, data, Auth.getToken())
  }

  /**
   * add one other calendar block
   * @returns {Promise<*>}
   */
  async addNotBookable(data) {
    return await Api._post("/not-bookables", data, Auth.getToken())
  }

  /**
   * remove one other calendar block
   * @returns {Promise<*>}
   */
  async deleteNotBookable(data) {
    return await Api._delete(`/not-bookables/${data.id}`, {}, Auth.getToken())
  }

  /**
   * get rfid of student
   * @returns {Promise<*>}
   */
  async getRfId(data) {
    return await Api._get(
      `/intern/students/rfid/${data.id}`,
      {},
      Auth.getToken()
    )
  }

  /**
   * print sticker of student
   * @returns {Promise<*>}
   */
  async printSticker(data) {
    return await Api._get(
      `/intern/students/print/sticker/${data.id}`,
      {},
      Auth.getToken()
    )
  }

  /**
   * print results of student
   * @returns {Promise<*>}
   */
  async printResults(data) {
    return await Api._get(
      `/intern/students/print/results/${data.id}`,
      {},
      Auth.getToken()
    )
  }

  /**
   * prints one result of student
   * @returns {Promise<*>}
   */
  async printOneResult(data) {
    return await Api._get(
      `/intern/students/print/result/${data.id}`,
      {},
      Auth.getToken()
    )
  }

  /**
   * load statistic workloads by month and year
   * @returns {Promise<void>}
   */
  async getWorkloads(month, year) {
    return await Api._get(
      `/intern/statistic/workload/${month}/${year}`,
      { _limit: -1 },
      Auth.getToken()
    )
  }

  /**
   * load statistic group strength by month and year
   * @returns {Promise<void>}
   */
  async getGroupStrength(month, year) {
    return await Api._get(
      `/intern/statistic/groupstrength/${month}/${year}`,
      { _limit: -1 },
      Auth.getToken()
    )
  }

  async getMonthStatistics(month, year) {
    return await Api._get(
      `/intern/statistics/${month}/${year}`,
      { _limit: -1 },
      Auth.getToken()
    )
  }

  async getYearStatistics(_month, year) {
    return await Api._get(
      `/intern/statistics/${year}`,
      { _limit: -1 },
      Auth.getToken()
    )
  }

  /**
   * load statistic students by month and year
   * @returns {Promise<void>}
   */
  async getStudents(month, year) {
    return await Api._get(
      `/intern/statistic/students/${month}/${year}`,
      { _limit: -1 },
      Auth.getToken()
    )
  }

  /**
   * download statistics
   * @returns {Promise<*>}
   */
  async downloadStatistic(data) {
    return await Api._post("/intern/statistic/download", data, Auth.getToken())
  }

  /**
   * submit new students
   * @returns {Promise<*>}
   */
  async submitNewStudents(id) {
    return await Api._get(
      `/intern/classes/sendnewstudents/${id}`,
      { _limit: -1 },
      Auth.getToken()
    )
  }

  /**
   * load all teachers
   * @returns {Promise<void>}
   */
  async getTeachers() {
    return await Api._get("/users", { _limit: -1, role: 5 }, Auth.getToken())
  }

  // ------------------------------------------------------------------------------------------

  /**
   * fetch wrapper
   * @param path
   * @param data
   * @param jwt
   * @param method
   * @param formData
   * @returns {Promise<any>}
   * @private
   */
  static async _fetch(
    path,
    data = {},
    jwt = null,
    method = "POST",
    formData = false
  ) {
    const headers = new Headers()
    jwt && headers.append("Authorization", `Bearer ${jwt}`)
    !formData && headers.append("Content-Type", "application/json")

    return await fetch(`${process.env.REACT_APP_API}${path}`, {
      method,
      headers,
      mode: "cors",
      cache: "no-cache",
      body: formData ? data : JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        response.status = response.status || response.statusCode
        switch (response.status) {
          case 400:
            return {
              error: true,
              status: "nok",
              key: Error.getKeyById(response.data[0].messages[0].id),
              msg: Error.getMsgById(response.data[0].messages[0].id),
            }
          case 401:
          case 402:
          case 403:
          case 404:
          case 500:
            return {
              error: true,
              status: "nok",
              key: response.error,
              msg: response.message,
            }
          default:
            return {
              error: false,
              status: "ok",
              data: response,
            }
        }
      })
  }

  /**
   * fetch post mapper
   * @param path
   * @param data
   * @param jwt
   * @returns {Promise<any>}
   */
  static async _post(path, data = {}, jwt = null) {
    return await this._fetch(path, data, jwt, "POST")
  }

  /**
   * fetch pop mapper
   * @param path
   * @param data
   * @param jwt
   * @returns {Promise<any>}
   */
  static async _put(path, data = {}, jwt = null) {
    return await this._fetch(path, data, jwt, "PUT")
  }

  /**
   * fetch delete mapper
   * @param path
   * @param data
   * @param jwt
   * @returns {Promise<any>}
   */
  static async _delete(path, data = {}, jwt = null) {
    return await this._fetch(path, data, jwt, "DELETE")
  }

  /**
   * fetch get mapper
   * @param path
   * @param data
   * @param jwt
   * @returns {Promise<any>}
   */
  static async _get(path, data = {}, jwt = null) {
    const search = new URLSearchParams(data)
    const headers = new Headers()
    jwt && headers.append("Authorization", `Bearer ${jwt}`)
    headers.append("Content-Type", "application/json")

    return await fetch(
      `${process.env.REACT_APP_API}${path}${
        data ? `?${search.toString()}` : ""
      }`,
      {
        headers,
        method: "GET",
        mode: "cors",
        cache: "default",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        response.status = response.status || response.statusCode
        switch (response.status) {
          case 400:
            return {
              error: true,
              status: "nok",
              key: Error.getKeyById("Auth.error.invalid"),
              msg: Error.getMsgById("Auth.error.invalid"),
            }
          case 401:
          case 402:
          case 403:
            return {
              error: true,
              status: "nok",
              key: response.error,
              msg: response.message,
            }
          default:
            return {
              error: false,
              status: "ok",
              data: response,
            }
        }
      })
  }
}

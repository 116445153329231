import "../../styles/Account.scss"

import React from "react"
import Autosuggest from "react-autosuggest"
import { Link, withRouter } from "react-router-dom"

import Form from "../../components/Form"
import Input from "../../components/Input"
import Loader from "../../components/Loader"
import PageIntern from "../../components/PageIntern"
import Select from "../../components/Select"
import { ReactComponent as Trash } from "../../images/BTN_Loeschen.svg"
import { ReactComponent as Check } from "../../images/IMG_Check.svg"
import { ReactComponent as Close } from "../../images/IMG_Close_Icon.svg"

class Account extends PageIntern {
  state = Object.assign(this.state, {
    view: this.props.store.view,
    removeOverlay: false,
    addSchoolOverlay: false,
    confirmed: {
      value: false,
      name: "confirmed",
      type: "checkbox",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({
          confirmed: Object.assign(this.state.confirmed, value),
        }),
    },
    password1: {
      value: "",
      name: "password1",
      type: "password",
      errorMsg: "Ungültiges Password",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({
          password1: Object.assign(this.state.password1, value),
        }),
    },
    password2: {
      value: "",
      name: "password2",
      type: "password",
      errorMsg: "Ungültiges Password",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({
          password2: Object.assign(this.state.password2, value),
        }),
    },
    email: {
      value: "",
      name: "email",
      type: "email",
      errorMsg: "Ungültige E-Mail Adresse",
      error: false,
      required: true,
      pattern:
        "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])",
      onChange: (value) =>
        this.setState({ email: Object.assign(this.state.email, value) }),
    },
    phone: {
      value: "",
      name: "phone",
      type: "text",
      errorMsg: "Ungültige Telefonnummer",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ phone: Object.assign(this.state.phone, value) }),
    },
    salutation: {
      value: "",
      name: "salutation",
      errorMsg: "Ungültige Anrede",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({
          salutation: Object.assign(this.state.salutation, value),
        }),
    },
    forename: {
      value: "",
      name: "forename",
      type: "text",
      errorMsg: "Ungültiger Vorname",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ forename: Object.assign(this.state.forename, value) }),
    },
    lastname: {
      value: "",
      name: "lastname",
      type: "text",
      errorMsg: "Ungültiger Nachname",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ lastname: Object.assign(this.state.lastname, value) }),
    },
    school: {
      value: "",
      name: "school",
      type: "text",
      errorMsg: "Ungültige Schule",
      error: false,
      required: true,
      onChange: (value) =>
        this.setState({ school: Object.assign(this.state.school, value) }),
    },
    suggestions: [],
    allSchools: [],
  })

  /**
   * constructor
   * @param props
   */
  constructor(props) {
    super(props)

    this.contactDataForm = new Form({
      className: "form-contact-data",
      onSubmit: this.submit,
      fields: {
        confirmed: this.state.confirmed,
        email: this.state.email,
        phone: this.state.phone,
        salutation: this.state.salutation,
        forename: this.state.forename,
        lastname: this.state.lastname,
      },
    })
  }

  get isNewTeacher() {
    return this.props.route === this.props.store.routes.intern_add_teacher.key
  }

  get isExistingTeacher() {
    return this.props.route === this.props.store.routes.intern_teacher.key
  }

  get isProfile() {
    return this.props.route === this.props.store.routes.intern_profile.key
  }

  get isPasswordValid() {
    if (!this.isNewTeacher && this.state.password1.value === "") {
      return true
    }

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-])(?=.{8,})/
    return regex.test(this.state.password1.value)
  }

  get isPasswordEqual() {
    if (!this.isNewTeacher && this.state.password1.value === "") {
      return true
    }

    return this.state.password1.value === this.state.password2.value
  }

  init = async () => {
    Loader.show()

    this.user = {}

    if (this.isProfile) {
      this.user = this.props.store._auth.user
      await this.props.store.loadSchools(true)
    } else if (this.isExistingTeacher) {
      const { userId } = this.props.match.params

      const response = await this.props.store._api.getUserById(userId)

      if (response.error) {
        alert(response.msg ?? "Fehler beim Laden des Benutzers")
        this.props.history.replace("/")
        Loader.hide()
        return
      }

      this.user = response.data
      this.state.schools = this.user.schools
    }

    await new Promise((resolve) => {
      this.setState(
        {
          confirmed: Object.assign(this.state.confirmed, {
            value: this.user.confirmed ?? false,
          }),
          email: Object.assign(this.state.email, { value: this.user.email }),
          phone: Object.assign(this.state.phone, { value: this.user.phone }),
          salutation: Object.assign(this.state.salutation, {
            value: this.user.salutation,
          }),
          forename: Object.assign(this.state.forename, {
            value: this.user.forename,
          }),
          lastname: Object.assign(this.state.lastname, {
            value: this.user.lastname,
          }),
          password1: Object.assign(this.state.password1, { value: "" }),
          password2: Object.assign(this.state.password2, { value: "" }),
          removeOverlay: false,
          addSchoolOverlay: false,
        },
        resolve
      )
    })

    this.contactDataForm.init()

    const response = await this.props.store._api.getAllSchools()
    if (!response.error) {
      this.setState({ allSchools: response.data })
    }

    Loader.hide()
  }

  /**
   * handle sign in submit
   * @param e
   */
  submit = async (e) => {
    e.preventDefault()

    Loader.show()

    const createOrUpdateUser = this.isNewTeacher
      ? this.props.store._api.createUser
      : this.props.store._api.updateUser

    const result = await createOrUpdateUser({
      id: this.user.id,
      confirmed: this.state.confirmed.value,
      salutation: this.state.salutation.value,
      forename: this.state.forename.value,
      lastname: this.state.lastname.value,
      email: this.state.email.value,
      username: this.state.email.value,
      phone: this.state.phone.value,
      password:
        this.state.password1.value === ""
          ? undefined
          : this.state.password1.value,
    })

    if (!this.isProfile) {
      await this.props.store.loadTeachers(true)
    }

    if (result.error) {
      alert(result.msg ?? "Fehler beim Speichern des Benutzers")
    } else if (this.isNewTeacher) {
      //TODO: send email

      this.props.history.push(
        this.props.store.routes.intern_teacher.path.to.replace(
          ":userId",
          result.data.id
        )
      )
    } else {
      this.init()
    }

    Loader.hide()
  }

  /**
   * get all salutations
   * @returns {[]}
   */
  get salutations() {
    const options = []

    options.push(this.getText("08.WT.1000.DM-01"))
    options.push(this.getText("08.WT.1000.DM-02"))
    options.push(this.getText("08.WT.1000.DM-03"))
    options.push(this.getText("08.WT.1000.DM-04"))
    options.push(this.getText("08.WT.1000.DM-05"))

    return options
  }

  /**
   * get school suggestions by value
   * @param value
   * @returns {*[]}
   */
  getSchools = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    const selectedSchoolIds = []
    this.state.schools.forEach((school) => selectedSchoolIds.push(school.id))
    return inputLength === 0
      ? []
      : this.state.allSchools.filter(
          (school) =>
            school.name &&
            school.name.toLowerCase().slice(0, inputLength) === inputValue &&
            !selectedSchoolIds.includes(school.id)
        )
  }

  /**
   * get school by name and id
   * @param schoolNameWithId
   * @returns {*}
   */
  getSchoolByNameAndId = (schoolNameWithId) => {
    return this.state.allSchools.find(
      (school) => `${school.name} (${school.number})` === schoolNameWithId
    )
  }

  /**
   * remove school
   * @param school
   */
  removeSchool = async (school) => {
    Loader.show()

    await this.props.store._api.updateUser({
      id: this.user.id,
      schools: this.state.schools.filter((s) => s.id !== school.id),
    })

    Loader.hide()
    this.init()
  }

  /**
   * add school to list
   */
  addSchool = async () => {
    if (this.state.school.value.trim() === "" || this.state.school.error) return
    Loader.show()

    await this.props.store._api.updateUser({
      id: this.user.id,
      schools: [
        ...this.state.schools,
        this.getSchoolByNameAndId(this.state.school.value),
      ],
    })

    Loader.hide()
    this.init()
  }

  /**
   * check if input field is empty
   * @param value
   * @returns {boolean}
   */
  isEmpty(value) {
    return value.trim().length === 0
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  view() {
    const { store } = this.props
    const {
      confirmed,
      password1,
      password2,
      email,
      phone,
      salutation,
      forename,
      lastname,
      removeOverlay,
      addSchoolOverlay,
      schools,
      suggestions,
      school,
    } = this.state

    return (
      <section className="intern_profile">
        <Link {...store.routes.intern_start.path} className={"close"}>
          <Close />
        </Link>

        <h1>{this.getText("08.WT.1000.HL")}</h1>

        <Form {...this.contactDataForm.props}>
          <div className={"intern_profile--container"}>
            <div className={"left"}>
              <h2>{this.getText("08.WT.1000.SL.01")}</h2>
              <div className={"form-row"}>
                <Select
                  {...salutation}
                  value={this.user?.salutation ?? ""}
                  label={this.getText("08.WT.0100.DT")}
                  options={this.salutations}
                />
                <Input
                  {...forename}
                  value={this.user?.forename ?? ""}
                  empty={forename.value ? this.isEmpty(forename.value) : true}
                  label={this.getText("08.WT.1000.EF.01")}
                />
                <Input
                  {...lastname}
                  value={this.user?.lastname ?? ""}
                  empty={lastname.value ? this.isEmpty(lastname.value) : true}
                  label={this.getText("08.WT.1000.EF.02")}
                />
              </div>

              <h2>{this.getText("08.WT.1000.SL.02")}</h2>
              <div className={"form-row"}>
                <Input
                  {...email}
                  value={this.user?.email ?? ""}
                  empty={email.value ? this.isEmpty(email.value) : true}
                  label={this.getText("08.WT.1000.EF.03")}
                />
              </div>
              <div className={"form-row"}>
                <Input
                  {...phone}
                  value={this.user?.phone ?? ""}
                  empty={phone.value ? this.isEmpty(phone.value) : true}
                  label={this.getText("08.WT.1000.EF.04")}
                />
              </div>

              <h2>{this.getText("08.WT.1000.SL.03")}</h2>
              <p>{this.getText("08.WT.1000.C.01")}</p>
              <div className={"form-row"}>
                <Input
                  {...password1}
                  label={this.getText("08.WT.1000.EF.05")}
                />

                {password1.value !== "" && !this.isPasswordValid && (
                  <div className={"overlay overlay_not_valid"}>
                    <p>{this.getText("08.WT.1010.HF.C.A")}</p>
                  </div>
                )}
              </div>
              <div className={"form-row"}>
                <Input
                  {...password2}
                  label={this.getText("08.WT.1000.EF.06")}
                />

                {password2.value !== "" &&
                  this.isPasswordValid &&
                  !this.isPasswordEqual && (
                    <div className={"overlay overlay_not_equal"}>
                      <p>{this.getText("08.WT.1010.HF.C.B")}</p>
                    </div>
                  )}
              </div>

              {!this.isProfile && (
                <>
                  <h2>{this.getText("08.ST.0801.HL")} </h2>
                  <div className={"form-row"}>
                    <Input
                      {...confirmed}
                      label={this.getText("08.ST.0801.C")}
                    />
                  </div>
                </>
              )}
            </div>

            {schools && (
              <div className={"right"}>
                <h2>{this.getText("08.WT.1000.SL.04")}</h2>
                <p>{this.getText("08.WT.1000.C.02")}</p>

                <p>
                  <strong>
                    {schools.length} Schulen (
                    {schools.filter((school) => school.confirmed).length}{" "}
                    verifiziert)
                  </strong>
                </p>

                {schools.map((school) => (
                  <div
                    className={"school-container"}
                    key={`school-${school.id}`}
                  >
                    <div
                      className={
                        "school school-" +
                        school.id +
                        (school.confirmed ? " confirmed" : "")
                      }
                    >
                      {school.name} ({school.number})
                    </div>

                    <div className={"school-delete"}>
                      <div className={"school--delete-overlay"}>
                        <button
                          type={"button"}
                          className={"clean-button"}
                          onClick={(_) =>
                            this.setState({ removeOverlay: school.id })
                          }
                        >
                          <Trash />
                        </button>
                        {removeOverlay === school.id && (
                          <div className={"overlay"}>
                            <p>{this.getText("08.WT.1020.DF.C")}</p>
                            <button
                              type={"button"}
                              className={"form-button"}
                              onClick={(_) => this.removeSchool(school)}
                            >
                              {this.getText("08.WT.1020.DF.BT")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

                <div className={"school-add"}>
                  {!addSchoolOverlay && (
                    <div className={"link__arrow"}>
                      <a
                        href={"#add-school"}
                        onClick={(_) =>
                          this.setState({
                            addSchoolOverlay: !this.state.addSchoolOverlay,
                          })
                        }
                      >
                        {this.getText("08.WT.1000.BT.L")}
                      </a>
                    </div>
                  )}
                  {addSchoolOverlay && (
                    <div>
                      <div className={"form-row"}>
                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={({ value }) =>
                            this.setState({
                              suggestions: this.getSchools(value),
                            })
                          }
                          onSuggestionsClearRequested={() =>
                            this.setState({ suggestions: [] })
                          }
                          getSuggestionValue={(suggestion) =>
                            `${suggestion.name} (${suggestion.number})`
                          }
                          renderSuggestion={(suggestion) => (
                            <div>
                              {suggestion.name} ({suggestion.number})
                            </div>
                          )}
                          inputProps={{
                            value: school.value,
                            type: "text",
                            className: "form-input-element",
                            label: this.getText("08.WT.0100.EF.04"),
                            onChange: (event, { newValue }) => {
                              this.state.school.value = newValue
                              this.setState({ school: this.state.school })
                            },
                            onBlur: (event) => {
                              const school = this.getSchoolByNameAndId(
                                event.target.value
                              )
                              this.state.school.error = !school
                              this.setState({
                                school: this.state.school,
                                hasError: !school,
                              })
                            },
                          }}
                        />
                        {school.error && (
                          <div className={"form-input-error"}>
                            {this.getText("08.WT.0102.H")}
                          </div>
                        )}
                        {school.value.length === 0 && (
                          <label className={"form-label"}>Schule</label>
                        )}
                        <button
                          type={"button"}
                          className={"form-input-add school-add-button"}
                          onClick={this.addSchool}
                        >
                          <Check />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className={"form-footer"}>
            <button
              type={"button"}
              className={"form-button"}
              disabled={
                !this.contactDataForm.isChanged ||
                !this.contactDataForm.isValid ||
                !this.isPasswordValid ||
                !this.isPasswordEqual
              }
              onClick={this.submit}
            >
              {this.getText("08.WT.1000.BT")}
            </button>
          </div>
        </Form>
      </section>
    )
  }
}

export default withRouter(Account)

import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import Styles from "../BarStatistics.module.scss"
import { monthNames } from "../lib"

const Capacity = ({ month, statisticsData, year, getText }) => {
  const strings = useMemo(
    () => [getText("08.ST.0500.L.23"), getText("08.ST.0500.L.24")],
    [getText]
  )

  const data = useMemo(() => {
    if (month !== undefined) {
      const date = dayjs().month(month).year(year)

      const data = []

      if (statisticsData?.capacity) {
        for (let i = 1; i <= date.daysInMonth(); i++) {
          const capacity = statisticsData?.capacity[i - 1]

          data.push({
            name: i,
            [strings[0]]: capacity?.booked_slots ?? 0,
            [strings[1]]:
              (capacity?.available_slots ?? 3) - (capacity?.booked_slots ?? 0),
          })
        }
      }

      return data
    }

    const data = [
      {
        name: monthNames[0],
        [strings[0]]: 0,
        [strings[1]]: 0,
      },
      {
        name: monthNames[1],
        [strings[0]]: 0,
        [strings[1]]: 0,
      },
      {
        name: monthNames[2],
        [strings[0]]: 0,
        [strings[1]]: 0,
      },
      {
        name: monthNames[3],
        [strings[0]]: 0,
        [strings[1]]: 0,
      },
      {
        name: monthNames[4],
        [strings[0]]: 0,
        [strings[1]]: 0,
      },
      {
        name: monthNames[5],
        [strings[0]]: 0,
        [strings[1]]: 0,
      },
      {
        name: monthNames[6],
        [strings[0]]: 0,
        [strings[1]]: 0,
      },
      {
        name: monthNames[7],
        [strings[0]]: 0,
        [strings[1]]: 0,
      },
      {
        name: monthNames[8],
        [strings[0]]: 0,
        [strings[1]]: 0,
      },
      {
        name: monthNames[9],
        [strings[0]]: 0,
        [strings[1]]: 0,
      },
      {
        name: monthNames[10],
        [strings[0]]: 0,
        [strings[1]]: 0,
      },
      {
        name: monthNames[11],
        [strings[0]]: 0,
        [strings[1]]: 0,
      },
    ]

    if (statisticsData?.capacity) {
      for (let i = 0; i <= 11; i++) {
        data[i] = {
          name: data[i].name,
          [strings[0]]: statisticsData?.capacity[i]?.booked_slots ?? 0,
          [strings[1]]:
            (statisticsData?.capacity[i]?.available_slots ?? 3) -
            (statisticsData?.capacity[i]?.booked_slots ?? 0),
        }
      }
    }

    return data
  }, [month, statisticsData, strings, year])

  return (
    <div>
      <ResponsiveContainer
        width="100%"
        height="100%"
        minHeight="500px"
        minWidth="500px"
      >
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            domain={month != null ? [0, 3] : [0, 96]}
            ticks={month != null ? [0, 1, 2, 3] : [0, 31, 62, 93]}
          />
          <Tooltip />
          <Legend />
          <Bar dataKey={strings[0]} stackId="capacity" fill="#fae760" />
          <Bar dataKey={strings[1]} stackId="capacity" fill="#93918a" />
        </BarChart>
      </ResponsiveContainer>
      <div className={Styles.description}>{getText("08.ST.0500.C.06")}</div>
    </div>
  )
}

Capacity.propTypes = {
  statisticsData: PropTypes.object,
  month: PropTypes.number,
  year: PropTypes.number.isRequired,
}

export { Capacity }

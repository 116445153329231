import React from "react"
import { useDragLayer } from "react-dnd"

import { DraggingPupil } from "./Pupil"

const layerStyles = {
  height: "100%",
  left: 0,
  pointerEvents: "none",
  position: "fixed",
  top: 0,
  width: "100%",
  zIndex: 100,
}

function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    }
  }

  const { x, y } = currentOffset

  const transform = `translate(${x}px, ${y}px)`
  return {
    pointerEvents: "none",
    transform,
    WebkitTransform: transform,
  }
}

export const CustomDragLayer = () => {
  const { _itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      currentOffset: monitor.getSourceClientOffset(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
    }))

  //   function renderItem() {
  //     switch (itemType) {
  //       case "pupil":
  //         return <DraggingPupil />
  //       default:
  //         return null
  //     }
  //   }

  if (!isDragging) {
    return null
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <DraggingPupil pupil={item.item} />
      </div>
    </div>
  )
}

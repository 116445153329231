import { useRef } from "react"
import useClickOutside from "use-click-outside"

export const PupilDeleteOverlay = ({ getText, remove, close }) => {
  const ref = useRef()
  useClickOutside(ref, close)

  return (
    <div ref={ref} className={"overlay deletePupil"}>
      <p>{getText("09.ST.0900.DF.02")}</p>
      <button type={"button"} className={"form-button"} onClick={remove}>
        {getText("08.WT.0820.DF.BT")}
      </button>
    </div>
  )
}

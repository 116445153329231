import React from "react"
import { Redirect } from "react-router-dom"

export default class Page extends React.Component {
  state = {
    data: null,
    texts: null,
    redirect: null,
    loggedIn: false,
  }

  /**
   * mount
   */
  componentDidMount() {
    const { store, route, path } = this.props

    // close overlay if you clicked somewhere else
    document.addEventListener("mousedown", this.overlayListener)

    store.subscribe(this)
    store.loadContentData(route, path)
    store.loadTexts().then(this.afterDidMount)
  }

  /**
   * unmount
   */
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.overlayListener)

    this.props.store.unsubscribe(this)
  }

  /**
   * empty callback method after texts loaded
   * will be used in sub components
   */
  afterDidMount = () => {}

  /**
   * empty overlay listener
   * can be used in sub components
   */
  overlayListener = () => {}

  // /**
  //  * update
  //  * @param prevProps
  //  * @param prevState
  //  * @param snapshot
  //  */
  // componentDidUpdate() {
  //   const contentLinks = document.querySelectorAll(
  //     ".content .link__arrow a[href]"
  //   )
  //   // handle links in content for routing with react router
  //   for (const link of contentLinks) {
  //     if (!link.isPicked) {
  //       link.addEventListener("click", (e) => {
  //         e.preventDefault()
  //         const path = e.target.href.substring(e.target.href.lastIndexOf("/"))
  //         this.setState({ redirect: path })
  //       })
  //       link.isPicked = true
  //     }
  //   }
  // }

  /**
   * render text with markup
   * @param text
   * @param id
   * @returns {*|JSX.Element}
   */
  text(text, id) {
    const markup = () => ({ __html: text })
    return (
      text && (
        <div
          className={"container"}
          key={id || ""}
          dangerouslySetInnerHTML={markup()}
        />
      )
    )
  }

  /**
   * render headline
   * @param element
   * @param level
   * @returns {JSX.Element}
   */
  headline(element, level = "h2") {
    const CustomTag = `${element.level || level}`
    return (
      element && (
        <CustomTag key={`${element.__component}${element.id}`}>
          {element.headline}
        </CustomTag>
      )
    )
  }

  /**
   * render image
   * @param element
   * @returns {*|JSX.Element}
   */
  image(element) {
    const { image } = element
    return (
      image && (
        <img
          key={`${image.__component}${image.id}`}
          src={`${process.env.REACT_APP_API}${image.url}`}
          alt={image.alternativeText}
          className={image.classes || ""}
        />
      )
    )
  }

  /**
   * render content block
   * @param content
   * @returns {*|JSX.Element}
   */
  contents(content) {
    return content?.map((element) => {
      switch (true) {
        case element.hasOwnProperty("textarea"):
          return this.text(
            element.textarea,
            `${element.__component}${element.id}`
          )
        case element.hasOwnProperty("headline"):
          return this.headline(element)
        case element.hasOwnProperty("image"):
          return this.image(element)
        default:
          return null
      }
    })
  }

  /**
   * render content header
   * @param information
   * @returns {*|JSX.Element}
   */
  header(information) {
    return (
      information && (
        <div className={"content__header"}>
          {this.image(information)}
          {information.title && <h1>{information.title}</h1>}
        </div>
      )
    )
  }

  /**
   * return text by key
   * @param key
   * @param withLineBreaks
   * @returns {JSX.Element|*|string}
   */
  getText(key, withLineBreaks = false) {
    if (!this) {
      return
    }

    const { texts } = this.state
    const text = texts?.find((text) => text.key === key)
    const markup = () => ({
      __html: text?.value.replace(/\r?\n/g, "<br>"),
    })

    return text ? (
      withLineBreaks ? (
        <p dangerouslySetInnerHTML={markup()} />
      ) : (
        text.value
      )
    ) : (
      `${key.toUpperCase()}`
    )
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { redirect } = this.state

    if (redirect) return <Redirect push={true} to={redirect} />
  }
}

import "../styles/DataProtection.scss"

import React from "react"

import Page from "../components/Page"

export default class DataProtection extends Page {
  insertDate() {
    const { data } = this.state
    const options = { day: "2-digit", month: "2-digit", year: "numeric" }

    if (data?.content) {
      const date = new Date()
      data.content = data.content.map((content) => {
        if (content.textarea) {
          content.textarea = String(content.textarea).replace(
            "Aktuelles Datum",
            date.toLocaleDateString("de-DE", options)
          )
        }
        return content
      })
    }
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { data } = this.state

    this.insertDate()

    return (
      data && (
        <section>
          {data.information && this.image(data.information)}
          {data.information && <h1>{data.information.title}</h1>}
          <div className={"two-columns"}>{this.contents(data.content)}</div>
        </section>
      )
    )
  }
}

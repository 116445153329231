import "./Unterstuetzungsbedarf.scss"

import PropTypes from "prop-types"

import Form from "./Form"
import Input from "./Input"

const Unterstuetzungsbedarf = ({ inputProps, getText }) => {
  return inputProps.readOnly ? (
    inputProps.value && (
      <div className="unterstuetzungsbedarf">
        <div className="unterstuetzungsbedarf-icon">!</div>
        <div className="unterstuetzungsbedarf-text">
          {getText("08.ST.0600.L.05")}
        </div>
      </div>
    )
  ) : (
    <Form className="unterstuetzungsbedarf-editable">
      <Input {...inputProps} label={getText("08.ST.0700.L")} />
    </Form>
  )
}

Unterstuetzungsbedarf.propTypes = {
  getText: PropTypes.func.isRequired,
  inputProps: PropTypes.object.isRequired,
}

export { Unterstuetzungsbedarf }

import PropTypes from "prop-types"
import { useCallback, useEffect, useRef, useState } from "react"

import Form from "../../../components/Form"
import Input from "../../../components/Input"
import { Input as NewInput } from "../../../components/input/input"
import { ReactComponent as Check } from "../../../images/IMG_Check.svg"
import { ReactComponent as Close } from "../../../images/IMG_Close_Icon.svg"

export const useDidUpdate = (callback, data) => {
  const didMount = useRef(false)
  const prevData = useRef(data)

  useEffect(() => {
    if (didMount.current) {
      callback(prevData.current)
      prevData.current = data
    } else {
      didMount.current = true
    }
  }, [callback, data])
}

const StudentForm = ({
  birthday,
  city,
  confirmOverlay,
  displayForm,
  gender,
  getText,
  inputName,
  isChanged,
  name,
  newStudentForm,
  openConfirmOverlay,
  openResetOverlay,
  resetForm,
  resetFormOverlay,
  saveStudent,
  street,
  student,
  studentFormRef,
  surname,
  zip,
}) => {
  const [genderValue, setGender] = useState(gender.value)

  const initialGender = useCallback(() => {
    // const genderDisplay =
    //   student && gender.value.length === 0 ? student.gender : gender.value
    const genderDisplay = student?.gender

    switch (genderDisplay) {
      case "maennlich":
        return "m"
      case "weiblich":
        return "w"
      case "divers":
        return "d"
      default:
        return genderDisplay
    }
  }, [student])

  useDidUpdate((prev) => {
    if (prev !== student?.id) {
      setGender(initialGender())
    }
  }, student?.id)

  const onChangeGender = useCallback(
    (value, error) => {
      setGender(value)
      gender.onChange(value, error)
    },
    [gender]
  )

  return (
    <div>
      {displayForm && (
        <Form innerRef={studentFormRef} {...newStudentForm.props}>
          <div className={"table__row grid-8"} key={"student-add-form"}>
            <div>
              {newStudentForm.isValid && (
                <div className={"students-list__student--add"}>
                  <button
                    type={"button"}
                    className={"clean-button"}
                    onClick={openConfirmOverlay}
                  >
                    <Check />
                  </button>
                  {confirmOverlay && (
                    <div className={"overlay"}>
                      <p>{getText("08.WT.0812.DF.C")}</p>
                      <button
                        type={"button"}
                        className={"form-button"}
                        onClick={saveStudent}
                      >
                        {getText("08.WT.0812.DF.BT")}
                      </button>
                    </div>
                  )}
                </div>
              )}

              {isChanged && (
                <div className={"students-list__student--reset-fields"}>
                  <button
                    type={"button"}
                    className={"clean-button close"}
                    onClick={openResetOverlay}
                  >
                    <Close />
                  </button>
                  {resetFormOverlay && (
                    <div className={"overlay"}>
                      <p>{getText("08.WT.0811.DF.C")}</p>
                      <button
                        type={"button"}
                        className={"form-button"}
                        onClick={resetForm}
                      >
                        {getText("08.WT.0811.DF.BT")}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={"form-row"}>
              <Input
                {...surname}
                ref={inputName}
                defaultValue={
                  student && surname.value.length === 0
                    ? student.lastname
                    : surname.value
                }
                label={getText("08.WT.0800.EF.01")}
              />
            </div>
            <div className={"form-row"}>
              <Input
                {...name}
                defaultValue={
                  student && name.value.length === 0
                    ? student.forename
                    : name.value
                }
                label={getText("08.WT.0800.EF.02")}
              />
            </div>
            <div className={"form-row"}>
              <Input
                {...birthday}
                defaultValue={
                  student && birthday.value.length === 0
                    ? new Date(student.birthdate).toLocaleDateString("de-DE")
                    : birthday.value
                }
                label={getText("08.ST.0700.EF.03")}
              />
            </div>
            <div className={"form-row"}>
              <NewInput
                {...gender}
                value={genderValue}
                defaultValue={initialGender}
                onChange={onChangeGender}
                label={getText("08.WT.0800.DT")}
              />
            </div>
            <div className={"form-row"}>
              <Input
                {...street}
                defaultValue={
                  student && street.value.length === 0
                    ? `${student.street} ${student.housenumber ?? ""}`
                    : street.value
                }
                label={getText("08.WT.0800.EF.06")}
              />
            </div>
            <div className={"form-row"}>
              <Input
                {...zip}
                defaultValue={
                  student && zip.value.length === 0 ? student.zip : zip.value
                }
                label={getText("08.WT.0800.EF.07")}
              />
            </div>
            <div className={"form-row"}>
              <Input
                {...city}
                defaultValue={
                  student && city.value.length === 0 ? student.city : city.value
                }
                label={getText("08.WT.0800.EF.08")}
              />
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}

StudentForm.propTypes = {
  birthday: PropTypes.object,
  city: PropTypes.object,
  confirmOverlay: PropTypes.bool,
  displayForm: PropTypes.bool,
  gender: PropTypes.object.isRequired,
  getText: PropTypes.func.isRequired,
  inputName: PropTypes.object.isRequired,
  isChanged: PropTypes.bool,
  name: PropTypes.object.isRequired,
  newStudentForm: PropTypes.object.isRequired,
  openConfirmOverlay: PropTypes.func.isRequired,
  openResetOverlay: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  resetFormOverlay: PropTypes.bool.isRequired,
  saveStudent: PropTypes.func.isRequired,
  street: PropTypes.object.isRequired,
  student: PropTypes.object,
  studentFormRef: PropTypes.object.isRequired,
  surname: PropTypes.object.isRequired,
  zip: PropTypes.object.isRequired,
}

export { StudentForm }

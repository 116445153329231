import "../../styles/DailyView.scss"

import React from "react"
import { Link } from "react-router-dom"

import Loader from "../../components/Loader"
import PageIntern from "../../components/PageIntern"

export default class DailyView extends PageIntern {
  state = Object.assign(this.state, {
    bookings: null,
  })

  /**
   * mount
   */
  componentDidMount() {
    super.componentDidMount()

    this.loadBookings(true)
  }

  /**
   * get all bookings
   * @param force
   */
  loadBookings(force = false) {
    Loader.show()
    this.props.store.loadBookings(force).then((_) => Loader.hide())
  }

  /**
   * render positions helper
   * @param booking
   * @param textKey
   * @param isStart
   * @param isEnd
   * @returns {JSX.Element}
   */
  renderPosition(booking, textKey, isStart = false, isEnd = false) {
    const text = this.getText(textKey)
    const times = String(text).match(/([0-9]{2})/gi)
    let isDone = false
    let isActive = false
    let isOpen = false

    if (booking) {
      const today = new Date(/*2021, 4, 30, 13, 0*/)
      const startTime = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        times[0],
        times[1]
      )
      const endTime = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        times[2],
        times[3]
      )
      isDone = startTime < today && endTime <= today
      isActive = startTime <= today && endTime > today
      isOpen = startTime > today && endTime > today
    }

    const classNames = ["table__col--timeline"]
    isDone && classNames.push("table__col--timeline-done")
    isActive && classNames.push("table__col--timeline-active")
    isStart && classNames.push("table__col--timeline-start")
    isEnd && classNames.push("table__col--timeline-end")

    const states = ["state"]
    isDone && states.push("state--done")
    isActive && states.push("state--active")
    isOpen && states.push("state--open")

    return (
      <div className={classNames.join(" ")}>
        <div className={states.join(" ")}>
          <div className={"circle"}>&nbsp;</div>
        </div>
        <div className={`time ${isOpen ? "time--done" : ""}`}>
          {times[0]}:{times[1]}
          <br />
          {times[2]}:{times[3]}
        </div>
      </div>
    )
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { store } = this.props
    const { bookings } = this.state

    return (
      bookings && (
        <div className={"table table--daily"} role={"table"}>
          <div className={"table__header grid-8"}>
            <div />
            <div>
              <div className={"timeslot"}>
                {this.getText("08.ST.0200.L.01")}
              </div>
              <div className={"time"}>{this.getText("08.ST.0200.L.08")}</div>
            </div>
            <div>
              <div className={"timeslot"}>
                {this.getText("08.ST.0200.L.02")}
              </div>
              <div className={"time"}>{this.getText("08.ST.0200.L.09")}</div>
            </div>
            <div>
              <div className={"timeslot font-weight-normal"}>
                {this.getText("08.ST.0200.L.03")}
              </div>
              <div className={"time"}>{this.getText("08.ST.0200.L.10")}</div>
            </div>
            <div>
              <div className={"timeslot"}>
                {this.getText("08.ST.0200.L.04")}
              </div>
              <div className={"time"}>{this.getText("08.ST.0200.L.11")}</div>
            </div>
            <div>
              <div className={"timeslot font-weight-normal"}>
                {this.getText("08.ST.0200.L.05")}
              </div>
              <div className={"time"}>{this.getText("08.ST.0200.L.12")}</div>
            </div>
            <div className={"showroom text-center headline"}>
              <div className={"timeslot"}>
                {this.getText("08.ST.0200.L.06")}
              </div>

              <div className={"showroom"}>
                <div className={"time"}>{this.getText("08.ST.0200.L.13")}</div>
                <div className={"time"}>{this.getText("08.ST.0200.L.14")}</div>
              </div>
            </div>
            <div>
              <div className={"timeslot"}>
                {this.getText("08.ST.0200.L.07")}
              </div>
              <div className={"time"}>{this.getText("08.ST.0200.L.15")}</div>
            </div>
          </div>
          <div className={"table__content"}>
            {bookings.map((booking, i) => (
              <div className={"table__row grid-8"} key={`booking-${i}`}>
                <div>
                  {booking ? (
                    <div className={"booking__classname"}>
                      <div className={"link__arrow"}>
                        <Link
                          to={store.routes.intern_class.path.to.replace(
                            ":classId",
                            booking.classes.id
                          )}
                          onClick={(_) =>
                            store.routes.intern_class.path.onClick(
                              booking.classes.id
                            )
                          }
                        >
                          <div className={"school_name"}>
                            {booking.school[0].name}
                          </div>
                          <div className={"class_name"}>
                            Klasse {booking.classname}
                          </div>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className={"booking__classname"}>
                      <div className={"no_booking"}>
                        {this.getText("08.ST.0200.C.01.B")}
                      </div>
                    </div>
                  )}
                </div>
                {this.renderPosition(
                  booking,
                  i === 0
                    ? "08.ST.0200.T.01"
                    : i === 1
                    ? "08.ST.0200.T.09"
                    : "08.ST.0200.T.17",
                  true
                )}
                {this.renderPosition(
                  booking,
                  i === 0
                    ? "08.ST.0200.T.02"
                    : i === 1
                    ? "08.ST.0200.T.10"
                    : "08.ST.0200.T.18"
                )}
                {this.renderPosition(
                  booking,
                  i === 0
                    ? "08.ST.0200.T.03"
                    : i === 1
                    ? "08.ST.0200.T.11"
                    : "08.ST.0200.T.19"
                )}
                {this.renderPosition(
                  booking,
                  i === 0
                    ? "08.ST.0200.T.04"
                    : i === 1
                    ? "08.ST.0200.T.12"
                    : "08.ST.0200.T.20"
                )}
                {this.renderPosition(
                  booking,
                  i === 0
                    ? "08.ST.0200.T.05"
                    : i === 1
                    ? "08.ST.0200.T.13"
                    : "08.ST.0200.T.21"
                )}
                <div className={"showroom"}>
                  {this.renderPosition(
                    booking,
                    i === 0
                      ? "08.ST.0200.T.06"
                      : i === 1
                      ? "08.ST.0200.T.14"
                      : "08.ST.0200.T.22"
                  )}
                  {this.renderPosition(
                    booking,
                    i === 0
                      ? "08.ST.0200.T.07"
                      : i === 1
                      ? "08.ST.0200.T.15"
                      : "08.ST.0200.T.23"
                  )}
                </div>
                {this.renderPosition(
                  booking,
                  i === 0
                    ? "08.ST.0200.T.08"
                    : i === 1
                    ? "08.ST.0200.T.16"
                    : "08.ST.0200.T.24",
                  false,
                  true
                )}
              </div>
            ))}
          </div>
        </div>
      )
    )
  }
}

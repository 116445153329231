import React from "react"

/** @deprecated */
export default class Input extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value || "",
      error: this.props.error === true,
      ref: this.props.innerRef,
      isHidden: this.props.isHidden,
    }
  }

  /**
   * value change update
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps) {
    const { onChange, defaultValue, value } = this.props
    const val = defaultValue || value

    if (val !== prevProps.value) {
      this.setState({ value: val }, (_) => onChange?.(this.state))
    }
  }

  /**
   * handle change
   * @param e
   */
  onChange = (e) => {
    const { onChange, type, readOnly } = this.props

    if (readOnly) {
      return
    }

    this.setState(
      { value: type === "checkbox" ? e.target.checked : e.target.value },
      (_) => onChange?.(this.state)
    )
  }

  /**
   * validate value by pattern
   * @param value
   * @returns {boolean}
   */
  isValid = (value) => {
    const { pattern } = this.props
    const patternRegex = pattern && new RegExp(pattern, "ig")

    return patternRegex
      ? value.trim().length === 0 || value.match(patternRegex) !== null
      : true
  }

  /**
   * error handling on blur
   * @param e
   */
  onBlur = (e) => {
    const { onChange } = this.props

    this.setState({ error: !this.isValid(e.target.value) }, (_) =>
      onChange?.(this.state)
    )
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const {
      name,
      type,
      label,
      pattern,
      errorMsg,
      error,
      empty,
      readOnly,
      style,
      className,
      maxLength,
      minLength,
    } = this.props

    return (
      <div className={`form-input ${className ?? ""}`} style={style}>
        <input
          className={
            "form-input-element" +
            (this.state.isHidden ? " d-none" : "") +
            (empty ? " empty" : "")
          }
          checked={type === "checkbox" ? this.state.value : null}
          maxLength={maxLength}
          minLength={minLength}
          name={name}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onClick={this.props.onClick}
          pattern={pattern}
          readOnly={readOnly}
          ref={this.state.ref}
          type={type}
          value={this.state.value}
        />
        {(type === "checkbox" ? true : this.state.value.length === 0) && (
          <label
            className={"form-label"}
            onClick={
              type === "checkbox"
                ? //() => this.onChange({ target: { checked: !this.state.value } })
                  this.props.onClick
                : null
            }
          >
            {label}
          </label>
        )}
        {error && <div className={"form-input-error"}>{errorMsg || ""}</div>}
      </div>
    )
  }
}

import "../styles/Login.scss"

import React from "react"
import { Redirect } from "react-router-dom"

import PageIntern from "../components/PageIntern"
import { ReactComponent as Lock } from "../images/lock.svg"

export default class Login extends PageIntern {
  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { data, loggedIn } = this.state

    if (loggedIn)
      return <Redirect to={this.props.store.routes.intern_start.path.to} />

    return (
      data && (
        <section>
          {this.header(data.information)}
          {
            <div className={"content__symbol"}>
              <Lock />
            </div>
          }
          <div className={"two-columns"}>{this.contents(data.content)}</div>
        </section>
      )
    )
  }
}

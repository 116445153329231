import React from "react"
import Autosuggest from "react-autosuggest"
import { Link } from "react-router-dom"

import Calendar from "../../components/Calendar"
import Loader from "../../components/Loader"
import PageIntern from "../../components/PageIntern"
import Sort from "../../components/Sort"
import { ReactComponent as Trash } from "../../images/BTN_Loeschen.svg"
import { ReactComponent as Search } from "../../images/IMG_Suchelupe.svg"

export default class ClassList extends PageIntern {
  state = Object.assign(this.state, {
    classes: null,
    filteredClasses: null,
    sortField: "name",
    sortDirection: "desc",
    suggestions: [],
    search: "",
    overlayRefId: null,
    overlayType: null,
  })

  /**
   * mount
   */
  init = async () => {
    this.setState({ overlayRefId: null, overlayType: null })
    await this.loadClasses()
  }

  /**
   * close calendar if you clicked somewhere else
   * @param e
   */
  overlayListener = (e) => {
    if (!e.target.closest(".overlay--wrapper"))
      this.setState({ overlayRefId: null, overlayType: null })
  }

  /**
   * get all classes
   * @param force
   */
  async loadClasses(force = false) {
    Loader.show()
    await this.props.store.loadClasses(force)
    this.sortByName()
    Loader.hide()
  }

  /**
   * sort by school name
   */
  sortByName = () => {
    const sortClassesByName = this.state.classes.sort((a, b) =>
      a.school.name.toLowerCase().localeCompare(b.school.name.toLowerCase())
    )
    this.props.store.sortBy(sortClassesByName, "name", this.state)
  }

  /**
   * sort by booking
   */
  sortByBooking = () => {
    const sortClassesByBooking = this.state.classes.sort((a, b) => {
      if (a.bookings.length > 0 && b.bookings.length > 0) {
        return a.bookings[0].date
          .toLowerCase()
          .localeCompare(b.bookings[0].date.toLowerCase())
      } else if (a.bookings.length > 0 && b.bookings.length === 0) {
        return 1
      }
      return -1
    })
    this.props.store.sortBy(sortClassesByBooking, "booking", this.state)
  }

  /**
   * removes a class
   * @param clazz
   * @returns {Promise<void>}
   */
  removeClass = async (clazz) => {
    Loader.show()
    await this.props.store._api.deleteClass(clazz.id)
    await this.loadClasses(true)
    Loader.hide()
  }

  /**
   * is fully complete filled
   * @param schoolClass
   * @returns {string}
   */
  isComplete = (schoolClass) => {
    return (
      (schoolClass.bookings.length > 0 && schoolClass.students.length > 0
        ? ""
        : "in") + "complete"
    )
  }

  /**
   * handle booking submit
   * @param day
   * @param slot
   * @param schoolClass
   */
  submitBooking = (day, slot, schoolClass) => {
    this.props.store
      .persistBooking({ date: day, slot, schoolClass })
      .then((result) => !result.error && this.loadClasses(true))
  }

  /**
   * get school suggestions by value
   * @param value
   * @returns {*[]}
   */
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    const selectedSuggestions = []
    const selectedIds = []
    this.state.classes.forEach((schoolClass) => {
      // search for matching class names
      let found =
        schoolClass.classname.toLowerCase().slice(0, inputLength) === inputValue
      let id = `c${schoolClass.id}`
      if (found && !selectedIds.includes(id)) {
        selectedIds.push(id)
        selectedSuggestions.push(`Klasse ${schoolClass.classname}`)
      }
      // search for matching school names
      found =
        schoolClass.school.name.toLowerCase().slice(0, inputLength) ===
        inputValue
      id = `s${schoolClass.school.id}`
      if (found && !selectedIds.includes(id)) {
        selectedIds.push(id)
        selectedSuggestions.push(schoolClass.school.name)
      }
      // search for matching student names
      found = schoolClass.students.filter((student) => {
        const forename = String(student.forename)
        const lastname = String(student.lastname)
        return (
          (forename.length > 1 &&
            forename.toLowerCase().slice(0, inputLength) === inputValue) ||
          (lastname.length > 1 &&
            lastname.toLowerCase().slice(0, inputLength) === inputValue)
        )
      })
      if (found.length > 0) {
        found.map((student) =>
          selectedSuggestions.push(
            `${student.forename} ${student.lastname} ${schoolClass.school.name} - Klasse ${schoolClass.classname}`
          )
        )
      }
    })
    return selectedSuggestions
  }

  get filteredClasses() {
    const { classes, search } = this.state
    const inputValue = search.trim().toLowerCase().replace("klasse ", "")
    const inputLength = search.length

    if (classes && search) {
      return classes.filter(
        (schoolClass) =>
          schoolClass.classname.toLowerCase().slice(0, inputLength) ===
            inputValue ||
          schoolClass.school.name.toLowerCase().slice(0, inputLength) ===
            inputValue ||
          schoolClass.students.filter((student) => {
            const name = `${student.forename} ${student.lastname} ${schoolClass.school.name} - ${schoolClass.classname}`
            return name.toLowerCase().slice(0, inputLength) === inputValue
          }).length > 0
      )
    }
    return classes
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { store } = this.props
    const {
      classes,
      sortField,
      suggestions,
      search,
      overlayRefId,
      overlayType,
    } = this.state

    return (
      <div className={"table table--classes"} role={"table"}>
        {(store._auth.isEmployee || store._auth.isReadonly) && (
          <div className={"table__search form"}>
            <div className={"form-row"}>
              <button type={"button"} className={"form-input-search"}>
                <Search />
              </button>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={({ value }) =>
                  this.setState({ suggestions: this.getSuggestions(value) })
                }
                onSuggestionsClearRequested={() =>
                  this.setState({ suggestions: [] })
                }
                getSuggestionValue={(suggestion) => `${suggestion}`}
                renderSuggestion={(suggestion) => <div>{suggestion}</div>}
                inputProps={{
                  value: search,
                  type: "text",
                  className: "form-input-element",
                  label: this.getText("08.ST.0400.EF"),
                  onChange: (event, { newValue }) =>
                    this.setState({ search: newValue }),
                  onBlur: (event) =>
                    this.setState({ search: event.target.value }),
                }}
              />
              {search.length === 0 && (
                <label className={"form-label"}>
                  {this.getText("08.ST.0400.EF")}
                </label>
              )}
            </div>
          </div>
        )}
        <div className={"table__header grid-5"}>
          <Sort active={sortField === "name"} onChange={this.sortByName}>
            {this.getText("08.WT.0300.TH.01")}
          </Sort>
          <div className={"class__name"}>
            {this.getText("08.WT.0300.TH.02")}
          </div>
          <div className={"class__size"}>
            {this.getText("08.WT.0300.TH.03")}
          </div>
          <Sort active={sortField === "booking"} onChange={this.sortByBooking}>
            {this.getText("08.WT.0300.TH.05")}
          </Sort>
        </div>
        <div className={"table__content"}>
          {classes &&
            this.filteredClasses.map((c) => (
              <div className={"table__row grid-5"} key={`class-${c.id}`}>
                {store._auth.isEmployee ? (
                  <div className={"link__arrow"}>
                    <Link
                      to={store.routes.intern_class.path.to.replace(
                        ":classId",
                        c.id
                      )}
                      onClick={(_) =>
                        store.routes.intern_class.path.onClick(c.id)
                      }
                    >
                      {c.school.name}
                    </Link>
                    {c.teacher?.id ? (
                      <small>
                        <Link
                          to={store.routes.intern_teacher.path.to.replace(
                            ":userId",
                            c.teacher.id
                          )}
                          onClick={(_) =>
                            store.routes.intern_teacher.path.onClick(
                              c.teacher.id
                            )
                          }
                        >
                          Lehrkraft (ID: {c.teacher.id}): {c.teacher.forename}{" "}
                          {c.teacher.lastname}
                        </Link>
                      </small>
                    ) : (
                      <small>Keine Lehrkraft vorhanden</small>
                    )}
                  </div>
                ) : c.teacher?.id === store._auth.user.id ? (
                  <div className={"link__arrow"}>
                    <Link
                      to={store.routes.intern_class.path.to.replace(
                        ":classId",
                        c.id
                      )}
                      onClick={(_) =>
                        store.routes.intern_class.path.onClick(c.id)
                      }
                    >
                      {c.school.name}
                    </Link>
                  </div>
                ) : (
                  <div className={"teacher__abbr--container"}>
                    <div className={"teacher__abbr--content"}>
                      {c?.teacher?.forename && c.teacher.lastname
                        ? c.teacher.forename.charAt(0) +
                          c.teacher.lastname.charAt(0)
                        : "??"}
                    </div>
                    {c.school.name}
                  </div>
                )}
                <div className={"class__name"}>Klasse {c.classname}</div>
                <div
                  className={"class__size"}
                >{`${c.students.length} Schüler*innen`}</div>
                {store._auth.isEmployee ||
                c.teacher?.id === store._auth.user.id ? (
                  <div
                    className={"table__col--booking overlay--wrapper"}
                    onClick={(_) =>
                      this.setState({
                        overlayRefId: c.id,
                        overlayType: "booking",
                      })
                    }
                  >
                    {c.bookings.length > 0
                      ? this.formatBookingDate(c.bookings[0])
                      : this.getText("08.WT.0300.H")}
                    {overlayRefId === c.id && overlayType === "booking" && (
                      <div className={"calender__overlay"}>
                        <Calendar
                          store={store}
                          date={c.bookings.length > 0 ? c.bookings[0] : null}
                          button={this.getText("08.WT.0400.BT.03")}
                          disabled={false}
                          onSubmit={({ day, slot }) =>
                            this.submitBooking(day, slot, c)
                          }
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={"table__col--booking no-hover"}>
                    {c.bookings.length > 0
                      ? this.formatBookingDate(c.bookings[0])
                      : this.getText("08.WT.0300.H")}
                  </div>
                )}
                <div
                  className={"table__row--extra"}
                  style={{
                    // need to set z-index via js, because this div is absolutely positioned
                    zIndex:
                      overlayRefId === c.id && overlayType === "delete" ? 1 : 0,
                  }}
                >
                  <div className={this.isComplete(c)} />
                  {store._auth.isEmployee && (
                    <div className={"delete overlay--wrapper"}>
                      <button
                        type={"button"}
                        className={"clean-button"}
                        onClick={() =>
                          this.setState({
                            overlayRefId: c.id,
                            overlayType: "delete",
                          })
                        }
                      >
                        <Trash />
                      </button>
                      {overlayRefId === c.id && overlayType === "delete" && (
                        <div className={"overlay"}>
                          <p>{this.getText("08.ST.0400.DF.C")}</p>
                          <button
                            type={"button"}
                            className={"form-button"}
                            onClick={() => this.removeClass(c)}
                          >
                            {this.getText("08.ST.0400.DF.BT")}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          {search.length > 0 && this.filteredClasses.length === 0 ? (
            <div className={"table__row grid-1"}>
              {this.getText("08.ST.0410.DM.H")}
            </div>
          ) : null}
        </div>
        <div className={"table__footer"}>
          {!store._auth.isReadonly && (
            <Link
              {...store.routes.intern_add_class.path}
              className={"form-button"}
            >
              {this.getText("08.WT.0300.BT")}
            </Link>
          )}
        </div>
        <div className={"table__legend"}>
          <span className={"incomplete"}>
            {this.getText("08.WT.0300.L.01")}
          </span>
          <span className={"complete"}>{this.getText("08.WT.0300.L.02")}</span>
        </div>
      </div>
    )
  }
}

export default class Error {
  static errors = [
    {
      id: "Auth.form.error.username.taken",
      key: "email",
      msg: "E-Mail Adresse bereits vorhanden",
    },
    {
      id: "Auth.form.error.email.taken",
      key: "email",
      msg: "E-Mail Adresse bereits vorhanden",
    },
    {
      id: "Auth.form.error.email.provide",
      key: "email",
      msg: "E-Mail Adresse bereits vorhanden",
    },
    {
      id: "Auth.form.error.invalid",
      key: null,
      msg: "E-Mail-Adresse oder Passwort ungültig",
    },
    {
      id: "Auth.error.invalid",
      key: null,
      msg: "Allgemeiner Fehler",
    },
    {
      id: "Auth.form.error.confirmed",
      key: null,
      msg: "Bitte bestätigen Sie zuerst ihre E-Mail-Adresse über Link in Ihrer Registrierungs-E-Mail.",
    },
  ]

  /**
   * get error message by error id
   * @param id
   * @returns {string|null}
   */
  static getMsgById(id) {
    const error = this.errors.find((error) => error.id === id)

    return error ? error.msg : null
  }

  /**
   * get error key by error id
   * @param id
   * @returns {string|null}
   */
  static getKeyById(id) {
    const error = this.errors.find((error) => error.id === id)

    return error ? error.key : null
  }
}

import About from "../pages/About"
import DataProtection from "../pages/DataProtection"
import EmailConfirmed from "../pages/EmailConfirmed"
import Faq from "../pages/Faq"
import Home from "../pages/Home"
import Imprint from "../pages/Imprint"
import Account from "../pages/intern/Account"
import AddClass from "../pages/intern/AddClass"
import CalendarAdmin from "../pages/intern/CalendarAdmin"
import ClassDetail from "../pages/intern/ClassDetail/ClassDetail"
import Documents from "../pages/intern/Documents"
import Start from "../pages/intern/Start"
import Statistics from "../pages/intern/Statistics/Statistics"
import StudentsList from "../pages/intern/StudentsList/StudentsList"
import Location from "../pages/Location"
import Login from "../pages/Login"
import Press from "../pages/Press"
import Register from "../pages/Register"
import SignIn from "../pages/SignIn"
import TeacherConfirmed from "../pages/TeacherConfirmed"

/**
 * routing class
 */
export default class Routing {
  _store = null

  _routes = {
    home: {
      path: {
        to: "/",
        onClick: (_) => (this._store.route = "home"),
      },
      key: "home",
      exact: true,
      component: Home,
      data: true,
    },
    press: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "press"),
      },
      key: "press",
      exact: true,
      component: Press,
      data: null,
    },
    about: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "about"),
      },
      key: "about",
      exact: true,
      component: About,
      data: null,
    },
    location: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "location"),
      },
      key: "location",
      exact: true,
      component: Location,
      data: null,
    },
    login: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "login"),
      },
      key: "login",
      exact: true,
      component: Login,
      data: null,
    },
    faq: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "faq"),
      },
      key: "faq",
      exact: true,
      component: Faq,
      data: null,
    },
    data_protection: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "dataprotection"),
      },
      key: "data_protection",
      exact: true,
      component: DataProtection,
      data: null,
    },
    imprint: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "imprint"),
      },
      key: "imprint",
      exact: true,
      component: Imprint,
      data: null,
    },
    register: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "register"),
      },
      key: "register",
      exact: true,
      component: Register,
      data: true,
    },
    email_confirmed: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "emailconfirmed"),
      },
      key: "email_confirmed",
      exact: true,
      component: EmailConfirmed,
      data: null,
    },
    teacher_confirmed: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "teacherconfirmed"),
      },
      key: "teacher_confirmed",
      exact: true,
      component: TeacherConfirmed,
      data: null,
    },
    sign_in: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "signin"),
      },
      key: "sign_in",
      exact: true,
      component: SignIn,
      data: true,
    },
    intern_start: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "intern"),
      },
      key: "intern_start",
      exact: true,
      component: Start,
      data: true,
    },
    intern_add_class: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "intern"),
      },
      key: "intern_add_class",
      exact: true,
      component: AddClass,
      data: true,
    },
    intern_add_teacher: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "intern"),
      },
      key: "intern_add_teacher",
      exact: true,
      component: Account,
      data: true,
    },
    intern_calendar_admin: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "intern"),
      },
      key: "intern_calendar_admin",
      exact: true,
      component: CalendarAdmin,
      data: true,
    },
    intern_class: {
      path: {
        to: "",
        onClick: (classId) => {
          this._store.route = "intern"
          this._store.params = { classId }
        },
      },
      key: "intern_class",
      exact: true,
      component: ClassDetail,
      data: true,
    },
    intern_students_list: {
      path: {
        to: "",
        onClick: (classId) => {
          this._store.route = "intern"
          this._store.params = { classId }
        },
      },
      key: "intern_students_list",
      exact: true,
      component: StudentsList,
      data: true,
    },
    intern_profile: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "intern"),
      },
      key: "intern_profile",
      exact: true,
      component: Account,
      data: true,
    },
    intern_documents: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "intern"),
      },
      key: "intern_documents",
      exact: true,
      component: Documents,
      data: true,
    },
    intern_statistics: {
      path: {
        to: "",
        onClick: (_) => (this._store.route = "intern"),
      },
      key: "intern_statistics",
      exact: true,
      component: Statistics,
      data: true,
    },
    intern_teacher: {
      path: {
        to: "",
        onClick: (userId) => {
          this._store.route = "intern"
          this._store.params = { userId }
        },
      },
      key: "intern_teacher",
      exact: true,
      component: Account,
      data: true,
    },
  }

  // --------------------------------------------------------------------------------------------------

  /**
   * constructor
   * @param store
   */
  constructor(store) {
    this._store = store

    this.loadNav().then((_) => (this._store.routes = this._routes))
  }

  /**
   * map nav items to routes
   * @param data
   */
  mapRoutes(data) {
    data &&
      data.items.forEach((item) => {
        const route = Object.keys(this._routes).find(
          (route) => item.uiRouterKey === this._routes[route].key
        )

        if (route) {
          this._routes[route].path.to = item.path
          this._routes[route].title = item.title
        }
      })
  }

  /**
   * load nav items
   */
  async loadNav() {
    const response = await this._store._api.getNavigation()

    response && !response.error && this.mapRoutes(response.data)
  }
}

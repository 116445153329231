/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../../styles/ClassDetail.scss"

import React from "react"
import { Link, withRouter } from "react-router-dom"

import Calendar from "../../../components/Calendar"
import Loader from "../../../components/Loader"
import PageIntern from "../../../components/PageIntern"
import Select from "../../../components/Select"
import { Unterstuetzungsbedarf } from "../../../components/Unterstuetzungsbedarf"
import { ReactComponent as Edit } from "../../../images/BTN_Edit.svg"
import { ReactComponent as Trash } from "../../../images/BTN_Loeschen.svg"
import { ReactComponent as Check } from "../../../images/IMG_Check.svg"
import { ReactComponent as Close } from "../../../images/IMG_Close_Icon.svg"
import { Pupil, PupilPlaceholder } from "./Pupil"

class ClassDetail extends PageIntern {
  state = Object.assign(this.state, {
    showCalendar: false,
    confirmOverlay: false,
    removeOverlay: false,
    rfidOverlay: null,
    studentToDelete: null,
    teacherOverlay: false,
    showTeacherEditor: false,
    teacherId: null,
    day: null,
    slot: null,
    printResultsOutput: null,
    printOneResultOutput: null,
    unterstuetzungsbedarf: {
      value: !!this.props.store?._schoolClass?.help_needed,
      name: "unterstuetzungsbedarf",
      type: "checkbox",
      error: false,
      required: true,
      readOnly: true,
    },
  })

  /**
   * init
   */
  init = () => {
    this.setState({
      showCalendar: false,
      confirmOverlay: false,
      removeOverlay: false,
      rfidOverlay: null,
      teacherOverlay: false,
      showTeacherEditor: false,
      teacherId: null,
      studentToDelete: null,
    })
    const { match } = this.props
    this.classId = match && match.params["classId"]

    this.loadClass(this.classId, true)
  }

  /**
   * overlay listener
   * @param e
   * @private
   */
  overlayListener = (e) => {
    if (!e.target.closest(".overlay"))
      this.setState({
        showCalendar: false,
        confirmOverlay: false,
        removeOverlay: false,
        rfidOverlay: null,
        teacherOverlay: null,
        showTeacherEditor: false,
        teacherId: null,
      })
  }

  /**
   * load one class by id
   * @param classId
   * @param force
   */
  loadClass(classId, force = false) {
    Loader.show()
    this.props.store
      .loadOneClass(classId, force)
      .then(() => this.loadClassData())
      .then(() => Loader.hide())
  }

  loadClassData() {
    const { schoolClass: currentClass } = this.props.store

    this.setState({
      unterstuetzungsbedarf: {
        ...this.state.unterstuetzungsbedarf,
        value: !!currentClass.help_needed,
      },
    })
  }

  /**
   * find all students by group key
   * @param group
   * @returns {*}
   */
  getStudentsByGroup(group) {
    return this.state.schoolClass.students.filter(
      (student) => student.group === group
    )
  }

  /**
   * handle booking submit
   * @param day
   * @param slot
   * @param schoolClass
   */
  submitBooking = (day, slot, schoolClass) => {
    this.props.store
      .persistBooking({ date: day, slot, schoolClass })
      .then((result) => !result.error && this.init())
  }

  /**
   * handle remove booking
   * @param schoolClass
   */
  removeBooking = (schoolClass) => {
    schoolClass &&
      this.props.store
        .deleteBooking(schoolClass.bookings[0].id)
        .then((result) => !result.error && result.data)
        .then((booking) => {
          if (booking) {
            this.init()
          } else {
            // error message
          }
        })
  }

  /**
   * add or edit rfid of student
   * @param e
   * @param student
   */
  handleRfId = (e, student) => {
    e.preventDefault()

    this.setState({ rfidOverlay: student.id }, (_) => {
      // no rfid ... get one
      !student.rfid &&
        this.props.store._api
          .getRfId({ id: student.id })
          .then((response) => !response.error && response.data)
          .then((data) => {
            student.rfid = data.tags.length > 0 ? data.tags[0] : null
            this.setState({ rfidOverlay: null })
            this.init()
          })
          .catch(() => {
            this.setState({ rfidOverlay: null })
          })
    })
  }

  /**
   * add or edit rfid of student
   * @param e
   * @param student
   */
  printOneResult = (e, student) => {
    Loader.show()
    e.preventDefault()

    student &&
      this.props.store._api
        .printOneResult({ id: student.id })
        .then(
          (response) =>
            !response.error &&
            response.data &&
            this.setState({
              printOneResultOutput: response.data,
            })
        )
        .then(() => Loader.hide())
        .catch(() => Loader.hide())
  }

  /**
   * print sticker of student
   * @param student
   */
  printSticker = (student) => {
    Loader.show()
    student.rfid &&
      this.props.store._api
        .printSticker({ id: student.id })
        .then((response) => !response.error && response.data)
        .then(() => {
          this.setState({ rfidOverlay: null })
          Loader.hide()
        })
        .catch(() => {
          this.setState({ rfidOverlay: null })
          Loader.hide()
        })
  }

  /**
   * print sticker of student
   * @param e
   */
  printResults = (e) => {
    Loader.show()

    const { schoolClass } = this.state
    e.preventDefault()

    schoolClass &&
      this.props.store._api
        .printResults({ id: schoolClass.id })
        .then(
          (response) =>
            !response.error &&
            response.data &&
            this.setState({
              printResultsOutput: response.data,
            })
        )
        .then(() => Loader.hide())
        .catch(() => Loader.hide())
  }

  /**
   * handle teacher change
   * @param e
   */
  submitTeacher = async (schoolClass, teacherId = this.state.teacherId) => {
    if (!schoolClass || !teacherId) return

    this.setState({ showTeacherEditor: false, teacherId: null })

    if (schoolClass.teacher.id === teacherId) {
      return
    }

    Loader.show()
    await this.props.store._api.updateClass({
      id: schoolClass.id,
      teacher: teacherId,
    })
    this.loadClass(this.classId, true)
  }

  removeStudent = async () => {
    if (!this.state.studentToDelete) {
      return
    }

    Loader.show()

    await this.props.store.deleteStudent(this.state.studentToDelete)
    this.props.store.loadClasses(true)

    this.init()
  }

  onClickDeleteStudent = (student) => {
    this.setState({ studentToDelete: student })
  }

  onClickOutsideDeleteStudent = () => {
    if (this.state.studentToDelete) {
      this.setState({ studentToDelete: null })
    }
  }

  /**
   * Verschiebt einen Schüler in eine Gruppe mit noch freiem Platz
   */
  onMoveStudent = async (id, targetGroup) => {
    if (!id || !targetGroup) {
      return
    }

    Loader.show()
    await this.props.store._api.updateStudent({ id, group: targetGroup })

    this.init()
  }

  /**
   * Vertauscht die Gruppen zweier Schüler
   */
  onSwitchStudentGroups = async (id1, id2) => {
    if (!id1 || !id2) {
      return
    }

    Loader.show()
    await this.props.store._api.switchStudentGroups({ id_1: id1, id_2: id2 })

    this.init()
  }

  /**
   * render view
   * @returns {JSX.Element}
   */
  render() {
    const { store } = this.props
    const {
      confirmOverlay,
      day,
      printOneResultOutput,
      printResultsOutput,
      removeOverlay,
      rfidOverlay,
      studentToDelete,
      schoolClass,
      showCalendar,
      showTeacherEditor,
      slot,
      teacherId,
      teacherOverlay,
      unterstuetzungsbedarf,
    } = this.state

    const blocks = [{ A: [1, 2, 3, 4] }, { B: [1, 2, 3, 4] }]

    let globalIndex = 1

    return (
      <section className={"class-detail"}>
        <Link {...store.routes.intern_start.path} className={"close"}>
          <Close />
        </Link>
        {schoolClass && (
          <div className={"class-detail__header form"}>
            <h1>
              {this.getText("08.WT.0700.HL").replace(
                "[school]",
                schoolClass.school.name
              )}
            </h1>
            <h2>
              {this.getText("08.WT.0700.SL").replace(
                "[class]",
                schoolClass.classname
              )}
            </h2>
            <p>
              {this.getText("08.WT.0700.C")
                .replace("[entries]", schoolClass.students.length)
                .replace("[classSize]", "32")}
            </p>
            <Unterstuetzungsbedarf
              inputProps={unterstuetzungsbedarf}
              getText={(key) => this.getText(key)}
            />
            {
              <div className={"class-detail__booking"}>
                <div>
                  {schoolClass.bookings.length > 0 && (
                    <div className={"class-detail__booking--delete"}>
                      <button
                        type={"button"}
                        className={"clean-button"}
                        onClick={(_) => this.setState({ removeOverlay: true })}
                      >
                        <Trash />
                      </button>
                      {removeOverlay && (
                        <div className={"overlay"}>
                          <p>{this.getText("08.WT.0640.DF.C")}</p>
                          <button
                            type={"button"}
                            className={"form-button"}
                            onClick={(_) => this.removeBooking(schoolClass)}
                          >
                            {this.getText("08.WT.0640.DF.BT")}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  <div className={"class-detail__booking--edit"}>
                    <button
                      type={"button"}
                      className={"clean-button"}
                      onClick={(_) => this.setState({ showCalendar: true })}
                    >
                      <Edit />
                    </button>
                    {showCalendar && (
                      <div className={"calender__overlay overlay"}>
                        <Calendar
                          store={store}
                          date={
                            schoolClass.bookings.length > 0
                              ? schoolClass.bookings[0]
                              : null
                          }
                          button={this.getText("08.WT.0400.BT.03")}
                          disabled={false}
                          onSubmit={({ day, slot }) =>
                            this.setState({
                              day,
                              slot,
                              confirmOverlay: true,
                              showCalendar: false,
                            })
                          }
                        />
                      </div>
                    )}
                    {confirmOverlay && (
                      <div className={"overlay"}>
                        <p>{`Neuer Termin: ${day.date} - ${slot.time.substring(
                          0,
                          5
                        )} Uhr`}</p>
                        <button
                          type={"button"}
                          className={"form-button"}
                          disabled={!day || !slot}
                          onClick={(_) =>
                            this.submitBooking(day, slot, schoolClass)
                          }
                        >
                          {this.getText("08.WT.0631.DF.BT")}
                        </button>
                      </div>
                    )}
                  </div>
                  <p>
                    <strong>{this.getText("08.WT.0700.L.01")}</strong>
                    <br />
                    {schoolClass.bookings.length > 0
                      ? this.formatBookingDate(schoolClass.bookings[0])
                      : this.getText("08.WT.0300.H")}
                  </p>
                </div>

                {store._auth.isEmployee && (
                  <div className={"class-detail__teacher--container"}>
                    <div
                      className={"class-detail__teacher"}
                      onClick={(_) =>
                        this.setState({ teacherOverlay: !teacherOverlay })
                      }
                    >
                      <div className={"class-detail__teacher--headline"}>
                        {this.getText("08.ST.0600.BT.L.02")}
                      </div>
                      {`${schoolClass.teacher?.salutation} ${schoolClass.teacher?.forename} ${schoolClass.teacher?.lastname}`}
                    </div>

                    {teacherOverlay && (
                      <div className={"overlay"}>
                        <div>
                          <p>E-Mail:</p>
                          <p>
                            <a href={`mailto:${schoolClass.teacher?.email}`}>
                              {schoolClass.teacher?.email}
                            </a>
                          </p>
                        </div>
                        <div>
                          <p>Telefon:</p>
                          <p>
                            <a href={`tel:${schoolClass.teacher?.phone}`}>
                              {schoolClass.teacher?.phone}
                            </a>
                          </p>
                        </div>
                        {this.props.store._auth.isEmployee && (
                          <div>
                            {showTeacherEditor ? (
                              <div className={"teacher-editor"}>
                                <Select
                                  label={this.getText("08.ST.0200.M.05")}
                                  options={schoolClass.school.users.map(
                                    (teacher) => ({
                                      value: teacher.id,
                                      title: `${teacher.salutation} ${teacher.forename} ${teacher.lastname}`,
                                    })
                                  )}
                                  onChange={({ value }) =>
                                    this.setState({ teacherId: value })
                                  }
                                  value={schoolClass.teacher.id}
                                />
                                <button
                                  type={"button"}
                                  className={"clean-button close"}
                                  onClick={(_) =>
                                    this.submitTeacher(
                                      schoolClass,
                                      schoolClass.teacher.id
                                    )
                                  }
                                >
                                  <Close />
                                </button>
                                <button
                                  type={"button"}
                                  className={"clean-button submit"}
                                  onClick={(_) =>
                                    this.submitTeacher(
                                      schoolClass,
                                      teacherId ?? schoolClass.teacher.id
                                    )
                                  }
                                >
                                  <Check />
                                </button>
                              </div>
                            ) : (
                              <button
                                type={"button"}
                                className={"form-button"}
                                onClick={(_) =>
                                  this.setState({ showTeacherEditor: true })
                                }
                              >
                                {this.getText("08.ST.0600.DF.BT")}
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            }
          </div>
        )}
        {schoolClass && (
          <div className={"class-detail__grid"}>
            {blocks.map((block) => {
              const row = Object.keys(block)[0]
              return (
                <div className={"grid-4"} key={`row-${row}`}>
                  <div className={"class-detail__row"}>{row}</div>
                  {block[row].map((col) => {
                    const key = `${row}_${col}`
                    return (
                      <div
                        key={key}
                        className={`class-detail__block class-detail__block--${key}`}
                      >
                        {row === "A" && store._auth.isEmployee && (
                          <div className={"class-detail__label"}>
                            <span>{this.getText("08.ST.0600.TH.01")}</span>
                            <span>{this.getText("08.ST.0600.TH.02")}</span>
                            <span>{this.getText("08.ST.0600.TH.03")}</span>
                          </div>
                        )}
                        {this.getStudentsByGroup(`${row}_${col}`).map(
                          (student) =>
                            store._auth.isEmployee ? (
                              <Pupil
                                getText={(key) => this.getText(key)}
                                key={student.id}
                                handleRfid={this.handleRfId}
                                printOneResult={this.printOneResult}
                                pupil={student}
                                rfidOverlay={rfidOverlay}
                                deleteOverlay={studentToDelete === student.id}
                                onClickDelete={this.onClickDeleteStudent}
                                remove={this.removeStudent}
                                switchStudentGroups={this.onSwitchStudentGroups}
                                printSticker={this.printSticker}
                                closeDeleteOverlay={
                                  this.onClickOutsideDeleteStudent
                                }
                                index={globalIndex++}
                              />
                            ) : (
                              <div
                                key={`student-${student.id}`}
                                className={"class-detail__content"}
                              >
                                <span>
                                  {student.forename} {student.lastname}
                                </span>
                              </div>
                            )
                        )}
                        {this.getStudentsByGroup(`${row}_${col}`).length !==
                          4 && (
                          <PupilPlaceholder
                            row={row}
                            col={col}
                            onDrop={this.onMoveStudent}
                          />
                        )}
                        {(() => {
                          const missingStudents =
                            4 - this.getStudentsByGroup(`${row}_${col}`).length
                          globalIndex += missingStudents
                        })()}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        )}
        <div className={"class-detail__footer form"}>
          <div className={"form-footer"}>
            {schoolClass &&
              schoolClass.bookings[0] &&
              schoolClass.bookings[0].editable &&
              !store._auth.isEmployee && (
                <Link
                  to={store.routes.intern_students_list.path.to.replace(
                    ":classId",
                    this.classId
                  )}
                  onClick={(_) =>
                    store.routes.intern_students_list.path.onClick(this.classId)
                  }
                  className={"form-button"}
                >
                  {this.getText("08.WT.0700.BT")}
                </Link>
              )}
            {schoolClass && store._auth.isEmployee && (
              <div>
                <Link
                  to={store.routes.intern_students_list.path.to.replace(
                    ":classId",
                    this.classId
                  )}
                  onClick={(_) =>
                    store.routes.intern_students_list.path.onClick(this.classId)
                  }
                  className={"form-button"}
                >
                  {this.getText("08.WT.0700.BT")}
                </Link>
                <button
                  type={"button"}
                  className={"form-button"}
                  onClick={this.printResults}
                >
                  {this.getText("08.ST.0600.BT.04")}
                </button>
              </div>
            )}
          </div>
        </div>
        {printResultsOutput && (
          <div className={"class-detail__messages"}>
            <h2>
              Nachfolgend finden Sie eine Auswertung des Druckprozesses für alle
              Schüler
            </h2>
            <div className={"grid-2"}>
              <div>
                <h5>Erfolgreich</h5>

                <ul>
                  {printResultsOutput[0].map((s) => (
                    <li>{s}</li>
                  ))}
                </ul>
              </div>

              <div>
                <h5>Fehlerhaft</h5>

                <ul>
                  {printResultsOutput[1].map((s) => (
                    <li>{s}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        {printOneResultOutput && (
          <div className={"class-detail__messages"}>
            <h2>
              Nachfolgend finden Sie eine Auswertung des Druckprozesses für
              einen einzelnen Schüler
            </h2>
            <div className={"grid-2"}>
              <div>
                <h5>Erfolgreich</h5>

                <ul>
                  {printOneResultOutput[0].map((s) => (
                    <li>{s}</li>
                  ))}
                </ul>
              </div>

              <div>
                <h5>Fehlerhaft</h5>

                <ul>
                  {printOneResultOutput[1].map((s) => (
                    <li>{s}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </section>
    )
  }
}

export default withRouter(ClassDetail)

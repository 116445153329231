import "./styles/index.scss"

import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import ReactDOM from "react-dom"

import App from "./App"
import { CustomDragLayer } from "./pages/intern/ClassDetail/CustomDragLayer"
import reportWebVitals from "./reportWebVitals"

ReactDOM.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <CustomDragLayer />
      <App />
    </DndProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import "../styles/Loader.scss"

import React from "react"

export default class Loader extends React.Component {
  /**
   * constructor
   */
  constructor(props) {
    super(props)

    this.state = {
      active: false,
    }
    window.Loader = this
  }

  /**
   * show loader
   */
  static show() {
    window.Loader.setState({ active: true })
  }

  /**
   * hide loader
   */
  static hide() {
    window.Loader.setState({ active: false })
  }

  /**
   * render view
   * @returns {boolean|JSX.Element}
   */
  render() {
    return (
      this.state.active && (
        <div className={"lds"}>
          <div className="lds-dual-ring" />
        </div>
      )
    )
  }
}

import "@splidejs/splide/dist/css/themes/splide-default.min.css"
import "../styles/Slider.scss"

import { Splide, SplideSlide } from "@splidejs/react-splide"
import React from "react"

export default class Slider extends React.Component {
  state = {}

  primaryOptions = {
    type: "loop",
    width: "auto",
    perPage: 1,
    perMove: 1,
    gap: "1rem",
    pagination: false,
  }

  secondaryOptions = {
    type: "slide",
    rewind: true,
    width: "auto",
    gap: "1rem",
    pagination: false,
    fixedWidth: 200,
    fixedHeight: 100,
    focus: "center",
    isNavigation: true,
    updateOnMove: true,
  }

  constructor(props) {
    super(props)

    this.primaryRef = React.createRef()
    this.secondaryRef = React.createRef()
  }

  componentDidMount() {
    // Set the sync target right after the component is mounted.
    this.primaryRef.current.sync(this.secondaryRef.current.splide)
  }

  render() {
    const { slides } = this.props

    return (
      <section className={"thumb_slider"}>
        <Splide options={this.primaryOptions} ref={this.primaryRef}>
          {slides &&
            slides.map((slide) => (
              <SplideSlide key={`${slide.hash}${slide.id}`}>
                <img
                  src={`${process.env.REACT_APP_API}${slide.url}`}
                  alt={slide.alternativeText}
                />
              </SplideSlide>
            ))}
        </Splide>
        <Splide options={this.secondaryOptions} ref={this.secondaryRef}>
          {slides &&
            slides.map((slide) => (
              <SplideSlide key={`${slide.hash}${slide.id}`}>
                <img
                  src={`${process.env.REACT_APP_API}${slide.url}`}
                  alt={slide.alternativeText}
                />
              </SplideSlide>
            ))}
        </Splide>
      </section>
    )
  }
}
